import axios from 'axios'

function getWrapper(path, params) {
  return axios.get(path, { params })
}

export const convert = (
  data,
  config = { headers: { Accept: 'application/json' } }
) => {
  const vehicleId = document.getElementById('product_id')

  return axios.post(
    '/convertions',
    {
      lead: {
        ...data,
        product_id: vehicleId?.value,
      },
    },
    config
  )
}

export const convertRaw = (data, method = 'post', config = {}) => {
  return axios({
    method: method,
    url: '/empresa-fale-conosco',
    data: data,
    ...config,
  })
}

export const confirmVisit = (date) => {
  const [authenticityTokenEl] = document.getElementsByName('authenticity_token')

  const body = {
    visit_schedule: {
      date: date,
    },
    authenticity_token: authenticityTokenEl.value,
  }

  return axios
    .post(window.location.href, body, {
      headers: { Accept: 'application/json' },
    })
    .then((resp) => resp.data)
}

export const getOccupations = (customParams) => {
  return getWrapper('/ocupacoes', customParams)
}

export const getZflowProfessions = (occupationType) => {
  return axios
    .get(`/financing/z_flow/professions?type=${occupationType}`)
    .then((resp) => resp.data)
}

export const addReceivementAddress = (resourceUrl, formData) => {
  return axios
    .put(resourceUrl, formData, { headers: { Accept: 'application/json' } })
    .then((response) => response.data)
}

/**
 * Obtém as versões do veículo baseado na placa
 * @param plate
 * @param productId O ID do produto alvo da compra. Necessário para obter o token de integração.
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTradeInVersions = (plate, dealerId) => {
  return axios
    .get(
      `/used_vehicle_pricing/trade_in/versions?plate=${plate}&dealerId=${dealerId}`
    )
    .then((response) => response.data)
}

//talvez essa função não tenha mais utilidade
export const getSmartPriceVersions = (plate, dealerId, state) => {
  return axios
    .get(
      `/auto_avaliar/vehicles/versions?plate=${plate}&dealerId=${dealerId}&state=${state}`
    )
    .then((response) => response.data)
}

export const getVehicleMakes = (year) => {
  return axios
    .get(`/api/v1/vehicles/makes?year=${year}`)
    .then((response) => response.data)
}

export const getVehicleModels = (makeID, year) => {
  return axios
    .get(`/api/v1/vehicles/models?makeId=${makeID}&year=${year}`)
    .then((response) => response.data)
}

export const getVehicleVersions = (modelID, year) => {
  return axios
    .get(`/api/v1/vehicles/versions?modelId=${modelID}&year=${year}`)
    .then((response) => response.data)
}

export const getCepData = (cep = null) => {
  const apiUrl = `https://viacep.com.br/ws/${cep}/json/`
  return axios.get(apiUrl).then((response) => response.data)
}
