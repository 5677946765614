/**
 * Wrapper simples para disparar eventos no Analytics de forma segura, caso a
 * lib exista no escopo global.
 */
export function dispatchGtagEvent(eventName: string, ...args: any[]) {
  try {
    gtag('event', eventName, ...args)
  } catch (e) {
    console.error(`Error dispatching Analytics Event: ${e}`)
  }
}

/**
 * Dados do produto para eventos do Analytics
 */
export interface ProductData {
  item_id: string
  item_name: string
  discount: number
  item_brand: string
  item_category: string
  item_variant: string
  price: number
  currency: string
  quantity: number
}

export type ProductDataExtra = Partial<{
  product_model: string
  product_model_year: number
  product_manufacture_year: number
  product_slug: string
}>

/**
 * Obtém os JSONs com os dados dos produtos
 */
export const getProductData = (productDataSelector = '#product_data',
                               productDataExtraSelector = '#product_data_extra') => {
  const productData: ProductData = JSON.parse(
    document.querySelector(productDataSelector)?.textContent || '{}'
  )

  const productDataExtra: ProductDataExtra = JSON.parse(
    document.querySelector(productDataExtraSelector)?.textContent || '{}'
  )

  return {
    productData,
    productDataExtra
  }
}

// Stubs para o TypeScript parar de indicar erros
declare function gtag(command: 'config' | 'get' | 'set' | 'event', ...parameters: any)