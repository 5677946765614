import { dispatchGtagEvent } from '../../utils'

export const configureVehiclePaymentEvents = () => {
  const isVehiclePaymentPage = document.querySelector('.payment--cash-without-used')
  if (!isVehiclePaymentPage) return

  configureBankBilletEvents()
}

const configureBankBilletEvents = () => {
  const btn = document.querySelector('.payment__billet-btn')
  btn?.addEventListener('click', () => {
    dispatchGtagEvent('cash_purchase_payment', {
      action: 'clicked_view_bank_billet'
    })
  })
}