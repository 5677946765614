import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { SecondaryButton } from '../Buttons'

const UniqueStore = ({ display_name, address, zip_code, sectors, map }) => {
  console.log({ display_name, address, zip_code, sectors, map })
  return (
    <Container>
      <TitleSection>
        <Title>{display_name}</Title>
        <Address>
          {address}{' '}
          {zip_code ? (
            <>
              {' '}
              - <span className="text-nowrap">CEP {zip_code}</span>
            </>
          ) : (
            <></>
          )}
        </Address>
      </TitleSection>

      <section>
        <SubTitle>Horários de atendimento:</SubTitle>

        <Schedule>
          {sectors.map((item) => (
            <>
              <span>- {item.name}</span>

              {item.hours.map((hour) => (
                <>
                  <br />
                  <span>- {hour.description}</span>
                </>
              ))}
            </>
          ))}
        </Schedule>
      </section>

      <section className="align-self-center">
        {map ? (
          <MapLink className="text-primary" href={map} target="_blank">
            <MapButton>Ver no mapa</MapButton>
          </MapLink>
        ) : (
          <></>
        )}
      </section>
    </Container>
  )
}

const Container = styled.div`
  background-color: #fefefe;
  padding: ${rem(74)} ${rem(48)};
  gap: ${rem(36)};
  height: 100%;
  border-radius: ${rem(8)};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 auto;
  max-width: ${rem(1216)};

  @media (max-width: 998px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: 1200px) {
    border: 1px solid var(--gray-4);
  }
`

const TitleSection = styled.section`
  max-width: 40%;

  @media (max-width: 998px) {
    max-width: initial;
  }
`

const Title = styled.h3`
  font-size: ${rem(24)};
  font-weight: 900;
  line-height: ${rem(28)};
`

const SubTitle = styled.h4`
  font-size: ${rem(18)};
  font-weight: 700;
  line-height: ${rem(27)};
`

const Address = styled.p`
  font-size: ${rem(18)};
  font-weight: 400;
  line-height: ${rem(27)};
  color: #737380;
`

const Schedule = styled.p`
  font-size: ${rem(16)};
  font-weight: 400;
  line-height: ${rem(24)};
  color: #737380;
`

const MapLink = styled.a`
  font-size: ${rem(16)};
  font-weight: 700;
  line-height: ${rem(24)};
  cursor: pointer;
  align-self: center;
`

const MapButton = styled(SecondaryButton)`
  @media (max-width: 998px) {
    width: ${rem(240)};
    max-width: 100%;
  }
`

export default UniqueStore
