import axios from 'axios'

const responseMapper = resp => { return resp.data }

const financingSimulationService = {
  update: (financingSimulation) => {
    return axios.post('/atualizar-simulacao  ', {
      financing_simulation: financingSimulation
    })
    .then(responseMapper)
  },
  creditAnalysis: (financingRequestId) => {
    return axios.get(`/analise-de-credito/${financingRequestId}`)
    .then(responseMapper)
  } 
}

export default financingSimulationService