import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { useSelector, useDispatch } from 'react-redux'
import { setOpenModalPurchaseTerm } from '../../store/ducks/modal-purchase-term'

const ModalPurchaseTerm = ({
  purchaseContract,
  onRejectClick = () => {},
  onAcceptClick = () => {},
}) => {
  const show = useSelector(state => state.modalPurchaseTerm.open)
  const dispatch = useDispatch()

  const handleHide = useCallback(() => {
    dispatch(setOpenModalPurchaseTerm(false))
  }, [show])

  return (
    <Modal
      className="modal-purchase-term"
      show={show}
      size="xl"
      centered
      onHide={handleHide}
    >
      <div dangerouslySetInnerHTML={{ __html: purchaseContract }}></div>

      <div className="modal-purchase-term__ctas">
        <a
          href=""
          className="sales__btn sales__btn--back"
          onClick={onRejectClick}
        >
          Cancelar compra
        </a>

        <a
          href="#"
          className="sales__btn sales__btn--next"
          onClick={onAcceptClick}
        >
          Eu concordo
          <i className="icon icon-chevron-right-solid"></i>
        </a>
      </div>

    </Modal>
  )
}

ModalPurchaseTerm.propTypes = {
  purchaseContract: PropTypes.string,
}

export default ModalPurchaseTerm
