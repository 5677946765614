export default function initLazyLoading() {
  const images = document.querySelectorAll('.img-lazy')

  const options = {
    threshold: 0,
    rootMargin: '25%',
  }

  var observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return

      const target = entry.target
      const dataURL = target.getAttribute('data-src')

      if (target.nodeName === 'IMG') {
        // caso o elemento seja uma imagem (nesse caso ele deve ter os atributos 'src' e 'data-src')
        target.setAttribute('src', dataURL ?? '')
      } else {
        // caso o elemento tenha um background image
        target.style.backgroundImage = `url(${dataURL})`
      }

      observer.unobserve(target)
    })
  }, options)

  images.forEach((image) => {
    observer.observe(image)
  })
}
