import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import Dropdown from 'react-bootstrap/Dropdown'

import { setOpenModalWhatsapp } from '../../store/ducks/modal-whatsapp'
import AccordionUnits from '../AccordionUnits'

const IconPhone = () => (
  <div className="navbar-header__item-secodary-accordion">
    <div className="navbar-header__text-secodary-accordion">ligar</div>
    <i className="icon icon-phone navbar-header__icon-secodary-accordion" />
  </div>
)

const IconWhatasapp = () => (
  <div className="navbar-header__item-secodary-accordion navbar-header__item-secodary-accordion--whatsapp">
    <div className="navbar-header__text-secodary-accordion">conversar</div>
    <i className="icon icon-whatsapp navbar-header__icon-secodary-accordion navbar-header__icon-secodary-accordion--whatsapp" />
  </div>
)

export const SocialLinks = ({
  unitsPhone,
  unitsWhatsapp,
  setShowNavbar,
  mobileMenu,
  closeButton,
  className,
  whatsappSVGColor,
  phoneSVGColor,
  customBg,
}) => {
  const dispatch = useDispatch()

  const [showDropdownPhone, setShowDropdownPhone] = useState(false)
  const [showDropdownWhatsapp, setShowDropdownWhatsapp] = useState(false)

  useEffect(() => {
    closeButton?.current.addEventListener('click', () => {
      setShowDropdownPhone(false)
      setShowDropdownWhatsapp(false)
    })
  }, [closeButton])

  const onDropdownPhoneToggle = (isOpen) => {
    setShowDropdownPhone(isOpen)
    setShowNavbar(false)
  }

  const onDropdownWhatsappToggle = (isOpen) => {
    setShowDropdownWhatsapp(isOpen)
    setShowNavbar(false)
  }

  const onNavItemWhatsappClick = (unitInfo) => {
    dispatch({ type: 'modalWhatsapp/UNIT_SELECTED', unit: unitInfo })
    dispatch(setOpenModalWhatsapp(true))
  }

  const getUnitsPhone = () =>
    unitsPhone.filter((unit) => unit.phones.length > 0) || []

  const getUnitsWhatsapp = () =>
    unitsWhatsapp.filter((unit) => unit.phones.length > 0) || []

  return (
    <div
      className={clsx(`navbar-header__dropdown-buttons ${className}`, {
        'navbar-header__dropdown-menu_mobile': mobileMenu,
      })}
    >
      <Dropdown
        show={showDropdownWhatsapp}
        onToggle={onDropdownWhatsappToggle}
        className="navbar-header__dropdown"
        alignRight
      >
        <Dropdown.Toggle
          as="div"
          className={clsx('navbar-header__toggle-dropdown', {
            'navbar-header__toggle-dropdown--active': showDropdownWhatsapp,
            'd-none': getUnitsWhatsapp().length == 0,
          })}
        >
          <div
            className={clsx(
              'navbar-header__btn-icon navbar-header__btn-icon--whatsapp bg-whatsapp text-white',
              {
                'navbar-header__btn-icon--square': showDropdownWhatsapp,
              }
            )}
          >
            <i
              className={clsx('icon icon-whatsapp', {
                'd-none': showDropdownWhatsapp,
              })}
              style={{
                color: whatsappSVGColor,
              }}
            />
            <i
              className={clsx('icon icon-times-solid', {
                'd-none': !showDropdownWhatsapp,
              })}
            />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix="dropdown-menu navbar-header__dropdown-menu">
          <AccordionUnits
            units={getUnitsWhatsapp()}
            Icon={<IconWhatasapp />}
            classNameToggle="navbar-header__accordion-toggle--whatsapp"
            onNavItemClick={onNavItemWhatsappClick}
          />
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown
        show={showDropdownPhone}
        onToggle={onDropdownPhoneToggle}
        className="navbar-header__dropdown"
        alignRight
      >
        <Dropdown.Toggle
          as="div"
          className={clsx('navbar-header__toggle-dropdown', {
            'navbar-header__toggle-dropdown--active': showDropdownPhone,
            'd-none': getUnitsPhone().length == 0,
          })}
        >
          <div
            className={clsx(
              `navbar-header__btn-icon bg-primary text-white ${customBg}`,
              {
                'navbar-header__btn-icon--square': showDropdownPhone,
              }
            )}
          >
            <i
              className={clsx(
                'icon icon-phone navbar-header__btn-toggle--icon-phone',
                {
                  'd-none': showDropdownPhone,
                }
              )}
              style={{
                color: phoneSVGColor,
              }}
            />
            <i
              className={clsx('icon icon-times-solid', {
                'd-none': !showDropdownPhone,
              })}
            />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix="dropdown-menu navbar-header__dropdown-menu">
          <AccordionUnits units={getUnitsPhone()} Icon={<IconPhone />} />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
