import axios from 'axios'

const WEEKDAYS = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
const MONTHS = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
]
const HOLIDAYS = [
  '[1,1]',
  '[4,21]',
  '[5,1]',
  '[9,7]',
  '[10,12]',
  '[11,2]',
  '[11,15]',
  '[12,25]',
]

export const getDaysList = () => {
  const today = new Date()
  const daysList = new Array(50).fill(0).map((d, idx) => {
    const day = new Date()
    day.setDate(day.getDate() + idx)
    if (day.getDay() == 0) return false
    if (HOLIDAYS.includes(`[${day.getMonth() + 1},${day.getDate()}]`))
      return false
    return {
      id: idx,
      day: day,
      weekDay: WEEKDAYS[day.getDay()],
      weekDayValue: day.getDay(),
      formattedDay: `${day.getDate()} de ${MONTHS[day.getMonth()]}.`,
    }
  })
  if (today.getHours() >= 17) {
    daysList.shift()
  }
  return daysList.filter((d) => !!d)
}

export const getTimeList = (state = '') => {
  const weekDays = [0, 1, 2, 3, 4, 5, 6]
  let timeList = {}

  weekDays.forEach((day) => {
    timeList[day] = new Array(10).fill(0).map((x, idx) => ({
      id: idx,
      value: 9 + idx,
      label: `${9 + idx}:00`,
    }))
  })

  if (state === 'SP' || state === 'RJ') {
    timeList[6] = new Array(9).fill(0).map((x, idx) => ({
      id: idx,
      value: 9 + idx,
      label: `${9 + idx}:00`,
    }))
  }

  if (window.location.host === 'avaliacao.expoentetoyota.com.br') {
    timeList[6] = new Array(5).fill(0).map((x, idx) => ({
      id: idx,
      value: 9 + idx,
      label: `${9 + idx}:00`,
    }))
  }

  return timeList
}

export const submitSchedule = async (day, time, saleId) => {
  const date = new Date(day.day)
  date.setHours(time.value)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  const baseURL = window.location.origin
  const { data } = await axios.post(`${baseURL}/api/v1/visit_schedule/create`, {
    saleId: saleId,
    date: date,
  })
  return data
}

export const getScheduleInfo = async () => {
  const baseURL = window.location.origin
  const scheduleInfo = await axios.get(
    `${baseURL}/api/v1/dealer/store/get_schedule_info`
  )
  return scheduleInfo.data
}
