import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { goTop } from '../../../../actions'
import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
  clearAvaliationFlowForm,
} from '../../../../../../store/ducks/avaliation-flow'
import useSmallWindow from '../../../../hooks/useSmallWindow'
import { fetchMakers } from './action'
import { Container, Title, StyledCard, StyledSearchInput, Logo } from './styled'
import LoadingPlaceholder from './loading'

const FindMaker = ({ formValues }) => {
  const dispatch = useDispatch()
  const isSmallWindow = useSmallWindow(600)
  const history = useHistory()

  const [makersList, setMakersList] = useState([])
  const [showingList, setShowingList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(1))
    dispatch(clearAvaliationFlowForm(['make_id']))
    ;(async () => {
      setIsLoading(true)
      const list = await fetchMakers(formValues.year)
      setIsLoading(false)
      setMakersList(list)
      setShowingList(list)
    })()
  }, [])

  const [search, setSearch] = useState('')
  useEffect(() => {
    setShowingList(
      makersList.filter((maker) => {
        return maker.name.toLowerCase().includes(search.toLowerCase())
      })
    )
  }, [search])

  const handleSelect = (maker) => {
    dispatch(setAvaliationFlowFormValues(maker.id, 'make_id'))
    history.push({
      pathname: '/compramos-seu-usado/modelo-do-veiculo',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
  }

  return (
    <Container>
      <Title>Selecione a marca do seu veículo</Title>
      <StyledSearchInput
        onChange={(e) => setSearch(e.target.value)}
        width={isSmallWindow ? '312px' : '488px'}
        containerPadding={isSmallWindow ? '12px 16px' : undefined}
      />
      <LoadingPlaceholder loading={isLoading} />
      <div>
        {showingList.map((maker) => (
          <StyledCard
            onClick={() => handleSelect(maker)}
            title={`${maker.name}`}
            padding="20px 28px"
            width={isSmallWindow ? 312 : 488}
            titleSize={isSmallWindow ? 14 : 16}
            Icon={() => (
              <Logo
                loading="lazy"
                src={`https://autoforce-autocommerce.s3.amazonaws.com/assets/make-logos/${maker.name
                  .toLowerCase()
                  .replace(/\s+/g, '-')}.png`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src =
                    'https://autoforce-autocommerce.s3.amazonaws.com/assets/make-logos/placeholder.png'
                }}
              />
            )}
          />
        ))}
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
})

export default connect(mapStateToProps)(FindMaker)
