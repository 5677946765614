import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from '../store'
import NavbarHeader from '../components/NavbarHeader'

const elData = document.querySelector('#header__navbar')

if (elData) {
  const {
    logoPath,
    unitsPhone,
    unitsWhatsapp,
    socialLinks,
    customer_signed_in,
    buy_your_vehicle,
    current_customer_name,
    states_and_cities_for_dealer,
    url_state,
    show_modal_state_page,
    content_by_location,
    current_url_state_path,
  } = elData.dataset

  ReactDOM.hydrate(
    <Provider store={store}>
      <NavbarHeader
        logoPath={logoPath}
        unitsPhone={JSON.parse(unitsPhone)}
        unitsWhatsapp={JSON.parse(unitsWhatsapp)}
        socialLinks={JSON.parse(socialLinks)}
        customer_signed_in={JSON.parse(customer_signed_in)}
        current_customer_name={current_customer_name}
        buy_your_vehicle={JSON.parse(buy_your_vehicle)}
        states_and_cities_for_dealer={JSON.parse(states_and_cities_for_dealer)}
        url_state={JSON.parse(url_state)}
        current_url_state_path={current_url_state_path}
        show_modal_state_page={JSON.parse(show_modal_state_page)}
        content_by_location={JSON.parse(content_by_location)}
      />
    </Provider>,
    document.getElementById('header__navbar')
  )
}
