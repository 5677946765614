import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { rem } from 'polished'

export const ModalBody = styled(Modal.Body)`
  padding: ${rem(24)} ${rem(96)} ${rem(96)} ${rem(96)};
  width: ${rem(520)};
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  color: var(--primary);
  font-weight: 700;
  font-size: ${rem(24)};
  line-height: ${rem(36)};
  text-align: center;
  margin-bottom: ${rem(8)};
`

export const SubTitle = styled.p`
  color: var(--gray-5);
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  text-align: center;
  margin-bottom: 0;
`

export const SelectContainer = styled.div`
  margin-top: ${rem(48)};
  margin-bottom: ${rem(32)};
`

export const IconContainer = styled.div`
  background-color: var(--primary);
  border: ${rem(10)} solid var(--primaryLightest);
  margin-bottom: ${rem(16)};
  color: #fff;
  border-radius: 50%;
  height: ${rem(100)};
  width: ${rem(100)};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`

export const customSelectStyle = {
  option: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    padding: `${rem(8)} ${rem(16)}`,
    borderRadius: rem(4),
    cursor: 'pointer',
    fontSize: rem(16),
  }),

  control: (base, state) => ({
    ...base,
    borderRadius: rem(4),
    display: 'flex',
    justfyContent: 'space-between',
    padding: `${rem(4)} ${rem(16)}`,
    height: rem(50),
    borderColor: '#ced4da',

    ...(state.isOpen && {
      '&:hover': {
        boxShadow: `0 0 ${rem(1)} var(--primary-box-shadow)`,
        borderColor: 'var(--primaryLight)',
      },
    }),
  }),
}
