import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { rem } from 'polished'
import images from '../../../images/icons/white-triangle.svg'
import styled from 'styled-components'

export default ({ currentCustomerName }) => {
  return (
    <UserDropDown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <Icon icon-name="user" />
        <span className="mx-3">{currentCustomerName}</span>
        <Icon icon-name="chevron-down" />
      </Dropdown.Toggle>

      <StyledMenu>
        <WhiteArrow src={images} />
        <InnerDropDownMenu>
          <UserDropDownItem href="/editar-cadastro">
            <Icon icon-name="user" /> Minha conta
          </UserDropDownItem>
          <UserDropDownItem href="/meus-pedidos">
            <Icon icon-name="box" /> Meus pedidos
          </UserDropDownItem>
          <LogOutDropDownItem href="/customers/sign_out">
            <Icon icon-name="log-out" /> Sair
          </LogOutDropDownItem>
        </InnerDropDownMenu>
      </StyledMenu>
    </UserDropDown>
  )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
    className="font-weight-bold text-dark d-flex w-100 justify-content-between align-items-center"
  >
    {children}
  </a>
))

const InnerDropDownMenu = styled.div`
  border-radius: ${rem(16)};
  padding: ${rem(24)} 0;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 ${rem(8)} ${rem(16)} rgba(0, 0, 0, 0.175);
`

const Icon = (props) => {
  return <i {...props} className="d-inline"></i>
}

const Menu = (props) => {
  return (
    <Dropdown.Menu renderOnMount={true} className={props.className}>
      {props.children}
    </Dropdown.Menu>
  )
}

const StyledMenu = styled(Menu)`
  width: 100%;
  border: 0;
  background: transparent;
  box-shadow: none;
`

const WhiteArrow = styled.img`
  position: absolute;
  left: calc(100% - 38px);
  top: -${rem(3)};
`

const UserDropDown = styled(Dropdown)`
  padding: 0 ${rem(16)};
  display: flex;
  justify-content: space-between;
`

const UserDropDownItem = styled(Dropdown.Item)`
  padding: ${rem(12)} ${rem(24)};
  font-size: ${rem(14)};

  svg {
    width: ${rem(20)};
    margin-right: ${rem(16)};
  }

  :active {
    background-color: #f8f8f9;
    color: initial;
  }
`

const LogOutDropDownItem = styled(UserDropDownItem)`
  margin-top: ${rem(16)};
  color: #f44336;
`
