import styled from 'styled-components'
import { rem } from 'polished'

const BaseButton = styled.button`
  border: 0;
  font-weight: bold;
  border-radius: ${rem(4)};
  font-size: ${rem(16)};
  padding: ${rem(16)};

  svg {
    margin: 0 8px 0 8px;
  }

  &[disabled] {
    opacity: 0.5;
  }
`

export default BaseButton
