import { dispatchGtagEvent } from '../../utils'

export const configureSaleSummaryEvents = () => {
  const isSaleSummaryPage = !!document.querySelector('.finished-sale-summary')
  if (!isSaleSummaryPage) return

  const elBtnPrint = document.querySelector('.finalized-sales__btn-download')

  elBtnPrint?.addEventListener('click', () => {
    dispatchGtagEvent('download_sale_summary')
  })
}