import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { rem } from 'polished'

export const ModalBody = styled(Modal.Body)`
  padding: ${rem(24)} 4rem 4rem 4rem;
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  color: var(--primary);
  font-weight: 700;
  font-size: ${rem(24)};
  line-height: ${rem(36)};
  text-align: center;
  margin-bottom: ${rem(8)};
`

export const SubTitle = styled.p`
  color: var(--gray-5);
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  text-align: center;
  margin-bottom: 35px;
`

export const SelectContainer = styled.div`
  margin-top: ${rem(48)};
  margin-bottom: ${rem(32)};
`

export const IconContainer = styled.div`
  background-color: var(--primary);
  border: ${rem(10)} solid var(--primaryLightest);
  margin-bottom: ${rem(16)};
  color: #fff;
  border-radius: 50%;
  height: ${rem(100)};
  width: ${rem(100)};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`
