import React, {useCallback, useState} from 'react';
import PaymentMethod from "./PaymentMethod";
import clsx from "clsx"

export default function PaymentMethodChooser(props: PaymentMethodChooserProps) {
    const [chosenMethod, setChosenMethod] = useState('')

    const methods = props.paymentMethods.map(({value, description, iconName, label}) => {
        const isMethodActive = chosenMethod === value

        return (
            <div onClick={() => setChosenMethod(value)} key={value}
                 role="button"
                 className={
                     clsx('payment-method-option', isMethodActive && 'payment-method-option--active')
                 }>
                <div className="payment-method-option__header">
                    <i className={`icon icon-${iconName}`}/> {label}
                </div>
                <div className="payment-method-option__description">
                    {description}
                </div>
            </div>
        )
    })

    return (
        <div className="payment-method-options" role="list">
            {/* Como atualmente estamos disparando o formulário de maneira clássica, adicionamos esse field hidden a
            seguir para guardar o valor do método escolhido */}
            <input type="hidden" name={props.fieldName} value={chosenMethod}/>
            {methods}
        </div>
    )
}

export interface PaymentMethodChooserProps {
    paymentMethods: PaymentMethod[],
    fieldName: string
}