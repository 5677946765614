import {render} from "react-dom";
import UserSaleAttachments from "../components/UserSaleAttachments";
import React from "react";

(() => {
    const container = document.querySelector('.user-panel__sale-function-attachments');
    if (!container) {
        return
    }

    render(<UserSaleAttachments uploadAttachmentUrl={container?.dataset?.uploadAttachmentUrl} />, container)
})()