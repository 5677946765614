import React from 'react'
import { render, hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import store from '../store'
const getCarouselApp = () => import('../components/CarouselApp')
const getCarouselGalleryApp = () => import('../components/CarouselGalleryApp')
const getCarouselGallerySimpleApp = () =>
  import('../components/CarouselGallerySimpleApp')
import ModalFormActiveBestPriceApp from '../components/ModalFormActiveBestPriceApp'
const getBanner = () => import('../components/Banner')
const getListItemsApp = () => import('../components/ListItemsApp')
import { setOpenModalWhatsapp } from '../store/ducks/modal-whatsapp'
import { setOpenModalActiveBestPrice } from '../store/ducks/modal-active-best-price'
import equalHeights from '../utils/equalHeights'
import ToggleGallery360 from '../components/ToggleGallery360'

const elCarouselGallery = document.getElementById(
  'vehicle-show__carousel-gallery'
)

const elCarouselGallerySimple = document.querySelector(
  '#vehicle-show__carousel-gallery-simple'
)
const elBannerAside = document.getElementById('vehicle-show__banner-aside')
const elBannerFooter = document.getElementById('vehicle-show__banner-footer')
const elListItemsVehicle = document.querySelector(
  '[data-dom-id="vehicle-show__list-items"]'
)
const elCarouselOther = document.getElementById(
  'vehicle-show-carousel-offers-other'
)

const elModalActiveBestPrice = document.getElementById(
  'modal-active-best-price'
)
const elToggleGallery = document.getElementById(
  'vehicle-show__toggle-gallery-360'
)

if (elCarouselGallery) {
  getCarouselGalleryApp().then((mod) => {
    const CarouselGalleryApp = mod.default
    const {
      items,
      special,
      shielded,
      premium,
      certifiedDekra,
      spinCarUrl,
      color,
    } = elCarouselGallery.dataset
    render(
      <CarouselGalleryApp
        items={JSON.parse(items)}
        special={JSON.parse(special)}
        shielded={JSON.parse(shielded)}
        premium={JSON.parse(premium)}
        certifiedDekra={JSON.parse(certifiedDekra)}
        spinCarUrl={spinCarUrl}
        color={color}
      />,
      document.getElementById('vehicle-show__carousel-gallery')
    )
  })
}

if (elCarouselGallerySimple) {
  getCarouselGallerySimpleApp().then((mod) => {
    const CarouselGallerySimpleApp = mod.default
    const { items, special, spinCarUrl, color } =
      elCarouselGallerySimple.dataset

    render(
      <Provider store={store}>
        <CarouselGallerySimpleApp
          items={JSON.parse(items)}
          special={special}
          spinCarUrl={spinCarUrl}
          color={color}
        />
      </Provider>,
      document.getElementById('vehicle-show__carousel-gallery-simple')
    )
  })
}

if (elToggleGallery) {
  render(
    <Provider store={store}>
      <ToggleGallery360 />
    </Provider>,
    document.getElementById('vehicle-show__toggle-gallery-360')
  )
}

if (elBannerAside) {
}

if (elBannerFooter) {
  getBanner().then((mod) => {
    const Banner = mod.default
    const { items } = elBannerFooter.dataset

    render(
      <Banner items={JSON.parse(items)} />,
      document.getElementById('vehicle-show__banner-footer')
    )
  })
}

if (elListItemsVehicle) {
  getListItemsApp().then((mod) => {
    const ListItemsApp = mod.default
    const { className, title, items } = JSON.parse(elListItemsVehicle.innerHTML)

    render(
      <ListItemsApp className={className} title={title} items={items} />,
      document.getElementById('vehicle-show__list-items')
    )
  })
}

if (elModalActiveBestPrice) {
  const { linkPrivacyPolicy, linkTermsOfUse } = elModalActiveBestPrice.dataset

  const handleSubmited = () => {
    const elsBoxPriceForBlockedPrice = document.querySelectorAll(
      '.vehicle-show__box-price--blocked, .vehicle-show__price-by--term-no-blocked'
    )
    const elsBoxPriceForNotBlockedPrice = document.querySelectorAll(
      '.vehicle-show__box-price--not-blocked, .vehicle-show__price-by--term-blocked'
    )

    elsBoxPriceForBlockedPrice.forEach((elBoxPriceForBlockedPrice) =>
      elBoxPriceForBlockedPrice.classList.add('d-none')
    )
    elsBoxPriceForNotBlockedPrice.forEach((elBoxPriceForNotBlockedPrice) =>
      elBoxPriceForNotBlockedPrice.classList.remove('d-none')
    )
  }

  render(
    <Provider store={store}>
      <ModalFormActiveBestPriceApp
        linkPrivacyPolicy={linkPrivacyPolicy}
        linkTermsOfUse={linkTermsOfUse}
        onSubmitted={handleSubmited}
      />
    </Provider>,
    elModalActiveBestPrice
  )
}

if (elCarouselOther) {
  getCarouselApp().then((mod) => {
    const CarouselApp = mod.default
    const { items, containerClass, unlocked_user, noSwiping, loop } =
      elCarouselOther.dataset

    render(
      <CarouselApp
        items={JSON.parse(items)}
        containerClass={containerClass}
        unlocked_user={unlocked_user}
        noSwiping={noSwiping}
        loop={loop}
      />,
      document.getElementById('vehicle-show-carousel-offers-other')
    )
  })
}

/**
 * Manipulador para abrir modal do WhatsApp
 */
function handleOpenModalWhatsApp() {
  const elConsultationBtnWhatsApp = document.getElementById(
    'vehicle-show-online-consultation-btn-whatsapp'
  )
  const elBtnTalkWhatsApp = document.getElementById(
    'vehicle-show-btn-talk-whatsapp'
  )

  const openWhatsappModal = (e) => {
    e.preventDefault()

    store.dispatch(setOpenModalWhatsapp(true))
  }

  if (elConsultationBtnWhatsApp)
    elConsultationBtnWhatsApp.addEventListener('click', openWhatsappModal)

  if (elBtnTalkWhatsApp)
    elBtnTalkWhatsApp.addEventListener('click', openWhatsappModal)
}

/**
 * Manipulador para abrir modal de ativar melhor preço
 */
function handleActiveBestPrice() {
  const elButtonMobileActiveBestPrice = document.getElementById(
    'show-vehicle__button-mobile-best-price'
  )
  const elButtonMobileActiveBestPriceDesktop = document.getElementById(
    'show-vehicle__button-mobile-best-price-desktop'
  )

  const showActivateBestPriceModal = (e) => {
    e.preventDefault()
    store.dispatch(setOpenModalActiveBestPrice(true))
  }

  if (elButtonMobileActiveBestPrice) {
    elButtonMobileActiveBestPrice.addEventListener(
      'click',
      showActivateBestPriceModal
    )
  }

  if (elButtonMobileActiveBestPriceDesktop) {
    elButtonMobileActiveBestPriceDesktop.addEventListener(
      'click',
      showActivateBestPriceModal
    )
  }
}

window.addEventListener('load', () => {
  handleOpenModalWhatsApp()
  handleActiveBestPrice()
  equalHeights('.vehicle-show__info')
})
