// Actions
const OPEN = 'modal-contact/OPEN'

// Actions Creators
export function setOpenModalContact(open) {
  return { type: OPEN, open }
}

const initial_state = {
  open: false,
  unit: null,
}

// Reducer
export default function reducer(state = initial_state, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        open: action.open,
      }
    case 'modalcontact/UNIT_SELECTED':
      return {
        ...state,
        unit: action.unit,
      }

    default:
      return state
  }
}
