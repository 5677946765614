import { configureAdvancedSearchSubmit } from './pages/advanced-search'
import { configureProductDetailsEvents } from './pages/product-details'
import { configureHomeOfferCarouselsAnalytics } from './pages/home/offers'
import { configureOrderReviewEvents } from './pages/purchase-flow/sales-order-review'
import { configureVehicleDeliveryTakeoutEvents } from './pages/purchase-flow/vehicle-delivery-takeout'
import { configureVehiclePaymentEvents } from './pages/purchase-flow/vehicle-payment'
import { configureSaleSummaryEvents } from './pages/purchase-flow/sale-summary'
import { configureFinancingSimulationEvents } from './pages/purchase-flow/financing-simulation'
import { configureOrderDetailsEvents } from './pages/profile/order-details'

configureHomeOfferCarouselsAnalytics()
configureAdvancedSearchSubmit()
configureProductDetailsEvents()
configureOrderReviewEvents()
configureVehicleDeliveryTakeoutEvents()
configureVehiclePaymentEvents()
configureSaleSummaryEvents()
configureFinancingSimulationEvents()
configureOrderDetailsEvents()