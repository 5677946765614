import axios from 'axios'

export const fetchAllowShielded = async () => {
  const baseURL = window.location.origin
  const { data: allow } = await axios.get(
    `${baseURL}/api/v1/dealer/store/allow_shielded`
  )
  return allow
}

export const goTop = () => {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}
