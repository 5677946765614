import React, {useCallback, useState} from 'react'
import {useDropzone} from "react-dropzone";
import axios from "axios";
import clsx from "clsx";

export default function UserSaleAttachments(props) {
    const {uploadAttachmentUrl, inputName = 'sale[upload_receipt_payment]'} = props
    const [isUploading, setIsUploading] = useState(false)
    const [hasUploadErrors, setHasUploadErrors] = useState(false)
    const [finishedUploading, setFinishedUploading] = useState(false)

    const onDrop = useCallback(acceptedFiles => {
        if (!uploadAttachmentUrl) {
            console.error('Upload Attachment URL is not configured. Unable to upload.')
            return
        }

        // Como só temos um arquivo para esse caso, pegamos o primeiro item do array
        const [file] = acceptedFiles

        if (!file) {
            console.warn('No attachment found to upload')
            return
        }

        const formData = new FormData()
        formData.append(inputName, file)

        const [tokenParam, token] = [
            document.querySelector('meta[name="csrf-param"]').content,
            document.querySelector('meta[name="csrf-token"]').content
        ]

        formData.append(tokenParam, token)
        setIsUploading(true)
        setHasUploadErrors(null)
        axios.put(uploadAttachmentUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(() => {
            setHasUploadErrors(null)
            setFinishedUploading(true)
        }).catch((e) => {
            console.error(e)
            setHasUploadErrors(true)
        }).finally(() => {
            setIsUploading(false)
        })
    }, [])
    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        accept: 'image/*,application/pdf',
        maxSize: 15728640,
        maxFiles: 1,
    })
    const fileRejectionItems = fileRejections.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ))

    return <div {...getRootProps()}>
        <input {...getInputProps({
            name: inputName
        })} />
        <div
            className={clsx('user-panel__sale-function-upload-label', finishedUploading && 'user-panel__sale-function-upload-label--success')}>
            {isUploading &&
            <div className="spinner-border" role="status">
                <span className="sr-only">Anexando arquivo...</span>
            </div>
            }
            {!isUploading && <>
                Comprovante de pagamento
                {finishedUploading && <i className="icon icon-check-solid"/>}
                {!finishedUploading && <i className="icon icon-plus"/>}
            </>}
        </div>
        {fileRejectionItems?.length > 0 &&
        <div className="alert alert-danger">
            <ul className="list-unstyled">
                fileRejectionItems
            </ul>
        </div>
        }
        {hasUploadErrors &&
        <div className="alert alert-danger">
            Houve um erro ao anexar o arquivo. Por favor, tente novamente.
        </div>
        }
    </div>
}