import React, { useCallback, useEffect, useMemo, useState } from 'react'
import UploadDropzoneTwo from '../UploadDropzoneTwo'

/**
 * Componente responsável por renderizar os uploads da primeira etapa do fluxo de pagamento.
 * Atualmente ele também controla o botão de "comprar" para que seja habilitado apenas se todos os documentos forem
 * preenchidos.
 * @constructor
 */
export default function DocumentPageUploads({
  className = '',
  iconCheckRenderFn,
  iconTrashRenderFn,
  iconFileRenderFn,
  receipt_address_download,
  front_id_document_download,
  back_id_document_download,
} = {}) {
  const uploadsData = [
    {
      title: 'Comprovante de residência',
      classDocumentIcon: 'icon-file-alt-solid',
      inputName: 'sale[upload_receipt_address]',
      linkDownload: receipt_address_download,
    },
    {
      title: 'Imagem frontal da identidade',
      classDocumentIcon: 'icon-id-card-solid',
      inputName: 'sale[upload_front_id_document]',
      linkDownload: front_id_document_download,
    },
    {
      title: 'Imagem do verso da identidade',
      classDocumentIcon: 'icon-id-card-regular',
      inputName: 'sale[upload_back_id_document]',
      linkDownload: back_id_document_download,
    },
  ]
  // fileList é apenas um objeto no formato "chave: boolean" para indicar se um arquivo foi configurado para o input.
  // Está aqui apenas para fins de validação
  const [fileList, setFileList] = useState({
    'sale[upload_receipt_address]': false,
    'sale[upload_front_id_document]': false,
    'sale[upload_back_id_document]': false,
  })

  // Verificamos se todos os inputs contém arquivos informados
  const uploadsAreValid = useMemo(
    () =>
      Object.values(fileList).reduce((acc, hasFile) => acc && hasFile, true),
    [fileList]
  )

  // Ativando/desativando o botão de "continuar" da tela
  useEffect(() => {
    const nextButtons = document.querySelectorAll('.sales__btn--next')

    uploadsAreValid
      ? nextButtons.forEach((btn) => btn.removeAttribute('disabled'))
      : nextButtons.forEach((btn) => btn.setAttribute('disabled', 'disabled'))
  }, [uploadsAreValid])

  const onAddFile = useCallback(
    (inputName) => {
      setFileList((prevState) => ({ ...prevState, [inputName]: true }))
    },
    [fileList]
  )
  const onRemoveFile = useCallback(
    (inputName) => {
      setFileList((prevState) => ({ ...prevState, [inputName]: false }))
    },
    [fileList]
  )

  const uploadsFields = uploadsData.map(
    ({ title, classDocumentIcon, inputName, linkDownload }, index) => (
      <div className="payment__send-file-list-item" key={index}>
        <UploadDropzoneTwo
          number={index + 1}
          onRemoveFile={onRemoveFile}
          onAddFile={onAddFile}
          title={title}
          inputName={inputName}
          iconCheckRenderFn={iconCheckRenderFn}
          iconTrashRenderFn={iconTrashRenderFn}
          iconFileRenderFn={iconFileRenderFn}
          classDocumentIcon={classDocumentIcon}
          linkDownload={linkDownload}
        />
      </div>
    )
  )

  return (
    <div
      className={`payment__send-file payment__send-file--no-border ${className}`}
    >
      <div className="payment__send-file-list">{uploadsFields}</div>
    </div>
  )
}
