
import React from 'react'
import PropTypes from 'prop-types'
import { CTAs, ErrorCTAs } from './CTAs'
import { useSelector } from 'react-redux'

const CreditAnalysisCTAs = (props) => {
  const errorOnAnalysis = useSelector(state => state.creditAnalysis.errorOnAnalysis)
  const loadingAnalysis = useSelector(state => state.creditAnalysis.loadingAnalysis)

  const content = () => {

    if (loadingAnalysis) return null
    if (errorOnAnalysis) return <ErrorCTAs {...props} />

    return <CTAs {...props} />
  }

  return content()
}

CreditAnalysisCTAs.propTypes = {
  back_cta: PropTypes.string.isRequired,
  continue_cta: PropTypes.string.isRequired,
  whatsapp_store_cta: PropTypes.string.isRequired,
  new_simulation_cta: PropTypes.string.isRequired,
}

export default CreditAnalysisCTAs
