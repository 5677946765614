import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setErrorInstallments,
  setErrorApi,
} from '../../store/ducks/financing-installments'
import { moneyFormat } from '../../utils/wNumb'
import InputRange from '../InputRange'
import InstallmentList from './InstallmentList'
import ModalDetails from './ModalDetails'
import financingSimulationService from '../../service/financingSimulationService'
import { debounce } from 'lodash'
import { IMaskInput, useIMask } from 'react-imask'
import { moneyMask } from '../../utils/masks'
import SimpleInstallments from './SimpleInstallments'

export const FinancingInstallmentsContext = createContext()

const FinancingInstallments = ({
  onInstallmentChange = () => {},
  onInputRangeChange = () => {},
  onBankChange = () => {},
  onInstallmentsRequested = () => {},
  useSimpleInstallments = false,
  ...props
}) => {
  const [min] = useState(props.min)
  const [max] = useState(props.max)
  const [entry, setEntry] = useState(props.input_value)
  const [installments, setInstallments] = useState([])
  const [activeBank, setActiveBank] = useState('')
  const [banks, setBanks] = useState([])
  const [times, setTimes] = useState(null)
  const [showModalDetails, setShowModalDetails] = useState(false)
  const [detailsInfo, setDetailsInfo] = useState([])
  const [textDetailsInfo, setTextDetailsInfo] = useState('')
  const [loading, setLoading] = useState(false)
  const [opts, setOpts] = useState(moneyMask)
  const { ref, maskRef } = useIMask(opts)

  const activeBankRef = useRef(activeBank)
  const entryRef = useRef(props.input_value)

  const errorsInstallments = useSelector(
    (state) => state.financingInstallments.errorsInstallments
  )
  const errorApi = useSelector((state) => state.financingInstallments.errorApi)
  const [errorsEntryValue, setErrorsEntryValue] = useState({})

  const dispatch = useDispatch()

  const updateActiveBankStateAndRef = (value) => {
    setActiveBank(value)
    activeBankRef.current = value
  }

  const updateEntryStateAndRef = (value) => {
    setEntry(value)
    entryRef.current = value
  }

  const getInstallmentsFromApiDebounced = debounce(() => {
    onInstallmentChange(null)
    getInstallmentsFromAPI(activeBankRef.current)
  }, 1000)

  const handleInputRangeChange = useCallback((value, mask) => {
    updateEntryStateAndRef(value)
    onInputRangeChange(moneyFormat.from(value))

    setLoading(true)
    getInstallmentsFromApiDebounced()
  }, [])

  const handleCheckChange = useCallback(
    (e) => {
      const { value } = e.target

      dispatch(setErrorInstallments(false))

      const times = Number(value)
      setTimes(times)

      const selectInstallment = installments.find(
        (item) => item.times === times
      )

      onInstallmentChange(selectInstallment)
    },
    [installments]
  )

  const handleBankClick = useCallback(
    (bank) => {
      setTimes(null)
      onInstallmentChange(null)

      updateActiveBankStateAndRef(bank)

      getInstallmentsFromAPI(bank)

      onBankChange(bank)
    },
    [activeBank, entry]
  )

  const handleDetailsClick = useCallback(
    (times) => {
      const installmentsDetail = installments.find(
        (item) => item.times === times
      )
      setDetailsInfo(installmentsDetail.details)
      setShowModalDetails(true)
      setTextDetailsInfo(installmentsDetail.text)
    },
    [installments]
  )

  const handleModalDetailsHide = useCallback(() => {
    setShowModalDetails(false)
  }, [])

  const fixedInstallmentDetails = [
    { title: 'Veículo:', value: props.vehicleName }, // fixo
    { title: 'Valor entrada:', value: moneyFormat.to(entryRef.current) }, // fixo
  ]

  const getInstallmentsFromAPI = (bank) => {
    setLoading(true)

    const input_value = entryRef.current
    if (!useSimpleInstallments) {
      financingSimulationService
        .update({
          sale_id: props.sale_id,
          bank: bank,
          input_value: input_value,
        })
        .then((installments) => {
          const installmentsWithDetails = installments.map((installment) => {
            installment.details = fixedInstallmentDetails.concat([
              { title: 'Valor prestação', value: installment.value },
              {
                title: 'Valor do veículo:',
                value: moneyFormat.to(props.vehiclePrice),
              }, // fixo
              { title: 'Parcelas:', value: installment.times },
              {
                title: 'Taxa ao ano (%)',
                value: installment.annual_interest_rate,
              },
              {
                title: 'Taxa ao mês (%)',
                value: installment.monthly_interest_rate,
              },
              { title: 'Imposto IOF', value: installment.iof },
              { title: 'CET ao Ano (%)', value: installment.cet_anual },
              { title: 'CET ao mês (%)', value: installment.cet_mensal },
            ])

            return installment
          })

          setInstallments(installmentsWithDetails)
          dispatch(setErrorApi(false))
        })
        .catch((error) => {
          dispatch(setErrorApi(true))
        })
        .finally(() => {
          setLoading(false)
          onInstallmentChange(null)
          onInstallmentsRequested()
        })
    }
  }

  function validateEntryValue(value) {
    const errors = {}
    errors.aboveMaxValue = value > max
    errors.underMinValue = value < min

    return errors
  }

  useEffect(() => {
    setErrorsEntryValue(validateEntryValue(moneyFormat.from(entry)))
  }, [entry])

  useEffect(() => {
    dispatch(setErrorInstallments(props.errorsInstallments))
  }, [props.errorsInstallments])

  useEffect(() => {
    if (useSimpleInstallments) return

    const optionsBanks = ['Itaú']
    const selectedBank = optionsBanks[0]

    setBanks(optionsBanks)
    updateActiveBankStateAndRef(selectedBank)

    onBankChange(selectedBank)

    getInstallmentsFromAPI(selectedBank)
  }, [])

  return (
    <div className="financing-installments">
      <div className="row">
        <div className="col-sm-6">
          {props.showInputRange && (
            <>
              <h4 className="financing-installments__title">Entrada</h4>

              <IMaskInput
                mask={moneyMask}
                value={moneyFormat.from(entry).toString()}
                type="text"
                inputMode="numeric"
                className={`form__control ${
                  errorsEntryValue.aboveMaxValue && 'is-invalid'
                }`}
                placeholder="R$ 20.000"
                onAccept={(e) => handleInputRangeChange(e)}
                required={true}
                maxLength="15" // limitar números de até 7 dígitos R$ d.ddd.ddd,00
              />
            </>
          )}
          {errorsEntryValue.aboveMaxValue && (
            <div className="financing-installments__label-error">
              Valor inserido é maior que o valor do veículo.
            </div>
          )}
        </div>
      </div>

      <hr className="financing-installments__hr" />

      {useSimpleInstallments ? (
        <SimpleInstallments />
      ) : (
        <InstallmentList
          banks={banks}
          activeBank={activeBank}
          installments={installments}
          checkedInstallment={times}
          errorsInstallments={errorsInstallments}
          errorApi={errorApi}
          loading={loading}
          onBankClick={handleBankClick}
          onCheckChange={handleCheckChange}
          onDetailsClick={handleDetailsClick}
        />
      )}

      <ModalDetails
        show={showModalDetails}
        details={detailsInfo}
        text={textDetailsInfo}
        onHide={handleModalDetailsHide}
      />
    </div>
  )
}

FinancingInstallments.defaultProps = {
  errorsInstallments: false,
  showInputRange: true,
}

FinancingInstallments.propTypes = {
  vehicleName: PropTypes.string,
  vehiclePrice: PropTypes.number,
  input_value: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  showInputRange: PropTypes.bool,
  errorsInstallments: PropTypes.bool,
}

export default FinancingInstallments
