const sectors_one = [
  {
    name: 'De segunda à sábado, das 8h às 22h',
    hours: [
      { description: 'Domingos e feriados, sem atendimento' },
      { description: 'Domingos e feriados, sem atendimento' },
    ],
  },
]

const sectors_two = [
  {
    name: 'Online',
    hours: [
      { description: 'De segunda à sábado, das 8h às 22h' },
      { description: 'Domingos e feriados, sem atendimento' },
    ],
  },
  {
    name: 'Showroom',
    hours: [
      { description: 'De segunda à sábado, das 8h às 22h' },
      { description: 'Domingos e feriados, sem atendimento' },
    ],
  },
]

export const storesCarouselElements = [
  {
    display_name: 'Recife',
    address: 'Rua Cabral , 5678, Batel, Curitiba, PE',
    zip_code: '98244-120',
    sectors: sectors_two,
    map: 'https://goo.gl/maps/vtoSB2P5qRp6G5pc6',
    state: 'PE',
  },
  {
    display_name: 'São Paulo',
    address:
      'Av. Farrapos , 2343, São Geraldo, Porto Alegre, RS - CEP 90220-007',
    zip_code: '90220-007',
    sectors: [],
    map: 'https://goo.gl/maps/YvGQ3255QR5x3t7W9',
    state: 'SP',
  },
  {
    display_name: 'Rio de Janeiro',
    address: 'Rua Cabral , 5678, Batel, Curitiba, PE',
    zip_code: '98244-120',
    sectors: sectors_one,
    map: 'https://goo.gl/maps/EeJLAeRf1jiTu9DP7',
    state: 'RJ',
  },
  {
    display_name: 'Niterói',
    address: 'Rua Cabral , 5678, Batel, Curitiba, PE',
    zip_code: '98244-120',
    sectors: sectors_one,
    map: 'https://goo.gl/maps/EeJLAeRf1jiTu9DP7',
    state: 'RJ',
  },
  {
    display_name: 'Campinas',
    address:
      'Av. Farrapos , 2343, São Geraldo, Porto Alegre, RS - CEP 90220-007',
    zip_code: '90220-007',
    sectors: sectors_two,
    map: 'https://goo.gl/maps/YvGQ3255QR5x3t7W9',
    state: 'SP',
  },
  {
    display_name: 'Petrópolis',
    address: 'Rua Cabral , 5678, Batel, Curitiba, PE',
    zip_code: '98244-120',
    sectors: [],
    map: 'https://goo.gl/maps/EeJLAeRf1jiTu9DP7',
    state: 'RJ',
  },
  {
    display_name: 'Nova Iguaçu',
    address: 'Rua Cabral , 5678, Batel, Curitiba, PE',
    zip_code: '98244-120',
    sectors: sectors_one,
    map: 'https://goo.gl/maps/EeJLAeRf1jiTu9DP7',
    state: 'RJ',
  },
]
