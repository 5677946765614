import styled, { css } from 'styled-components'
import BaseButton from './BaseButton'

const PrimaryButton = styled(BaseButton)`
  background-color: var(--primary);
  color: var(--white);

  svg {
    margin: 0 8px 0 8px;
  }

  ${({ disabled }) => !disabled && ButtonHoverActiveStyle}
`

const ButtonHoverActiveStyle = css`
  :hover {
    background-color: var(--primaryDark);
  }

  :active {
    background-color: var(--primaryLight);
  }
`

export default PrimaryButton
