import React from 'react'
import CreditAnalysisInfoUsedVehicle from './UsedVehicle'
import PropTypes from 'prop-types'

const CreditAnalysisInfoItem = (props) => {
  return (
    <div className="financing-requests__info-item">
      <div className="financing-requests__info-item-label">{props.label}:</div>
      <div className="financing-requests__info-item-value">{props.value}</div>
    </div>
  )
}

CreditAnalysisInfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
}

const CreditAnalysisInfo = (props) => {
  return (
    <div className="financing-requests__info">
      {
        props.used_in_exchange &&
        <CreditAnalysisInfoUsedVehicle
          used_vehicle_value={props.used_vehicle_value}
        />
      }

      <CreditAnalysisInfoItem
        label="Parcelas"
        value={`${props.installments} de ${props.installments_value}`}>
      </CreditAnalysisInfoItem>

      <CreditAnalysisInfoItem
        label="Comprador"
        value={props.customer_name}>
      </CreditAnalysisInfoItem>

      <CreditAnalysisInfoItem
        label="CPF"
        value={props.cpf}>
      </CreditAnalysisInfoItem>

      <CreditAnalysisInfoItem
        label="Validade dessa oferta"
        value={props.offer_validity}>
      </CreditAnalysisInfoItem>
    </div>
  )
}

CreditAnalysisInfo.propTypes =  {
  used_in_exchange: PropTypes.bool.isRequired,
  installments: PropTypes.number.isRequired,
  installments_value: PropTypes.string.isRequired,
  customer_name: PropTypes.string.isRequired,
  offer_validity: PropTypes.any.isRequired,
}

export default CreditAnalysisInfo