const SignupPrivacyPolicy = 'SIGNUP/PRIVACY_POLICY'
const SignupTermsOfUse = 'SIGNUP/TERMS_OF_USE'

export function setPrivacyPolicyModalDisplay(show) {
  return { type: SignupPrivacyPolicy, show: show }
}

export function setTermsOfUseDisplay(show) {
  return { type: SignupTermsOfUse, show: show }
}

const initial_state = {
  signup: {
    privacyPolicy: false,
    termsOfUse: false
  }
}

export default function reducer(state = initial_state, action = {}) {
  switch (action.type) {
    case SignupPrivacyPolicy:
      return {
        ...state,
        signup: {
          ...state.signup,
          privacyPolicy: action.show
        }
      }
    case SignupTermsOfUse:
      return {
        ...state,
        signup: {
          ...state.signup,
          termsOfUse: action.show
        }
      }

    default:
      return state
  }
}
