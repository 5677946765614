import React, { useEffect, useState, useRef } from 'react'
import {
  Accordion,
  BottomSheet,
  Button,
  IllustratedIcon,
} from 'autocommerce-sb'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { goTop } from '../../actions'
import useSmallWindow from '../../hooks/useSmallWindow'
import { setAvaliationFlowStep } from '../../../../store/ducks/avaliation-flow'
import {
  LeaveModalButton,
  LeaveMouseDesc,
  LeaveMouseModal,
  LeaveMouseOverlay,
  LeaveMouseTitle,
  BigScreenModal,
  BigScreenModalDesc,
  BigScreenModalTitle,
  Container,
  CotationWarning,
  FailedRequisitionContainer,
  FailedRequisitionCTA,
  FailedRequisitionFaqTitle,
  FailedRequisitionTitle,
  Results,
  ResultValue,
  ScheduleVisit,
  StyledIcon,
  WarningContainer,
  WarningText,
} from './style'

const Calendar = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 20V14.7C40 12.1043 37.8957 10 35.3 10H12.7C10.1043 10 8 12.1043 8 14.7V20M40 20H8M40 20V23.5M8 20V37.3C8 39.8957 10.1043 42 12.7 42H22M16 6V14M32 6V14"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M28 38L32 42L42 32"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const AvaliationResult = ({ saleInfo, formValues }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSmallWindow = useSmallWindow(600)
  const isSmallFailedReqWindow = useSmallWindow(800)
  const [mobileModal, setMobileModal] = useState(false)
  const [bigScreenModal, setBigScreenModal] = useState(false)

  const isFirstAttemptToLeave = useRef(true)

  const [leaveMouseModal, setLeaveMouseModal] = useState(false)

  const preventFirstLeave = () => {
    if (isFirstAttemptToLeave.current) {
      setLeaveMouseModal(true)
      isFirstAttemptToLeave.current = false
    }
  }

  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(8))
  }, [])

  const handleClick = () => {
    history.push({
      pathname: '/compramos-seu-usado/agendar-inspecao',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
  }

  const replacePrice = (rawPrice) => {
    const [price, cents] = [
      rawPrice.replace(/R\$\s/, '').replace(/\,[0-9]{2}/, ''),
      rawPrice.replace(/.*?\,/, ''),
    ]
    return [price, cents]
  }

  const [carLabel, setCarLabel] = useState(
    `${saleInfo.make} ${saleInfo.model} ${saleInfo.version} ${saleInfo.year}`
  )
  useEffect(() => {
    setCarLabel(
      `${saleInfo.make} ${saleInfo.model} ${saleInfo.version} ${saleInfo.year}`
    )
  }, [saleInfo.make, saleInfo.model, saleInfo.version, saleInfo.year])

  const dealerText = () => {
    if (document.location.host.includes('akta.autocommerce')) {
      return ' em empresa homologada pelo Grupo Akta.'
    } else {
      return '.'
    }
  }

  if (saleInfo.estimated_price && saleInfo.estimated_price !== 'R$ 0,00') {
    return (
      <Container>
        <Results>
          Compramos seu <strong>{`${saleInfo.make} ${saleInfo.model}`}</strong>{' '}
          e pagamos em média
        </Results>
        <ResultValue>
          R$<strong>{replacePrice(saleInfo.estimated_price)[0]},</strong>
          {replacePrice(saleInfo.estimated_price)[1]}
        </ResultValue>
        <WarningContainer
          onClick={() => {
            isSmallWindow ? setMobileModal(true) : setBigScreenModal(true)
          }}
        >
          <StyledIcon className="icon icon-info-grid"></StyledIcon>
          <WarningText>Entenda esse valor</WarningText>
        </WarningContainer>
        <CotationWarning breakLine={carLabel.length > 22}>
          <p>
            Cotação para <strong>{carLabel}</strong>
          </p>
        </CotationWarning>
        {/*
          Feature de bonus ainda não implementada
          <BonusBanner>
          <StyledIcon className='icon icon-dollar-sign' ></StyledIcon>
          <div>
            <BonusTitle>Bônus de troca!</BonusTitle>
            <BonusCTA>Ganhe até <strong>R$ 1.250,00</strong> ao trocar seu carro com a Kantam.</BonusCTA>
          </div>
        </BonusBanner> */}
        <ScheduleVisit>Só falta agendar sua visita</ScheduleVisit>
        <Button
          label="Agendar inspeção presencial"
          width={isSmallWindow ? 312 : 424}
          onClick={() => handleClick()}
        />
        <BottomSheet
          title="Porque esse preço?"
          description={`Esta é uma oferta inicial e o valor final dependerá do resultado da avaliação presencial e aprovação sem apontamentos de perícia cautelar${dealerText()}`}
          secondDescription={`A oferta inicial representa uma estimativa do valor do seu veículo, calculada com base nos dados transacionais de mercado e no histórico de venda do seu seminovo. Valor: R$${
            replacePrice(saleInfo.estimated_price)[0]
          },${replacePrice(saleInfo.estimated_price)[1]}`}
          isOpen={mobileModal}
          onClose={() => setMobileModal(false)}
        />
        {/*
        Código da prova social, de acordo com o card, não vai ser implementado agora
        <JoinUsContainer>
          {joinUs.map((e, i) => {
            return(
              <img
                src={`${baseURL}${e}`}
                key={i}
                style={{transform: `translateX(-${i*8}px)`}}
              />
            )
          })}
        </JoinUsContainer>
        <JoinUsCTA>Junte-se a +100 pessoas que já fizeram a melhor venda de suas vidas com a AutoDealer</JoinUsCTA>
        */}
        <BigScreenModal bigScreenModal={bigScreenModal}>
          <div>
            <svg
              onClick={() => setBigScreenModal(false)}
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="16" fill="#F8F8F9" />
              <path
                d="M22 10L10 22"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10L22 22"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <BigScreenModalTitle>Porque esse preço?</BigScreenModalTitle>
            <BigScreenModalDesc id="modal-description-top">
              Esta é uma oferta inicial e o valor final dependerá do resultado
              da avaliação presencial e aprovação sem apontamentos de perícia
              cautelar{dealerText()}
            </BigScreenModalDesc>
            <BigScreenModalDesc id="modal-description-bottom">
              A oferta inicial representa uma estimativa do valor do seu
              veículo, calculada com base nos dados transacionais de mercado e
              no histórico de venda do seu seminovo. Valor: R$
              <strong>{replacePrice(saleInfo.estimated_price)[0]},</strong>
              {replacePrice(saleInfo.estimated_price)[1]}
            </BigScreenModalDesc>
          </div>
          <div
            className="overlay"
            onClick={() => setBigScreenModal(false)}
          ></div>
        </BigScreenModal>
        <LeaveMouseOverlay onMouseLeave={preventFirstLeave} />
        <LeaveMouseModal leaveMouseModal={leaveMouseModal}>
          <div>
            <svg
              className="close-btn"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setLeaveMouseModal(false)}
            >
              <rect width="32" height="32" rx="16" fill="#F8F8F9" />
              <path
                d="M22 10L10 22"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10L22 22"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="icon-container">
              <IllustratedIcon
                Icon={() => <Calendar />}
                color="var(--primary)"
                borderColor={'var(--primaryLightest)'}
              />
            </div>
            <LeaveMouseTitle>
              Você ainda não agendou sua inspeção
            </LeaveMouseTitle>
            <LeaveMouseDesc>
              Ao realizar o agendamento você acelera seu processo de venda, é
              simples e direto na plataforma, basta selecionar dia e horário.
            </LeaveMouseDesc>
            <LeaveModalButton
              label="Entendi"
              onClick={() => setLeaveMouseModal(false)}
            />
          </div>
          <div
            className="overlay"
            onClick={() => setLeaveMouseModal(false)}
          ></div>
        </LeaveMouseModal>
      </Container>
    )
  } else {
    return (
      <FailedRequisitionContainer>
        <svg
          width="104"
          height="104"
          viewBox="0 0 104 104"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            className="outside-ring"
            width="104"
            height="104"
            rx="52"
            fill="#F1EFFF"
          />
          <rect
            className="inside-ring"
            x="12"
            y="12"
            width="80"
            height="80"
            rx="40"
            fill="#5D4DD9"
          />
          <path
            d="M63.561 52H40.4391C39.3611 52 38.3099 52.3362 37.4319 52.9617C36.0672 53.9341 35.2568 55.5064 35.2568 57.1822V67.8619C35.2568 68.3486 35.6514 68.7432 36.1381 68.7432H38.3411H40.5442C41.0308 68.7432 41.4254 68.3486 41.4254 67.8619V64.9383C41.4254 64.5811 41.715 64.2915 42.0722 64.2915H52H62.0459C62.8246 64.2915 63.4559 64.9227 63.4559 65.7014V67.8619C63.4559 68.3486 63.8504 68.7432 64.3371 68.7432H67.8619C68.3486 68.7432 68.7432 68.3486 68.7432 67.8619V57.1822C68.7432 55.5064 67.9328 53.9341 66.5681 52.9617C65.6901 52.3362 64.6389 52 63.561 52Z"
            stroke="white"
            strokeWidth="2"
          />
          <line
            x1="58.8154"
            y1="58.4772"
            x2="63.4619"
            y2="58.4772"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <line
            x1="41.2754"
            y1="58.3235"
            x2="45.9218"
            y2="58.3235"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M51 40.9541H44.4658C42.5356 40.9541 40.8219 42.1893 40.2115 44.0205L37.6571 51.6838C37.4824 52.2077 37.7656 52.7741 38.2895 52.9487C38.8135 53.1233 39.3798 52.8402 39.5544 52.3162L42.1089 44.6529C42.447 43.6384 43.3965 42.9541 44.4658 42.9541H51V40.9541Z"
            fill="white"
          />
          <path
            d="M60.5576 45.6747C63.4345 45.6747 65.7666 43.3426 65.7666 40.4658C65.7666 37.5889 63.4345 35.2568 60.5576 35.2568C57.6808 35.2568 55.3486 37.5889 55.3486 40.4658C55.3486 43.3426 57.6808 45.6747 60.5576 45.6747Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.0687 46.9771L64.2363 44.1447"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <FailedRequisitionTitle>
          Hmmm...seu veículo parece ser especial
        </FailedRequisitionTitle>
        <FailedRequisitionCTA>
          Por algum motivo não conseguimos encontrar o seu veículo em nossa base
          de dados, mas fique tranquilo, ainda conseguimos avaliar o seu veículo
          presencialmente. Para isso realize o agendamento da inspeção.
        </FailedRequisitionCTA>
        <Button
          label="Agendar inspeção presencial"
          width={isSmallFailedReqWindow ? 312 : 272}
          onClick={() => handleClick()}
        />
        <FailedRequisitionFaqTitle>
          Principais dúvidas
        </FailedRequisitionFaqTitle>
        <Accordion
          className="failed-req-accordion"
          title="Meu veículo não foi avaliado, e agora?"
          description="Isso pode ter acontecido por diversos motivos, portanto não se preocupe. Nosso sistema busca os dados do seu veículo na nossa base de dados e utiliza um sistema de regras de precificação para definir quanto podemos pagar por ele. Em alguns casos raros esse processo pode falhar, mas isso não significa que não podemos comprar seu veículo. Realize o agendamento ou entre em contato conosco para fazermos uma avaliação presencial."
          width={isSmallFailedReqWindow ? '312px' : '800px'}
          descriptionHeight={isSmallWindow ? '330px' : '140px'}
        />
        <Accordion
          className="failed-req-accordion"
          title="Posso agendar a inspeção sem que meu veículo tenha sido avaliado?"
          description="Com certeza, em raras oportunidas o sistema falha ao processar a pré-avaliação, mas ainda podemos comprar seu veículo ao realizar uma inspeção presencial. Sinta-se seguro para continuar com seu agendamento, e se ainda tiver dúvidas, entre em contato conosco."
          width={isSmallFailedReqWindow ? '312px' : '800px'}
          descriptionHeight={isSmallWindow ? '210px' : '90px'}
        />
        <LeaveMouseOverlay onMouseLeave={preventFirstLeave} />
        <LeaveMouseModal leaveMouseModal={leaveMouseModal}>
          <div>
            <svg
              className="close-btn"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setLeaveMouseModal(false)}
            >
              <rect width="32" height="32" rx="16" fill="#F8F8F9" />
              <path
                d="M22 10L10 22"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10L22 22"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="icon-container">
              <IllustratedIcon
                Icon={() => <Calendar />}
                color="var(--primary)"
                borderColor={'var(--primaryLightest)'}
              />
            </div>
            <LeaveMouseTitle>
              Você ainda não agendou sua inspeção
            </LeaveMouseTitle>
            <LeaveMouseDesc>
              Ao realizar o agendamento você acelera seu processo de venda, é
              simples e direto na plataforma, basta selecionar dia e horário.
            </LeaveMouseDesc>
            <LeaveModalButton
              label="Entendi"
              onClick={() => setLeaveMouseModal(false)}
            />
          </div>
          <div
            className="overlay"
            onClick={() => setLeaveMouseModal(false)}
          ></div>
        </LeaveMouseModal>
      </FailedRequisitionContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  saleInfo: state.avaliationFlow.saleInfo,
  formValues: state.avaliationFlow.formValues,
})

export default connect(mapStateToProps)(AvaliationResult)
