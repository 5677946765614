import React from 'react'
import { defaultStyles, Select } from './styles'

const CustomSelect = ({
  value = undefined,
  defaultValue = undefined,
  options = [],
  components = {},
  styles = {},
  ...rest
}) => {
  const customComponents = {
    IndicatorSeparator: null,
    ...components,
  }

  const customSelectStyles = {
    ...defaultStyles,
    ...styles,
  }

  const valueIsNotEmptyOrNull = !['', null, undefined].includes(value)

  // fix para comparar uma opção do tipo manager
  const comp =
    valueIsNotEmptyOrNull && value.hasOwnProperty('$oid')
      ? (option, value) => option['$oid'] === value['$oid']
      : (option, value) => option === value

  if (valueIsNotEmptyOrNull) {
    value = options.find((option) => comp(option.value, value))
  }

  const defaultValueIsNotEmptyOrNull = !['', null, undefined].includes(
    defaultValue
  )

  if (defaultValueIsNotEmptyOrNull) {
    defaultValue = options.find((option) => comp(option.value, defaultValue))
  }

  return (
    <Select
      components={customComponents}
      options={options}
      styles={customSelectStyles}
      defaultValue={defaultValue}
      value={value}
      {...rest}
    />
  )
}

export default CustomSelect
