/**
 * Deixa os vídeos responsivos.
 * Adicionar a class .with-embed-responsive ao container.
 *
 * @param {String} container Seletor css
 * @param {String} ratio Proporção
 */
export default function (container, ratio = '16by9') {
  const elContainerEmbed = document.querySelectorAll(container)

  elContainerEmbed.forEach(elContainer => {
    let elsEmbed = elContainer.querySelectorAll('iframe, embed, video, object')

    elsEmbed.forEach(elEmbed => {
      let wrapper = document.createElement('div')

      elEmbed.parentNode.insertBefore(wrapper, elEmbed)
      wrapper.appendChild(elEmbed)

      wrapper.classList.add('embed-responsive')
      wrapper.classList.add(`embed-responsive-${ratio}`)
      elEmbed.classList.add('embed-responsive-item')
    })
  })
}
