import React, { useCallback, useMemo } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector, useDispatch } from 'react-redux'
import { setOpenModalReserveTerm } from '../../store/ducks/modal-reserve-term'

const ModalReserveTerm = ({
  vehicleReservationDays,
  onRejectClick = () => {},
  onAcceptClick = () => {},
}) => {
  // @ts-ignore
  const show = useSelector((state) => state.modalReserveTerm.open)
  const dispatch = useDispatch()

  const handleHide = useCallback(() => {
    dispatch(setOpenModalReserveTerm(false))
  }, [show])

  const vehicleReservationTime = vehicleReservationDays + ' dia(s)'

  return (
    <Modal
      className="modal-reservation-term"
      show={show}
      size="xl"
      centered
      onHide={handleHide}
    >
      <div>
        <h3>TERMO DE RESERVA DE VEÍCULO</h3>
        <br />
        <p>
          Ao concordar com o presente Termo de Reserva do Veículo ou promover a
          reserva do veículo, o <strong>Cliente</strong>, já devidamente
          qualificado por meio das informações preenchidas no ato de cadastro na
          plataforma, também denominado como “Comprador”, declara{' '}
          <strong>expressa ciência</strong> e <strong>concorda</strong> que:
        </p>

        <ol>
          <li>
            Os valores dos veículos exibidos nas telas iniciais de compra
            poderão sofrer reajustes, com a inclusão de tarifas, tributos ou
            acréscimos decorrentes do processo de compra.
          </li>
          <li>
            Os valores exibidos relativos ao veículo poderão ser atualizados a
            qualquer momento pela Concessionária. Portanto, não há qualquer
            garantia de preço até a efetiva finalização da compra pelo Cliente.
          </li>
          <li>
            O valor final para pagamento será exibido na tela{' '}
            <strong>“Resumo do pedido”</strong>, sendo este valor o válido para
            fins de efetiva venda e processamento de pagamento.
          </li>
          <li>
            O veículo reservado estará sujeito à disponibilidade em estoque pela
            Concessionária.
          </li>
          <li>
            A reserva do veículo tem o objetivo único de retirar o veículo
            objeto da reserva do site da Concessionária e gerar a sua
            indisponibilidade para venda na loja física pelo prazo máximo de{' '}
            {vehicleReservationTime}.
          </li>
          <li>
            O valor pago a título de reserva do veículo garantirá a reserva do
            bem pelo prazo máximo de {vehicleReservationTime}, período no qual o
            cliente deverá finalizar o processo de compra com a realização do
            pagamento e a assinatura do contrato de compra e venda respectivo.
          </li>
          <li>
            Decorridos os {vehicleReservationTime}, caso o cliente não finalize
            o processo de compra, configurado com a assinatura do contrato e o
            pagamento dos valores devidos, a reserva será automaticamente
            cancelada, com o retorno da exibição do veículo na Plataforma e a
            sua liberação para venda em loja física.
          </li>
          <li>
            Com o cancelamento da reserva, os valores pagos na reserva serão
            automaticamente estornados ao Cliente pela Concessionária.{' '}
          </li>
          <li>
            A concessionária dará a ordem de estorno de forma automática.
            Entretanto, o efetivo ressarcimento dos valores ou estorno da
            cobrança pode demorar mais dias para ocorrer, respeitando os
            procedimentos da sua instituição financeira e/ou bancária.
          </li>
          <li>
            A Concessionária e seus prestadores de serviços não se
            responsabilizam por qualquer falha, dano ou vício no estorno ou
            ressarcimento dos valores de responsabilidade das instituições
            financeiras e/ou bancárias.
          </li>
          <li>
            Em caso de finalização do processo de compra, os valores pagos no
            ato de reserva serão debitados do valor final do veículo para
            pagamento.
          </li>
        </ol>

        <p>
          O presente termo tem força de contrato entre o Cliente e a
          Concessionária para todos os fins de direito, e será considerado como
          parte integrante do contrato de compra e venda em caso de finalização
          do processo de compra.
        </p>
      </div>

      <div className="modal-reservation-term__ctas">
        <a
          href=""
          className="sales__btn sales__btn--back"
          onClick={onRejectClick}
        >
          Cancelar reserva
        </a>

        <a
          href="#"
          className="sales__btn sales__btn--next"
          onClick={onAcceptClick}
        >
          Eu concordo
          <i className="icon icon-chevron-right-solid"></i>
        </a>
      </div>
    </Modal>
  )
}

export default ModalReserveTerm
