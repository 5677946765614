import { combineReducers } from 'redux'
import modalWhatsapp from './ducks/modal-whatsapp'
import modalContact from './ducks/modal-contact'
import modalActiveBestPrice from './ducks/modal-active-best-price'
import financingInstallments from './ducks/financing-installments'
import modalPurchaseTerm from './ducks/modal-purchase-term'
import modalReserveTerm from './ducks/modal-reserve-term'
import creditAnalysis from './ducks/credit-analysis'
import financingRequests from './ducks/financing-request'
import termModals from './ducks/term-modals'
import avaliationFlow from './ducks/avaliation-flow'
import toggleGallery360 from './ducks/toggle-gallery-360'
import timeline from './ducks/timeline'
import customers from './ducks/customers'

export default combineReducers({
  modalWhatsapp,
  modalContact,
  modalActiveBestPrice,
  financingInstallments,
  modalPurchaseTerm,
  modalReserveTerm,
  creditAnalysis,
  financingRequests,
  termModals,
  avaliationFlow,
  toggleGallery360,
  timeline,
  customers,
})
