// Actions
const SET_CUSTOMERS_LIST = 'customers/SET_CUSTOMERS_LIST'
const SET_CUSTOMERS_COUNTERS = 'customers/SET_CUSTOMERS_COUNTERS'
const TOGGLE_FILTER_BY_FIELD = 'customers/TOGGLE_FILTER_BY_FIELD'
const SET_TEXT_SEARCH = 'customers/SET_TEXT_SEARCH'
const SET_MANAGER_ID = 'customers/SET_MANAGER_ID'
const SET_DATE_RANGE = 'customers/SET_DATE_RANGE'
const SET_ORDER = 'customers/SET_ORDER'
const SET_PILL_FILTER = 'customers/SET_PILL_FILTER'
const SET_PILL_REASON_FILTER = 'customers/SET_PILL_REASON_FILTER'
const SET_CUSTOMERS_PAGE = 'customers/SET_CUSTOMERS_PAGE'
const SET_INDEX_TOTAL_PAGES = 'customers/SET_INDEX_TOTAL_PAGES'
const SET_INDEX_TOTAL_COUNT = 'customers/SET_INDEX_TOTAL_COUNT'
const SET_CO_RESPONSIBLE_LIST = 'customers/SET_CO_RESPONSIBLE_LIST'
const SET_CUSTOMERS_LOADING = 'customers/SET_CUSTOMERS_LOADING'

// função auxiliar para setar os params na própria URL
export function toQueryParams(params) {
  const queryParams = new URLSearchParams()

  for (const key in params) {
    const value = params[key]

    if (!value) continue

    if (value.constructor == Array)
      value.forEach((element) => queryParams.append(`${key}[]`, element))
    else queryParams.append(key, value)
  }

  return `?${queryParams.toString()}`
}

const getParam = (param = '') => {
  const reasonsFilter = ['proposal_value', 'searching', 'give_up', 'other']
  const urlParams = new URL(location).searchParams

  if (param === 'pillFilter')
    return urlParams
      .getAll('deal_status[]')
      .filter((el) => !reasonsFilter.includes(el))
  else if (param === 'pillReasonFilter')
    return urlParams
      .getAll('deal_status[]')
      .filter((el) => reasonsFilter.includes(el))

  return param.endsWith('[]') ? urlParams.getAll(param) : urlParams.get(param)
}

// Actions creators
export const setCustomersListLoading = (payload) => {
  return { type: SET_CUSTOMERS_LOADING, payload }
}

export const setCustomersList = (list) => {
  return { type: SET_CUSTOMERS_LIST, payload: list }
}

export const setCustomersCounters = (payload) => {
  return { type: SET_CUSTOMERS_COUNTERS, payload }
}

export const toggleFilter = (field, filter) => {
  return { type: TOGGLE_FILTER_BY_FIELD, field, payload: filter }
}

export const setTextSearch = (text) => {
  return { type: SET_TEXT_SEARCH, payload: text }
}

export const setManagerId = (id) => {
  return { type: SET_MANAGER_ID, payload: id }
}

export const setDateRange = (dateRange) => {
  return { type: SET_DATE_RANGE, payload: dateRange }
}

export const setOrder = (order) => {
  return { type: SET_ORDER, payload: order }
}

export const setPillFilter = (filter) => {
  return { type: SET_PILL_FILTER, payload: filter }
}

export const setPillReasonFilter = (filter) => {
  return { type: SET_PILL_REASON_FILTER, payload: filter }
}

export const setIndexPage = (page) => {
  return { type: SET_CUSTOMERS_PAGE, payload: page }
}

export const setTotalPages = (pages) => {
  return { type: SET_INDEX_TOTAL_PAGES, payload: pages }
}

export const setTotalCount = (count) => {
  return { type: SET_INDEX_TOTAL_COUNT, payload: count }
}

export const setCoResponsibleList = (list) => {
  return { type: SET_CO_RESPONSIBLE_LIST, payload: list }
}

const initial_state = {
  customersList: [],
  schedulings: getParam('schedulings[]') || [],
  stores: getParam('stores[]') || [],
  brands: getParam('brands[]') || [],
  vehicle_types: getParam('vehicle_types[]') || [],
  vehicle_models_buy: getParam('vehicle_models_buy[]') || [],
  vehicle_models_sell: getParam('vehicle_models_sell[]') || [],
  journeys: getParam('journeys[]') || [],
  agents: [],
  textSearch: getParam('search') || '',
  managerId: getParam('manager_id') || '',
  dateRange: getParam('date') || '',
  order: getParam('order') || '',
  pillFilter: getParam('pillFilter') || [],
  pillReasonFilter: getParam('pillReasonFilter') || [],
  page: getParam('page') || 1,
  totalPages: 0,
  customersCount: 0,
  listCount: 0,
  coResponsibleList: [],
  brand_count: {},
  sales_schedules_count: {},
  product_model_count: {},
  used_vehicle_model_count: {},
  vehicle_type_count: {},
  stores_count: {},
  deal_status_count: {},
  sale_type_count: {},
  customersListLoading: false,
}

// Reducer
export default function reducer(state = initial_state, action = {}) {
  switch (action.type) {
    case SET_CUSTOMERS_LIST:
      return {
        ...state,
        customersList: action.payload,
        listCount: action.payload.length,
      }
    case SET_CUSTOMERS_COUNTERS:
      return {
        ...state,
        brand_count: action.payload['brand_count'] || {},
        sales_schedules_count: action.payload['sales_schedules_count'] || {},
        product_model_count: action.payload['product_model_count'] || {},
        used_vehicle_model_count:
          action.payload['used_vehicle_model_count'] || {},
        vehicle_type_count: action.payload['vehicle_type_count'] || {},
        stores_count: action.payload['stores_count'] || {},
        deal_status_count: action.payload['deal_status_count'] || {},
        sale_type_count: action.payload['sale_type_count'] || {},
      }
    case TOGGLE_FILTER_BY_FIELD:
      if (state[`${action.field}`].includes(action.payload)) {
        return {
          ...state,
          [`${action.field}`]: state[`${action.field}`].filter(
            (v) => v !== action.payload
          ),
        }
      } else {
        return {
          ...state,
          [`${action.field}`]: [...state[`${action.field}`], action.payload],
        }
      }
    case SET_TEXT_SEARCH:
      return {
        ...state,
        textSearch: action.payload,
      }
    case SET_MANAGER_ID:
      return {
        ...state,
        managerId: action.payload,
      }
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      }
    case SET_ORDER:
      return {
        ...state,
        order: action.payload,
      }
    case SET_PILL_FILTER:
      if (state.pillFilter.includes(action.payload)) {
        return {
          ...state,
          pillFilter: state.pillFilter.filter((v) => v !== action.payload),
        }
      } else {
        return {
          ...state,
          pillFilter: [...state.pillFilter, action.payload],
        }
      }
    case SET_PILL_REASON_FILTER:
      if (state.pillReasonFilter.includes(action.payload)) {
        return {
          ...state,
          pillReasonFilter: state.pillReasonFilter.filter(
            (v) => v !== action.payload
          ),
        }
      } else {
        return {
          ...state,
          pillReasonFilter: [...state.pillReasonFilter, action.payload],
        }
      }
    case SET_CUSTOMERS_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    case SET_INDEX_TOTAL_COUNT:
      return {
        ...state,
        customersCount: action.payload,
      }
    case SET_INDEX_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      }
    case SET_CO_RESPONSIBLE_LIST:
      return {
        ...state,
        coResponsibleList: action.payload,
      }
    case SET_CUSTOMERS_LOADING:
      return {
        ...state,
        customersListLoading: action.payload,
      }
    default:
      return state
  }
}
