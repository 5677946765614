import React from 'react'
import PropTypes from 'prop-types'

const CreditAnalysisApprovedBox = (props) => {
  return (
    <div className="financing-requests__box-approved">
      <i className="financing-requests__box-approved-icon icon icon-smile-wink-regular"></i>

      <h1>Você possui crédito aprovado no<br className="d-none d-xl-block" /> {props.bank} para comprar o seu carro!</h1>
      <p>Confira seus dados e clique em continuar para realizar o<br className="d-none d-xl-block" />pagamento do sinal de reserva do veículo.</p>

      <div className="financing-requests__simulate-title-number">Número da sua simulação:</div>
      <div className="financing-requests__simulate-number">{props.simulation_number}</div>
    </div>
  )
}

CreditAnalysisApprovedBox.propTypes = {
  bank: PropTypes.string.isRequired,
  simulation_number: PropTypes.string
}

export default CreditAnalysisApprovedBox