import { applyCpfMask, applyPhoneMask } from '../utils/applyMasks'
import validationFactory from '../utils/validation'
import {
  renderUseTermsModal,
  renderPrivacyPolicyModal,
} from '../utils/termsModalsRenders'

const elContainer = document.querySelector('.user-register')

if (elContainer) {
  const validator = validationFactory('.user-register__form')
  /**  Variáveis criadas para capturar se os checkboxes das condiçõesde de uso foram marcados.
   * Atualmente temos formulários com 1 ou 2 checkboxes utilizando esse mesmo render, por isso
   * temos as três variáveis logo abaixo.
   * Sugestão: Devemos padronizar isso e usar ou 1 ou 2 checkboxes para as condições de uso
   */
  // usado quando temos apenas 1 checkbox
  const acceptConditionsCheckbox = elContainer.querySelector(
    '#customer_accept_conditions'
  )
  // usado quando temos 2 checkboxes
  const acceptTermsCheckbox = elContainer.querySelector(
    '#customer_accept_terms'
  )
  const acceptPrivacyPolicyCheckbox = elContainer.querySelector(
    '#customer_accept_privacy_policy'
  )

  /**
   * Validação do form
   */
  function validationForm() {
    const elForm = document.querySelector('.user-register__form')

    elForm.addEventListener(
      'submit',
      (e) => {
        const formIsValid = validator.validateAll(elForm).length === 0

        elForm.classList.add('was-validated')

        if (!formIsValid) {
          e.preventDefault()
          return
        }

        elForm.submit()
      },
      false
    )
  }

  function submitWithUsed() {
    const btn = document.querySelector('#continue-with-used')
    const input = document.querySelector('[name="continue-with-used"]')

    if (!btn) return

    btn.addEventListener('click', () => {
      input.value = 'true'
    })
  }

  submitWithUsed()
  applyCpfMask(elContainer)
  applyPhoneMask(elContainer)

  if (document.getElementById('sign_up_fields')) {
    renderUseTermsModal(acceptConditionsCheckbox || acceptTermsCheckbox)
    renderPrivacyPolicyModal(
      acceptConditionsCheckbox || acceptPrivacyPolicyCheckbox
    )
  }

  validationForm()
}
