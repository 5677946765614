import React from 'react'
import { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import clsx from 'clsx'
import { useCallback } from 'react'
import { getTradeInVersions } from '../../service/api'

const UsedVehiclePricing = (props) => {

  const plateRef = useRef(null)

  const [options, setOptions] = useState(props.options)
  const [currentPlate, setCurrentPlate] = useState(props.plate || '')

  const [versionsNotFound, setVersionsNotFound] = useState(false)

  const [loading, setLoading] = useState(false)
  const [version, setVersion] = useState(props.versionId && { label: props.version, value: props.versionId })
  // nome da versão, ex: MI ALCOOL G.III MANUAL 2P 1.0 8V
  const [versionName, setVersionName] = useState(props.version)
  // o name é a combinação de marca + modelo passada pela api de avaliação junto com as versões
  const [name, setName] = useState(props.name)
  const [year, setYear] = useState(props.year)

  const [requestError, setRequestError] = useState(null)

  const setVersionIdAndName = (version) => {
    setVersion(version)
    setVersionName(version.label)
  }

  const setVersionOptions = useCallback(() => {
    if (currentPlate.match(/\w{3}-\d{4}/) || currentPlate.match(/\w{3}-\d{1}\w{1}\d{2}/)) {
      setLoading(true)
      setRequestError(null)
      getTradeInVersions(currentPlate, props.dealerId).then(data => {
        if (data.success) {
          if( data.versions && data.versions.length ){
            setOptions(opts => [...opts, ...data.versions.map(item => ({ label: item.name, value: item.id }))])
            setVersionsNotFound(false)
          } else {
            setVersionsNotFound(true)
          }
          setName(`${data.make.name} ${data.model.name}`)
          setYear(data.year)
          return
        } else {
          console.error('Erro na análise de veículo usado: ', data)
          setRequestError(data)
        }

        setOptions([])
        setVersionIdAndName({ label: '', value: '' })
      })
        .catch(e => {
          console.error(e)
        })
        .finally(() => setLoading(false))
    } else {
      setOptions([])
    }

  }, [currentPlate])

  useEffect(() => {
    plateRef.current.addEventListener('keyup', () => {
      setCurrentPlate(plateRef.current.value)
    })
  })

  useEffect(setVersionOptions, [currentPlate])

  return (
    <div className='row'>
      <div className='col-lg-6'>
        <div className='form-group'>
          <label className='form__control-label'>Placa do usado</label>
          <input placeholder='Ex.: MXM-9292'
                  className='form__control'
                  data-bouncer-target='#invalid-plate'
                  data-mask='plate'
                  required='required'
                  type='text'
                  defaultValue={props.plate}
                  ref={plateRef}
                  name='used_vehicle[plate]'
                  id='used_vehicle_plate'
          />
          <div id='invalid-plate' className='form__invalid-feedback' />
        </div>
      </div>

      <div className='col-lg-6'>
        <div className='form-group'>
          <label className='form__control-label'>Quilometragem atual</label>
          <input placeholder='Ex.: 59.000 Km'
                  className='form__control'
                  data-bouncer-target='#invalid-km'
                  required='required'
                  data-mask='km'
                  defaultValue={props.km}
                  name='used_vehicle[km]'
                  id='used_vehicle_km'
          />
          <div id='invalid-km' className='form__invalid-feedback' />
        </div>
      </div>

      {
        !versionsNotFound && (options.length > 0) &&
        <div className="col-12">
          <div className='d-flex align-items-center alert alert--feedback-success'>
          <i class="icon icon-check-circle-regular feedback-success__icon"></i>
          <strong>{name}</strong>
          </div>
        </div>
      }

      <div className={clsx('col-12', {'element-disabled': !options.length})}>
        <div className='form-group'>
          <label className='form__control-label'>Selecione a versão do modelo</label>
          <input name='used_vehicle[version]' type='hidden' value={versionName || ''} />
          <input name='used_vehicle[name]' type='hidden' value={name} />
          <input name='used_vehicle[year]' type='hidden' value={year} />
          <div className='row'>
            {requestError &&
            <div className='col-12'>
              <div className='alert alert-danger'>
                Houve um erro ao processar sua requisição.
                Por favor, tente novamente.
              </div>
            </div>
            }
            {loading &&
            <div className='col text-center'>
              <div className='text-center spinner-border' />
            </div> ||
            versionsNotFound && (options.length <= 0) &&
            <div className='col'>
              <div class="alert alert-danger" role="alert">
              Não foi possível encontrar versões do seu veículo
              </div>
            </div> ||
            <div className='col'>
              <Select
                isLoading={loading}
                options={options}
                value={version}
                onChange={setVersionIdAndName}
                className='form__control-select'
                classNamePrefix='form__control-select'
                placeholder='Selecione uma opção'
                name='used_vehicle[version_id]'
                isDisabled={options.length ? false : true}
              />
              <input name='used_vehicle[version]'
                     type='hidden'
                     value={versionName || ''}
                     data-bouncer-target='#invalid-version'
                     required
              />
              {!versionName &&
              <div id='invalid-version' className='form__invalid-feedback' />
              }
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsedVehiclePricing
