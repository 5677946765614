const SCHEDULEEDITINFO = 'TIMELINE/SCHEDULEEDITINFO'
const SCHEDULEFILTER = 'TIMELINE/SCHEDULEFILTER'
const TIMELINELIST = 'TIMELINE/LIST'
const SET_DEAL_FILTER = 'TIMELINE/SET_DEAL_FILTER'
const SET_SEARCH_LOGS = 'TIMELINE/SET_SEARCH_LOGS'
const SET_TIMELINE_ORDER = 'TIMELINE/SET_TIMELINE_ORDER'
const CLEAR_TIMELINE_FILTERS = 'TIMELINE/CLEAR_TIMELINE_FILTERS'

export function setScheduleEditingInfo(info) {
  return { type: SCHEDULEEDITINFO, info }
}

export function setScheduleFilter(value) {
  return { type: SCHEDULEFILTER, value }
}

export function setTimelineList(value) {
  return { type: TIMELINELIST, value }
}

export const setDealFilter = (deal) => {
  return { type: SET_DEAL_FILTER, payload: deal }
}

export const setSearchLogs = (payload) => {
  return { type: SET_SEARCH_LOGS, payload }
}

export const setTimelineOrder = (order) => {
  return { type: SET_TIMELINE_ORDER, payload: order }
}

export const clearFilters = () => {
  return { type: CLEAR_TIMELINE_FILTERS }
}

const initial_state = {
  editingScheduleInfo: {},
  schedulingFilter: 'active',
  timelineList: [],
  dealFilter: '',
  searchFilter: '',
  logsOrder: '',
}

export default function reducer(state = initial_state, action = {}) {
  switch (action.type) {
    case SCHEDULEEDITINFO:
      return {
        ...state,
        editingScheduleInfo: action.info,
      }
    case SCHEDULEFILTER:
      return {
        ...state,
        schedulingFilter: action.value,
      }
    case TIMELINELIST:
      return {
        ...state,
        timelineList: action.value,
      }
    case SET_DEAL_FILTER:
      return {
        ...state,
        dealFilter: action.payload,
      }
    case SET_SEARCH_LOGS:
      return {
        ...state,
        searchFilter: action.payload,
      }
    case SET_TIMELINE_ORDER:
      return {
        ...state,
        logsOrder: action.payload,
      }
    case CLEAR_TIMELINE_FILTERS:
      return {
        dealFilter: '',
        searchFilter: '',
        logsOrder: '',
      }
    default:
      return state
  }
}
