import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { PrimaryButton } from '../Buttons'

const InputContainer = styled.div`
  border: ${rem(1)} solid var(--gray-4);
  padding: ${rem(8)} ${rem(8)} ${rem(8)} ${rem(24)};
  border-radius: ${rem(100)};
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: ${rem(16)};
  flex: 1;
  min-width: 0px;
`

const SearchButton = styled(PrimaryButton)`
  background-color: var(--primary);
  color: var(--white);
  border: 0;
  margin-left: ${rem(8)};
  width: ${rem(48)};
  height: ${rem(48)};
  padding: ${rem(14)};
  font-size: ${rem(16)};
  border-radius: ${rem(100)};
`

const NavbarSearchInput = ({ inputProps, buttonProps }) => {
  return (
    <InputContainer>
      <Input
        type="text"
        placeholder="Busque por modelo ou marca"
        name="search"
        {...inputProps}
      />
      <SearchButton {...buttonProps}>
        <i className="icon icon-search"></i>
      </SearchButton>
    </InputContainer>
  )
}

export default NavbarSearchInput
