import React from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'
;(() => {
  const elContainer = document.querySelector('.buy-used-show-select')
  if (!elContainer) return

  // restante do código
  function renderSelectState() {
    const elSelectState = document.getElementById('buy-used-select-state')
    const elDataSelectState = document.querySelector(
      '[data-dom-id="buy-used-select-state"'
    )

    if (!elSelectState || !elDataSelectState) return null

    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelectState.innerHTML
    )

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={options.find((option) => option.value === defaultValue)}
      />,
      elSelectState
    )
  }

  renderSelectState()
})()
