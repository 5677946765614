import styled from 'styled-components'
import { rem } from 'polished'

export const openedStyles = {
  width: '100%',
}

export const MobileMenuContainer = styled.div`
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.5s;
  width: 0;
  height: 100%;
`

export const MobileMenuHeader = styled.header`
  display: flex;
  gap: ${rem(30)};
  align-items: center;
  padding: ${rem(16)} ${rem(24)};

  .logo {
    max-width: ${rem(96)};
  }

  .social-links-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${rem(-14)};
    height: ${rem(36)};
  }
`

export const MobileMenuBody = styled.div`
  background-color: #ffffff;
  flex-grow: 1;
  overflow: auto;
  width: 100%;

  .content {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-end;

    .navbar-header__search-input {
      margin-bottom: ${rem(32)} !important;
    }

    a {
      font-size: ${rem(16)};
      font-weight: 700;
      line-height: ${rem(24)};
      padding: ${rem(20)} ${rem(33)} ${rem(20)} ${rem(24)};
      border-top: ${rem(1)} solid $gray-4;
      border-bottom: ${rem(1)} solid $gray-4;
    }
  }
`

export const MobileMenuFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(32)};
  width: 100%;

  div {
    display: flex;
    justify-content: center;
    padding-bottom: 0 !important;
    width: 100%;
    height: fit-content;

    a {
      background-color: #f8f8f9;
      border-radius: ${rem(100)};
      padding: ${rem(12)};
      width: 100%;
      max-width: ${rem(312)};

      span {
        margin-left: ${rem(8)} !important;
      }
    }
  }
`
