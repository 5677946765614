import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card } from 'autocommerce-sb'
import { useHistory } from 'react-router-dom'

import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
  clearAvaliationFlowForm,
} from '../../../../store/ducks/avaliation-flow'
import { fetchStates } from './actions'
import { fetchPlaces } from '../SelectPlace/action'
import {
  CardContainer,
  Container,
  Title,
  StyledIcon,
  WarningText,
} from './styled'
import useSmallWindow from '../../hooks/useSmallWindow'
import { goTop } from '../../actions'

function normalizeChar(str) {
  return str
    .normalize('NFD')
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
    .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
    .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
}

const SelectState = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSmallWindow = useSmallWindow(600)

  const [stateList, setStateList] = useState([])
  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(5))
    dispatch(clearAvaliationFlowForm(['state_inspection']))
    ;(async () => {
      setStateList(await fetchStates())
    })()
  }, [])

  const handleSelect = (state) => {
    const stateUF = state.label.substr(state.label.length - 2)
    dispatch(
      setAvaliationFlowFormValues(parseInt(state.value), 'state_inspection')
    )
    dispatch(setAvaliationFlowFormValues(stateUF, 'state_inspection_UF'))
    dispatch(setAvaliationFlowFormValues(state.city, 'city_name'))
    dispatch(
      setAvaliationFlowFormValues(normalizeChar(state.city), 'city_inspection')
    )
    ;(async () => {
      const stores = await fetchPlaces(parseInt(state.value), state.city)
      if (stores.length === 1) {
        dispatch(setAvaliationFlowFormValues(stores[0].store_id, 'store_id'))
        dispatch(
          setAvaliationFlowFormValues(stores[0].formated_address, 'address')
        )
        history.push({
          pathname: '/compramos-seu-usado/dados-pessoais',
          search: `?cidade=${normalizeChar(state.city)}&estado=${stateUF}`,
        })
      } else {
        history.push({
          pathname: '/compramos-seu-usado/local-de-inspecao',
          search: `?cidade=${normalizeChar(state.city)}&estado=${stateUF}`,
        })
      }
    })()
    // history.push({
    //   pathname: '/compramos-seu-usado/ano-do-veiculo',
    //   search: `?cidade=${normalizeChar(state.city)}&estado=${stateUF}`,
    // })
    //history.push({pathname: '/compramos-seu-usado/identifique-seu-veiculo', search: `?cidade=${state.city}&estado=${stateUF}`});
  }

  return (
    <Container>
      <Title>Vamos lá, para qual cidade você deseja fazer sua avaliação?</Title>
      {stateList.map((state) => {
        return (
          <CardContainer>
            <Card
              title={`${state.label}`}
              padding="20px 28px"
              onClick={() => handleSelect(state)}
              width={isSmallWindow ? 312 : 488}
            />
          </CardContainer>
        )
      })}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ margin: '50px auto 48px auto' }}
      >
        <StyledIcon className="icon icon-info-grid"></StyledIcon>
        <WarningText>Selecione a opção mais próxima de você</WarningText>
      </div>
    </Container>
  )
}

export default SelectState
