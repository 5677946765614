import { setCookie, getCookie } from './cookie'

const getParameterByName = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(window.location.search)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const getParamameters = () => {
  let source, medium, campaign, content

  source = getParameterByName('source')
    ? 'adwords'
    : getParameterByName('utm_source')

  medium = getParameterByName('medium')
    ? 'medium-' + getParameterByName('medium')
    : getParameterByName('utm_medium')

  medium = getParameterByName('source')
    ? medium + ',source-' + getParameterByName('source')
    : medium

  campaign = getParameterByName('campaign')
    ? 'campaign-' + getParameterByName('campaign')
    : getParameterByName('utm_campaign')

  campaign = getParameterByName('adgroup')
    ? campaign + ',adgroup-' + getParameterByName('adgroup')
    : campaign

  content = getParameterByName('kw')
    ? getParameterByName('kw')
    : getParameterByName('utm_content')

  return {
    source,
    medium,
    campaign,
    content,
  }
}

const hasParameters = () => {
  const { source, medium, campaign, content } = getParamameters()
  if (source || medium || campaign || content) {
    return true
  }
  return false
}

const getUTMString = () => {
  const { source, medium, campaign, content } = getParamameters()
  return (
    'utmcsr=' +
    source +
    '|utmccn=' +
    campaign +
    '|utmcmd=' +
    medium +
    '|utmcct=' +
    content
  )
}

export const createCookieUTM = () => {
  if (hasParameters()) {
    const settings = {
      name: '_utmz',
      value: getUTMString(),
      expirationDays: 30,
    }
    setCookie(settings)
  }
}

export const getUTM = () => {
  if (!hasParameters()) {
    const cookieUTM = getCookie('utmz')
    if (cookieUTM) {
      return cookieUTM
    }
  }
  const utmz = getUTMString()
  return utmz
}
