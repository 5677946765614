import styled from 'styled-components'
import { Card } from 'autocommerce-sb'
import { rem } from 'polished'
import { ContainerBase } from '../../styled'

export const Container = styled(ContainerBase)``

export const Title = styled.h1`
  font-family: Roboto;
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  margin: ${rem(48)} auto;
  @media (max-width: ${rem(600)}) {
    max-width: ${rem(314)};
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
`

export const StyledCard = styled(Card)`
  margin-bottom: ${rem(16)};
  &:last-child {
    margin-bottom: ${rem(48)};
  }
`
