import React from 'react'
import PropTypes from 'prop-types'

const CreditAnalysisSummary = (props) => {
  return (
    <div className="financing-requests__card">
      <div className="financing-request__card-col-image">
        <img src={props.thumb} alt={props.name_and_version} />
      </div>
      <div className="financing-requests__card-col-content">
        <div className="financing-requests__titles">
          <h2>{props.name}</h2>
          <h3>{props.full_name}</h3>
        </div>
        <div className="financing-requets__values">
          <div className="financing-requests__title-value">Valor final</div>
          <div className="financing-requests__value">{props.financing_value} </div>
        </div>
      </div>
    </div>
  )
}

CreditAnalysisSummary.propTypes = {
  thumb: PropTypes.string.isRequired,
  name_and_version: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired,
  financing_value: PropTypes.any.isRequired
}

export default CreditAnalysisSummary