;(() => {
  const container = document.querySelector('.buy-used')

  if (!container) return

  const toggleBoxShadow = () => {
    const buttonContainer = document.querySelector('.action-buttons')

    if (!buttonContainer) return

    const boxShadowValue = buttonContainer.style.boxShadow

    window.addEventListener('scroll', () => {
      const viewHeight = window.visualViewport.height

      buttonContainer.getBoundingClientRect().bottom < viewHeight
        ? (buttonContainer.style.boxShadow = 'none')
        : (buttonContainer.style.boxShadow = boxShadowValue)
    })
  }

  toggleBoxShadow()
})()
