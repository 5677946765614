import styled, { css } from 'styled-components'
import BaseButton from './BaseButton'
import { rem } from 'polished'

const SecondaryButton = styled(BaseButton)`
  background-color: var(--white);
  color: var(--primary);
  border: ${rem(1)} solid var(--primary);

  svg {
    margin: 0 8px 0 8px;
  }

  ${({ disabled }) => !disabled && ButtonHoverActiveStyle}
`

const ButtonHoverActiveStyle = css`
  :hover {
    background-color: var(--primary);
    color: var(--white);
  }

  :active {
    background-color: var(--primaryLighter);
    color: var(--white);
  }
`

export default SecondaryButton
