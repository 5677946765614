import styled from 'styled-components'
import { rem } from 'polished'

export const Option = styled.a<{ selected?: boolean }>`
  display: block;
  margin-top: ${rem(8)};
  border-radius: ${rem(4)};
  padding: ${rem(8)} 0;

  &:hover {
    color: initial;
    text-decoration: none;
  }

  ${(props) => (props.selected ? optionSelected : optionDefault)};
`

export const SearchInput = styled.input.attrs({ className: 'form-control' })`
  border-color: var(--gray-4);
  margin-bottom: ${rem(32)};

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gray-5);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--gray-5);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--gray-5);
  }
`

const optionDefault = `
  color: var(--gray-8);
`

const optionSelected = `
  color: var(--gray-1);
  padding: ${rem(8)};
  background-color: var(--autoforce-blue);

  &:hover {
    color: var(--gray-1);
  }
`
