import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const ModalDetails = ({
  show = false,
  details = [],
  text = '',
  onHide = () => {},
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="xl"
      className="financing-installment__modal-detail"
    >
      <Modal.Header closeButton>
      </Modal.Header>

      <Modal.Body>
        { details.length > 0 &&
          <table className="financing-installment__modal-detail-table">
            <tbody>
              {details.map((detail, index) => (
                <tr key={index}>
                  <th className="financing-installment__modal-detail-table-title">
                    {detail.title}
                  </th>
                  <td className="financing-installment__modal-detail-table-value">{detail.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      </Modal.Body>

      <Modal.Footer>
        <div className="financing-installment__modal-detail-text">{text}</div>
      </Modal.Footer>
    </Modal>
  )
}

ModalDetails.propTypes = {
  show: PropTypes.bool,
  details: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.isRequired,
  })).isRequired,
  text: PropTypes.string,
  onHide: PropTypes.func,
}

export default ModalDetails
