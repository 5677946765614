import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {
  setAvaliationFlowPrimary,
  setAllowShielded,
} from '../../store/ducks/avaliation-flow'

import routes from './router'
import { AvaliationFlowContainer } from './styled'
import Header from './Header'
import { fetchAllowShielded } from './actions'

const AvaliationFlow = () => {
  const dispatch = useDispatch()

  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current) {
      dispatch(
        setAvaliationFlowPrimary(
          window.getComputedStyle(document.body).getPropertyValue('--primary')
        )
      )
      fetchAllowShielded().then((allowShielded) => {
        dispatch(setAllowShielded(allowShielded))
      })
      firstRender.current = false
    }
  }, [])

  return (
    <AvaliationFlowContainer>
      <Router>
        <Header />
        <Switch>
          {routes.map(({ path, comp }) => (
            <Route path={path} component={comp} />
          ))}
        </Switch>
      </Router>
    </AvaliationFlowContainer>
  )
}

export default AvaliationFlow
