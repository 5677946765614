import React from 'react'

const Error = ({ bankName }) => {
  return (
    <div className="credit-analysis__box">
      <h4 className="credit-analysis__error-api-title">
        Opa! Não conseguimos validar sua proposta.
      </h4>
      <p className="credit-analysis__error-api-desc">
        A análise de crédito é um processo feito pelo banco {bankName}, mas pode ser reavaliado com ajuda da
        nossa concessionária. <br/>Entre em contato para garantir as condições da oferta.
      </p>
    </div>
  )
}

export default Error
