import { useState, useEffect } from 'react'

export default (smallWindow) => {
  const [isSmallWindow, setIsSmallWindow] = useState(false)

  useEffect(() => {
    setIsSmallWindow(window.innerWidth < smallWindow)
  }, [window.innerWidth])

  return isSmallWindow
}
