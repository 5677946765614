import React, { forwardRef, useRef } from 'react'

const Checkbox = ({className = '', ...props}, ref) => {
  const el = useRef(null)

  return (
    <label className={`custom-check ${className}`}>
      <input
        ref={node => {
          el.current = node
          ref && ref(node)
          return ref
        }}
        {...props}
      />
      <span className="custom-check__checkmark" />
    </label>
  )
}

export default forwardRef(Checkbox)
