import { applyAllIdentityMasks } from '../utils/applyMasks'
import validationFactory from '../utils/validation'
import {
  renderUseTermsModal,
  renderPrivacyPolicyModal,
} from '../utils/termsModalsRenders.js'

const personalDataContainer = document.querySelector('.personal-data')

if (personalDataContainer) {
  const validator = validationFactory('.personal-data__form')
  const acceptConditionsCheckbox = document.getElementById(
    'used_vehicle_accept_conditions'
  )

  function hideFieldErrorsOnInputKeyup() {
    const inputs = [...document.querySelectorAll('.form__control.is-invalid')]
    inputs.map((input) => {
      input.addEventListener('keyup', () => {
        input.classList.remove('is-invalid')
        input
          .closest('.form-group')
          .querySelector('span')
          .classList.add('d-none')
      })
    })
  }

  function handleFormValidation() {
    const elForm = document.querySelector('.personal-data__form')

    elForm.addEventListener(
      'submit',
      (e) => {
        const formIsValid = validator.validateAll(elForm).length === 0

        if (!formIsValid) {
          e.preventDefault()
          return
        }

        elForm.submit()
      },
      false
    )
  }

  applyAllIdentityMasks(personalDataContainer)
  hideFieldErrorsOnInputKeyup()
  handleFormValidation()
  renderUseTermsModal(acceptConditionsCheckbox)
  renderPrivacyPolicyModal(acceptConditionsCheckbox)
}
