import React from 'react'
import PropTypes from 'prop-types'

const CreditAnalysisFooter = (props) => {

  return (
    <div className="financing-requests__footer">
      { props.used_in_exchange && <div className="financing-requests__warning">
        <strong>
          <i className="icon icon-exclamation-triangle-solid"></i>
          ATENÇÃO:</strong>
        Esta avaliação precisa ser confirmada na concessionária, onde o valor final poderá ser alterado.
      </div>
      } 

      <div className="financing-requests__text-legal">
        {props.legal_notice}
      </div>

    </div>
  )
}

CreditAnalysisFooter.propTypes = {
  legal_notice: PropTypes.string.isRequired,
  used_in_exchange: PropTypes.bool.isRequired
}

export default CreditAnalysisFooter