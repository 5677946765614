import axios from 'axios'

export const validateName = (name) => {
  return typeof name == 'string' && name.length >= 1
}

export const validateEmail = (email) => {
  const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  return regex.test(email)
}

export const validatePhone = (phone) => {
  const regex = new RegExp(/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/)
  return regex.test(phone)
}

export const dealerHasPrivacyPolicy = async () => {
  const baseURL = window.location.origin

  return axios.get(`${baseURL}/api/v1/dealer/store/has_privacy_policy`)
}

export const submitData = async (formData, personalData) => {
  const baseURL = window.location.origin
  const [name, email, rawPhone] = personalData
  const phone = rawPhone.replace(/[^0-9]+/g, '')
  const payload = {
    used_vehicle: {
      ...formData,
      full_name: name,
      email,
      phone,
      city_inspection_text: formData.city_inspection,
    },
    customer: {
      name,
      email,
      phone,
      store_id: formData.store_id,
    },
  }
  try {
    const { data } = await axios.post(
      `${baseURL}/api/v1/dealer/used_vehicle/create`,
      payload
    )
    return data
  } catch (error) {
    return {
      error: error,
      message: error.response?.data?.message,
      sale_id: error.response?.data?.sale_id,
    }
  }
}
