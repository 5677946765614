import React from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'
import { applyPhoneMask } from '../utils/applyMasks'
import validationFactory from '../utils/validation'
import {
  oneOptionChecked,
  oneOptionCheckedErrorMessage,
} from '../utils/oneOptionChecked'

const elContainer = document.querySelector('.page-contact-us')

if (elContainer) {
  const validator = validationFactory('.page-contact-us__form')

  const elInputHiddenSelectStore = document.getElementById('contact_store_id')

  /**
   * Adiciona ou remove class de erro para os selects
   *
   * @param {HTMLElement} elInputHidden
   * @param {HTMLElement} elSelectContainer
   */
  function toggleClassErrorSelect({ elInputHidden, elSelectContainer }) {
    const validate = validator.validate(elInputHidden)

    const isValid = validate ? validate.valid : true

    if (isValid) {
      elSelectContainer.classList.remove('form__select-container--is-invalid')
    } else {
      elSelectContainer.classList.add('form__select-container--is-invalid')
    }
  }

  /**
   * Validação do form
   */
  function validationForm() {
    const elForm = document.querySelector('.page-contact-us__form')

    elForm.addEventListener(
      'submit',
      (e) => {
        const formIsValid = validator.validateAll(elForm).length === 0

        elForm.classList.add('was-validated')

        if (!formIsValid) {
          e.preventDefault()
          return
        }

        elForm.submit()
      },
      false
    )
  }

  applyPhoneMask(elContainer)
  validationForm()
}
