import { dispatchGtagEvent } from '../../utils'

export const configureOrderDetailsEvents = () => {
  const isOrderDetailsPageContainer = document.querySelector('.user-panel')

  if (!isOrderDetailsPageContainer) return

  configureDownloadButtonClick()
  configureAttachmentAreaClick()
}

const configureAttachmentAreaClick = () => {
  // Usamos o container para ficar mais simples de fazer tracking
  const pageContainer = document.querySelector('.user-panel')
  const uploadButtonSelector = '.user-panel__sale-function-upload-label'
  pageContainer?.addEventListener('click', (e) => {
    const target = e.target as HTMLElement
    if (!target.matches(uploadButtonSelector)) return
    dispatchGtagEvent('purchase_details', {
      action: 'clicked_upload_proof_of_payment'
    })
  })
}

const configureDownloadButtonClick = () => {
  // Usamos o container para ficar mais simples de fazer tracking
  const pageContainer = document.querySelector('.user-panel')
  const downloadButtonSelector = '.user-panel__sale-function-button[download]'
  pageContainer?.addEventListener('click', (e) => {
    const target = e.target as HTMLElement
    const parent = target.parentElement
    // Verificamos um match com o parent porque o target pode ser o span
    // que se encontra atualmente na estrutura bo botão
    if (!parent.parentElement.matches(downloadButtonSelector)) return
    const { action } = target.dataset
    dispatchGtagEvent('purchase_details', {
      action
    })
  })
}