import React from 'react'
import { render } from 'react-dom'
import validationFactory from '../utils/validation'
import {
  handlePaymentAcceptTerms,
  renderModalPurchaseTerm,
  renderUploads,
} from './payments/shared'
import PaymentMethodChooser from '../components/Payments/PaymentMethodChooser.tsx'
import VehiclePaymentInfo from '../components/Payments/VehiclePaymentInfo.tsx'
;(() => {
  const elPaymentContainer = document.querySelector(
    '.payment--cash-without-used, .sales.documents-upload, .guided-flow-payment'
  )
  if (!elPaymentContainer) return

  // É importante deixar essa estrutura compatível com a interface PaymentMethod
  const paymentMethods = [
    {
      value: 'pix',
      label: 'PIX',
      iconName: 'pix',
      description: 'O dinheiro cai na hora',
    },
    {
      value: 'bank-billet',
      label: 'Boleto',
      iconName: 'file-alt-solid',
      description: 'Confirmação leva até 3 dias',
    },
  ]

  const validator = validationFactory('.payment__form')

  /**
   * Validação do form
   */
  function validationForm() {
    /**
     * @type {HTMLFormElement}
     */
    const elForm = document.querySelector('.payment__form')

    if (!elForm) return false

    elForm.addEventListener(
      'submit',
      (e) => {
        const formIsValid = validator.validateAll(elForm).length === 0
        elForm.classList.add('was-validated')
        e.preventDefault()
        if (!formIsValid) return
        elForm.submit()
      },
      false
    )
  }

  // Renderização do componente de escolha de método de pagamento e de informação do veículo
  function renderPaymentMethodChooser() {
    const container = document.getElementById('payment-method-chooser')
    if (!container) return

    const props = {
      fieldName: 'sale[payment_method]',
      paymentMethods,
    }

    render(<PaymentMethodChooser {...props} />, container)
  }

  // Renderiza o cabeçalho de informações de pagamento de veículo no passo 5 (pagamento do veículo)
  function renderVehiclePaymentInfo() {
    const container = document.getElementById('vehicle-payment-info')
    if (!container) return

    const props = JSON.parse(container?.dataset?.props || {})
    const finalProps = {
      ...props,
      paymentMethods,
    }

    render(<VehiclePaymentInfo {...finalProps} />, container)
  }

  renderUploads()
  renderModalPurchaseTerm()
  handlePaymentAcceptTerms()
  validationForm()
  renderPaymentMethodChooser()
  renderVehiclePaymentInfo()
})()
