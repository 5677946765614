import {render} from "react-dom";
import UploadDropzoneTwo from "../components/UploadDropzoneTwo";
import React from "react";
import DocumentPageUploads from "../components/Payments/DocumentPageUploads";

(() => {
    const container = document.getElementById('documents-uploads')
    if (!container) return

    render(<DocumentPageUploads/>, container)
})()