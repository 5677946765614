// Tela de recebimento de veículo

import { dispatchGtagEvent } from '../../utils'

export const configureVehicleDeliveryTakeoutEvents = () => {
  const isReceiveVehiclePage = document.querySelector('.receive-vehicle')
  if (!isReceiveVehiclePage) return
  configureEditAddressButtonEvent()
  configureSaveAddressEvent()
}

const configureEditAddressButtonEvent = () => {
  const editAddressButton = document.querySelector('.receive-vehicle__btn-add-address')
  if (!editAddressButton) return
  editAddressButton.addEventListener('click', () => {
    dispatchGtagEvent('cash_purchase_delivery', {
      action: 'clicked_edit_address'
    })
  })
}

const configureSaveAddressEvent = () => {
  const submitButton = document.querySelector('form.edit_customer #save-address')
  if (!submitButton) return
  submitButton.addEventListener('click', () => {
    dispatchGtagEvent('cash_purchase_delivery', {
      action: 'clicked_save_new_address'
    })
  })
}