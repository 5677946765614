import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import PaymentMethod from "./PaymentMethod";
import Select from 'react-select'
import {Overlay, Tooltip} from "react-bootstrap";
import { dispatchGtagEvent } from '../../renders/analytics/utils'

export default function VehiclePaymentInfo(props: VehiclePaymentInfoProps) {
    const {
        product,
        signalValue,
        paymentMethods,
        salePaymentMethod,
        showPaymentMethodSelect
    } = props
    const [paymentMethod, setPaymentMethod] = useState(salePaymentMethod)
    const getPaymentMethodOption = useCallback((value: string) => paymentMethods.find(p => p.value === value), [])
    const salePaymentMethodSelectOption = getPaymentMethodOption(paymentMethod)

    // Effect para efetuar o toggle de visibilidade do box de informações de método de pagamento
    useEffect(() => {
        const paymentBoxes = Array.from(document.querySelectorAll<HTMLElement>('[data-payment-method]'))
        paymentBoxes.forEach((box) => {
            const {paymentMethod: boxPaymentMethod} = box.dataset
            if (boxPaymentMethod === paymentMethod) {
                box.classList.remove('d-none')
            } else {
                box.classList.add('d-none')
            }
        })

        dispatchGtagEvent('cash_purchase_payment',
          {
            action: 'selected_new_payment_option'
          }
        )
    }, [paymentMethod])

    // Atualizando o label que fica no topo da box de informação de pagamento
    useEffect(() => {
        const paymentMethodTitle = document.querySelector('.payment-info-box__method-title')
        if (!paymentMethodTitle) return
        paymentMethodTitle.innerHTML = getPaymentMethodOption(paymentMethod)?.label
    }, [paymentMethod])

    // Atualizando um input hidden com o método de pagamento, caso exista
    useEffect(() => {
        const field = document.getElementById('sale_payment_method') as HTMLInputElement | null
        if (!field) return
        field.value = paymentMethod
    }, [paymentMethod])

    let select;
    if (showPaymentMethodSelect) {
        select = <Select
            styles={selectStyle}
            className="vehicle-payment-method-select"
            isSearchable={false}
            defaultValue={salePaymentMethodSelectOption}
            onChange={(val) => setPaymentMethod(val.value)}
            options={paymentMethods}/>
    }

    const tooltipTarget = useRef(null)
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <div className="vehicle-payment__info">
            <div className="vehicle-payment__vehicle-info">
                <img className="vehicle-payment__vehicle-image" src={product.thumb} alt={product.name_and_version}/>
                <div>
                    <h2 className="vehicle-payment__vehicle-name">{product.name}</h2>
                    <h6 className="vehicle-payment__vehicle-features">
                        {product.version} {product.fuel} {product?.doors && product.doors + 'P'} {product.gear}
                    </h6>
                    {
                        showPaymentMethodSelect &&
                        <div data-testid="payment-methods-select-box" className="d-none d-md-block">
                            {select}
                        </div>
                    }
                </div>
            </div>
            <div className="vehicle-payment__payment-info">
                <span ref={tooltipTarget} className="vehicle-payment__payment-info-title"
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseOut={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}>
                    <i className="icon icon-info-circle-solid"/> Valor do veículo
                </span>
                <Overlay target={tooltipTarget.current} show={showTooltip} placement="top-start">
                    <Tooltip id='tooltip-info-vehicle'>
                        Este valor já inclui a compensação do valor pago pela reserva, mas não inclui frete, IPVA e
                        outros custos extras.
                    </Tooltip>
                </Overlay>
                <span className="vehicle-payment__full-price">
                    {product.price}
                </span>
                {product.has_discount &&
                <span className="vehicle-payment__discount">
                    -{product.discount_value}
                </span>}
                {
                    signalValue &&
                    <span className="vehicle-payment__discount">
                        -{signalValue}
                    </span>
                }
                <span className="vehicle-payment__final-price">
                    {product.final_price}
                </span>
                {
                    showPaymentMethodSelect &&
                    <div data-testid="payment-methods-select-box" className="d-block d-md-none">
                        {select}
                    </div>
                }
            </div>
        </div>
    )
}

export interface VehiclePaymentInfoProps {
    product: {
        thumb: string
        name_and_version: string
        name: string
        version: string
        fuel: string
        doors: string
        gear: string
        price: string
        discount_value: string
        has_discount: boolean
        final_price: string
    }
    signalValue: string
    salePaymentMethod: string
    paymentMethods: PaymentMethod[],
    showPaymentMethodSelect: boolean
}

const selectStyle = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'var(--primary)',
        border: 'none',
        "&:hover": {
          ...provided.hover,
          cursor: 'pointer'
        }
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: 'white',
        "&:hover": {
            ...provided.hover,
            color: 'white'
        },
        transition: 'transform .3s ease-in-out',
        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : ''
    }),
    option: (provided, {isFocused, isSelected}) => {
        const isFocusedOrSelected = isFocused || isSelected;

        return {
            ...provided,
            backgroundColor: isFocusedOrSelected ? 'var(--primary)' : provided.backgroundColor,
            color: isFocusedOrSelected ? 'white' : provided.color,
        }
    },
    indicatorSeparator: () => ({
        display: 'none'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'white',
        margin: 0
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '12px 16px'
    })
}