import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ProgressBar } from 'autocommerce-sb'
import { useHistory } from 'react-router-dom'

import { Container, BackButton } from './styled'

const Header = ({ step, primary, totalSteps }) => {
  const history = useHistory()

  const handleBackClick = () => {
    if (step == 0) {
      window.location.href = `${window.location.origin}/compramos-seu-usado`
    } else {
      history.goBack()
    }
  }

  const [done, setDone] = useState(1)
  useEffect(() => {
    setDone((step / totalSteps) * 100)
  }, [step, totalSteps])

  const [isSmallWindow, setIsSmallWindow] = useState(false)
  useEffect(() => {
    setIsSmallWindow(window.innerWidth < 460)
  }, [window.innerWidth])

  const [showCompansationDiv, setShowCompansationDiv] = useState(true)
  useEffect(() => {
    setShowCompansationDiv(window.innerWidth > 1180)
  }, [window.innerWidth])

  if (step >= 0) {
    return (
      <div>
        <Container>
          <BackButton onClick={handleBackClick}>
            <i className="icon icon-arrow-left"></i>
          </BackButton>
          <ProgressBar
            barSize={isSmallWindow ? 238 : 360}
            done={done}
            color={primary}
          />
          {showCompansationDiv && <div style={{ width: '42px' }}></div>}
        </Container>
        <hr />
      </div>
    )
  } else {
    return <></>
  }
}

const mapStateToProps = (state) => ({
  step: state.avaliationFlow.step,
  primary: state.avaliationFlow.primary,
  totalSteps: state.avaliationFlow.totalSteps,
})

export default connect(mapStateToProps)(Header)
