import { mileageFormat } from '../utils/wNumb'
import noUiSlider from 'nouislider'
import ReactDOM, { render } from 'react-dom'
import Select from 'react-select'
import store from '../store'
import { setOpenModalWhatsapp } from '../store/ducks/modal-whatsapp'
import validationFactory from '../utils/validation'
import { convertRaw } from '../service/api'
import { applyMoneyMask, applyPhoneMask } from '../utils/applyMasks'
import equalHeights from '../utils/equalHeights'
import { dispatchGtagEvent } from './analytics/utils'
import React from 'react'
import VehicleLocationFilter from '../components/VehicleLocationFilter'
import { ModalVehicle } from '../components/ModalVehicle'
import initLazyLoading from '../utils/lazyLoading'

function handleVehicleTypeCheckboxesClick(form) {
  const motoCheckbox = document.getElementById('vehicle_type_moto')
  const carCheckbox = document.getElementById('vehicle_type_car')

  const uncheckAndSubmit = (checkbox) => {
    setTimeout(() => form.submit(), 0)
    checkbox.checked = false
  }

  motoCheckbox &&
    motoCheckbox.addEventListener('change', () => uncheckAndSubmit(carCheckbox))
  carCheckbox &&
    carCheckbox.addEventListener('change', () => uncheckAndSubmit(motoCheckbox))
}

function displayYearSlider() {
  const currentYear = new Date().getFullYear()
  const minYear = currentYear - 30
  const maxYear = currentYear + 1

  const yearSlider = document.getElementById('year_filter_slider')
  const minYearInput = document.querySelector<HTMLInputElement>('#min_year')
  const maxYearInput = document.querySelector<HTMLInputElement>('#max_year')
  const minYearLabel = document.getElementById('min_year_label')
  const maxYearLabel = document.getElementById('max_year_label')

  const start = [minYearInput.value, maxYearInput.value]

  noUiSlider
    .create(yearSlider, {
      start: start.map((v) => parseInt(v)),
      connect: true,
      range: {
        min: minYear,
        max: maxYear,
      },
      step: 1,
      format: {
        from: (value) => parseInt(value),
        to: (value) => parseInt(value),
      },
    })
    .on('update', (values, handle) => {
      const [min, max] = values

      minYearInput.value = min
      maxYearInput.value = max

      minYearLabel.innerHTML = min
      maxYearLabel.innerHTML = max
    })

  return yearSlider
}

function displayMileageSlider() {
  const mileageSlider = document.getElementById('used-filter-slider')

  const minMileageEl = document.querySelector<HTMLInputElement>('#min_mileage')
  const maxMileageEl = document.querySelector<HTMLInputElement>('#max_mileage')
  const minMileageLabel = document.getElementById('min_mileage_label')
  const maxMileageLabel = document.getElementById('max_mileage_label')

  const start = [minMileageEl.value, maxMileageEl.value]

  const minMileage = 0
  const maxMileage = 1000 * 1000

  noUiSlider
    .create(mileageSlider, {
      start: start.map((v) => mileageFormat.from(v)),
      connect: true,
      range: {
        min: minMileage,
        max: maxMileage,
      },
      margin: 2000,
      step: 100,
      format: mileageFormat,
    })
    .on('update', (values, handle) => {
      const [min, max] = values

      minMileageEl.value = min
      minMileageLabel.innerHTML = min

      maxMileageEl.value = max
      maxMileageLabel.innerHTML = max
    })

  return mileageSlider
}

function handleCollapsableOptionsClick() {
  const collapses = Array.from(
    document.querySelectorAll('.advanced-search-filters__collapse')
  )

  collapses.map((el) => {
    el.addEventListener('click', () => {
      el.classList.toggle('advanced-search-filters__collapse--active')

      const content = el.nextElementSibling
      content.classList.toggle('d-none')
    })
  })
}

function handleSeeAllBrandsClick() {
  const seeAllBrandsButton = document.getElementById('see-all-brands')
  // Caso haja até 4 marcas, o botão não é renderizado. A próxima linha evita qualquer trabalho relacionado à ele.
  if (!seeAllBrandsButton) return
  const brandsContainer = document.querySelector(
    '.advanced-search-filters__brands-container'
  )

  seeAllBrandsButton.addEventListener('click', () => {
    brandsContainer.classList.toggle(
      'advanced-search-filters__brands-container--collapsed'
    )
  })
}

function submitFormOnFooterSearchClick(form) {
  const footerSearchLink = document.getElementById('search_btn_fixed')

  footerSearchLink.addEventListener('click', () => form.submit())
}

function renderOrderingSelect(searchForm) {
  const orderingSelect = document.getElementById('advanced-search-ordering')
  const orderingSelectData = document.querySelector(
    '[data-dom-id="advanced-search-ordering"]'
  )
  const {
    name,
    options,
    className,
    placeholder,
    defaultValue,
    primaryColor,
    secondaryColor,
  } = JSON.parse(orderingSelectData.innerHTML)

  ReactDOM.render(
    <Select
      className={className}
      classNamePrefix="form__control-select"
      name={name}
      options={options}
      placeholder={placeholder}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: secondaryColor,
          primary: primaryColor,
        },
      })}
      styles={{
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            color: isSelected ? 'white' : isFocused ? 'white' : secondaryColor,
          }
        },
      }}
      defaultValue={options.find((option) => option.value === defaultValue)}
      onChange={({ value }) => {
        setTimeout(() => {
          document.querySelector<HTMLInputElement>(
            'input[name="ordering"]'
          ).value = value
          searchForm.submit()
        }, 500)
      }}
    />,
    orderingSelect
  )
}

function openWhatsappModalOnClick() {
  const button = document.querySelector(
    '.advanced-search-results__btn-whatsapp'
  )
  button?.addEventListener('click', () =>
    store.dispatch(setOpenModalWhatsapp(true))
  )
}

function convertLeadOnConversionSubmission() {
  const formSelector = '#advanced-search__conversion-form'
  const form = document.querySelector<HTMLFormElement>(formSelector)
  if (!form) return

  validationFactory(formSelector)

  applyPhoneMask(form)

  const getSelectedContactMethods = () =>
    [
      ['#customer_contact_for_email', 'email'],
      ['#customer_contact_for_phone', 'phone'],
      ['#customer_contact_for_whatsapp', 'whatsapp'],
    ].reduce(
      (acc, [selector, value]) =>
        (document.querySelector<HTMLInputElement>(selector).checked &&
          acc.concat(value)) ||
        acc,
      []
    )

  form.addEventListener('bouncerFormValid', function () {
    const conversionData = new FormData(form)
    dispatchGtagEvent('clicked_send_solicitation', {
      chosen_contact_forms: getSelectedContactMethods().join(','),
    })
    convertRaw(conversionData)
      .then((response) => {
        form.classList.add('d-none')
        location.href = '#success'
      })
      .catch((err) => {
        console.error(`Error sending conversion: ${err}`)
      })
  })
}

function xsDeviceListeners(searchForm) {
  const filtersContainer = document.querySelector('.advanced-search-filters')
  const orderingContainer = document.querySelector('.advanced-search-ordering')
  const mobileViewOptions = Array.from(
    document.querySelectorAll('.advanced-search__nav-filter-option')
  )
  const deactivateViewOptionButtons = () => {
    mobileViewOptions.map((el) =>
      el.classList.remove('advanced-search__nav-filter-option--active')
    )
  }
  const showFiltersSection = () => {
    deactivateViewOptionButtons()

    const navOptionsContainer = document.querySelector(
      '.advanced-search__nav-options'
    )
    navOptionsContainer.classList.add('advanced-search__nav-options--fixed')

    orderingContainer.classList.add('d-none')
    filtersContainer.classList.remove('d-none')
  }

  // Handle redo search click
  ;(() => {
    const redoSearchButton = document.querySelector(
      '.advanced-search-results__btn-redo'
    )

    redoSearchButton.addEventListener('click', () => {
      showFiltersSection()
      document
        .getElementById('filterMobile')
        .classList.toggle('advanced-search__nav-filter-option--active')
    })
  })()

  // Handle filters click
  ;(() => {
    const mobileFilterButton = document.getElementById('filterMobile')

    mobileFilterButton.addEventListener('click', () => {
      showFiltersSection()

      mobileFilterButton.classList.toggle(
        'advanced-search__nav-filter-option--active'
      )
    })
  })()

  // Handle sort click
  ;(() => {
    const mobileOrderingButton = document.getElementById('orderingMobile')

    mobileOrderingButton.addEventListener('click', () => {
      deactivateViewOptionButtons()
      mobileOrderingButton.classList.toggle(
        'advanced-search__nav-filter-option--active'
      )
      const navOptionsContainer = document.querySelector(
        '.advanced-search__nav-options'
      )
      navOptionsContainer.classList.add('advanced-search__nav-options--fixed')
      filtersContainer.classList.add('d-none')
      orderingContainer.classList.remove('d-none')
    })
  })()

  const showResultsSection = () => {
    deactivateViewOptionButtons()

    const navOptionsContainer = document.querySelector(
      '.advanced-search__nav-options'
    )
    navOptionsContainer.classList.remove('advanced-search__nav-options--fixed')

    orderingContainer.classList.add('d-none')
    filtersContainer.classList.add('d-none')
  }

  // Handle results click
  ;(() => {
    const resultsButton = document.getElementById('resultsMobile')
    resultsButton.addEventListener('click', () => {
      resultsButton.classList.toggle(
        'advanced-search__nav-filter-option--active'
      )
      showResultsSection()
    })
  })()

  // Handle close filters click
  ;(() => {
    const closeButtons = Array.from(
      document.querySelectorAll('.advanced-search__close-filters-btn')
    )

    closeButtons.map((button) => {
      button.addEventListener('click', () => {
        showResultsSection()
      })
    })
  })()

  // Handle sort buttons click
  ;(() => {
    const buttons = Array.from<HTMLButtonElement>(
      document.querySelectorAll('.advanced-search-ordering__btn')
    )
    const orderingSelect = document.getElementsByName(
      'ordering'
    )[0] as HTMLInputElement

    buttons.map((button) => {
      button.addEventListener('click', () => {
        orderingSelect.value = button.dataset.value
        setTimeout(() => searchForm.submit(), 0)
      })
    })
  })()
}

const renderLocationFilters = () => {
  const container = document.querySelector<HTMLDivElement>('#location-filter')
  const options = JSON.parse(container.dataset?.options)
  const urlState = container.dataset?.url_state

  if (!container) return

  const url = new URLSearchParams(window.location.search)
  const [state, city] = [url.get('state'), url.get('city')]

  render(
    <VehicleLocationFilter
      options={options}
      selectedState={state}
      filteredByUrlState={!!urlState}
      selectedCity={city}
    />,
    container
  )
}

const toggleBoxShadowOnAdvancedSearchButton = () => {
  const buttonContainer = document.querySelector<HTMLDivElement>(
    '.advanced-search-filters__submit-container'
  )
  const boxShadowValue = buttonContainer.style.boxShadow

  window.addEventListener('scroll', () => {
    const viewHeight = window.visualViewport.height

    buttonContainer.getBoundingClientRect().bottom < viewHeight
      ? (buttonContainer.style.boxShadow = 'none')
      : (buttonContainer.style.boxShadow = boxShadowValue)
  })
}

function renderModalVehicle() {
  const elModalVehicle = document.querySelector('#modal-vehicle')

  if (
    !elModalVehicle ||
    // @ts-ignore
    typeof window.hasModalVehicle == 'undefined' ||
    // @ts-ignore
    !window.hasModalVehicle
  ) {
    return
  }

  ReactDOM.render(<ModalVehicle />, elModalVehicle)
}

;(() => {
  if (!document.querySelector('.advanced-search')) return

  const searchForm = document.querySelector('.advanced-search__form')

  handleVehicleTypeCheckboxesClick(searchForm)

  // Criando range sliders
  displayYearSlider()
  displayMileageSlider()

  handleCollapsableOptionsClick()
  handleSeeAllBrandsClick()
  submitFormOnFooterSearchClick(searchForm)
  renderOrderingSelect(searchForm)
  openWhatsappModalOnClick()
  convertLeadOnConversionSubmission()
  xsDeviceListeners(searchForm)
  renderLocationFilters()
  renderModalVehicle()

  equalHeights()

  toggleBoxShadowOnAdvancedSearchButton()

  applyMoneyMask(document.querySelector('.advanced-search-filters__price'))
  initLazyLoading()
})()
