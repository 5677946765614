// Actions
const OPEN = 'modal-purchase-term/OPEN'

// Actions Creators
export function setOpenModalPurchaseTerm(open) {
  return { type: OPEN, open }
}

const initial_state = {
  open: false,
}

// Reducer
export default function reducer(state = initial_state, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        open: action.open,
      }

    default:
      return state
  }
}
