import React from 'react'
import StoreCard from './storeCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { SwiperOptions } from 'swiper/types'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { SwiperButton } from '../SwiperSlideButtons'

interface Breakpoint {
  [width: number]: SwiperOptions
  [ratio: string]: SwiperOptions
}

const StoresCarousel = ({ elements }) => {
  const breakpoint: Breakpoint = {
    0: {
      slidesPerView: 'auto',
      spaceBetween: 16,
    },
    560.5: {
      slidesPerView: 'auto',
      spaceBetween: 24,
    },
    745: {
      slidesPerView: elements.length < 3 ? elements.length : 'auto',
      spaceBetween: 24,
    },
    1093: {
      slidesPerView: elements.length < 3 ? elements.length : 'auto',
      spaceBetween: 32,
    },
  }

  return (
    <div className="stores-carousel-container">
      <SwiperButton id="store-prev-button">
        <ChevronLeft strokeWidth={2} />
      </SwiperButton>
      <Swiper
        breakpoints={breakpoint}
        centerInsufficientSlides={true}
        className="swiper-container"
        grabCursor={true}
        modules={[Navigation]}
        navigation={{
          disabledClass: 'swiper-button--disabled',
          nextEl: '#store-next-button',
          prevEl: '#store-prev-button',
        }}
        preventClicks={true}
      >
        {elements.map((card, index) => {
          return (
            <SwiperSlide key={index} className="swiper-slide-container">
              <StoreCard {...card} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <SwiperButton id="store-next-button">
        <ChevronRight strokeWidth={2} />
      </SwiperButton>
    </div>
  )
}

export default StoresCarousel
