import React from 'react'

import { Card, Label, Info, IconContainer, Icon } from './styled'

const SelectedCarCard = ({ model, plate }) => {
  return (
    <Card>
      <div className="d-flex flex-column">
        <Label>{plate ? 'Veículo encontrado:' : 'Veículo selecionado:'}</Label>
        <Info>
          <b>{model}</b>
          {plate && ` - Placa ${plate}`}
        </Info>
      </div>
      <IconContainer>
        <Icon className="icon icon-check-simple" />
      </IconContainer>
    </Card>
  )
}

export default SelectedCarCard
