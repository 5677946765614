export const emailPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  message: 'Informe um e-mail válido',
}

export const creditCardPattern = [
  {
    strValue: '\\d{4}\\s\\d{4}\\s\\d{4}\\s\\d{4}',
  },
  {
    strValue: '\\d{4}\\s\\d{6}\\s\\d{5}',
  },
]

export const creditCardValidatePattern = {
  strValue: '[0-1]\\d\\/\\d{2}',
}

export const cpfPattern = {
  strValue: '\\d{3}\\.?\\d{3}\\.\\d{3}-?\\d{2}',
}

export const zipCodePattern = {
  strValue: '\\d{5}-\\d{3}',
}
