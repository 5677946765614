import React from 'react'
import { render } from 'react-dom'
import { applyPlateMask, applyKmMask } from '../utils/applyMasks'
import { kmMask } from '../utils/masks'
import IMask from 'imask'
import validationFactory from '../utils/validation'
import AutoAvaliarPricing from '../components/AutoAvaliarPricing'
;(() => {
  const elSalesContainer = document.querySelector('.identify-your-vehicle')
  if (!elSalesContainer) return

  const validator = validationFactory('#form-identify-your-vehicle')

  ;(() => {
    const vehiclePricingContainer = document.getElementById(
      'buy-used-vehicle-pricing'
    )
    if (!vehiclePricingContainer) return

    const domData = document.querySelector(
      '[data-dom-id="buy-used-vehicle-pricing"]'
    )
    const props = JSON.parse(domData.innerHTML)

    document
      .querySelector('.aa-princing-loading')
      .classList.remove('aa-princing-loading')

    render(<AutoAvaliarPricing {...props} />, vehiclePricingContainer)
  })()

  /**
   * Validação do form
   */
  function handleValidationForm() {
    const elForm = document.querySelector('#form-identify-your-vehicle')

    elForm.addEventListener('submit', (e) => {
      const formIsValid = validator.validateAll(elForm).length === 0

      if (!formIsValid) {
        e.preventDefault()
        return
      }

      // TODO: remover código abaixo após corrigir envio de valores com ponto(.) no formato R$ 1.000,00
      const elInputBalanceDue = document.getElementById(
        'used_vehicle_balance_due'
      )
      const elInputKm = document.getElementById('used_vehicle_km')

      if (elInputBalanceDue) {
        elInputBalanceDue.value = elInputBalanceDue.value.replace(/\./, '')
      }

      if (elInputKm) {
        elForm.elements['used_vehicle[km]'].value = elInputKm.value.replace(
          /[^\d]/g,
          ''
        )
      }
      // END TODO

      elForm.submit()
    })
  }

  handleValidationForm()

  applyPlateMask(elSalesContainer)
  applyKmMask(elSalesContainer)
})()
