import React from 'react'
import { render } from 'react-dom'
import { confirmVisit } from '../service/api'
import validationFactory from '../utils/validation'

const elContainer = document.querySelector('.visit-schecule')

if (elContainer) {
  ;(async () => {
    let { parseISO, formatISO, getHours } = await import('date-fns')
    const { default: VisitSchedule } = await import(
      '../components/VisitSchedule'
    )
    const { default: ConfirmationPopup } = await import(
      '../components/VisitSchedule/ConfirmationPopup'
    )

    const elDate = document.getElementById('visit_schedule_date')
    const validator = validationFactory('.visit-schedule__form')

    /**
     * Renderiza componente de calendário
     */
    function renderCalendar() {
      const el = document.querySelector('.visit-schedule__calendar')
      const submitButton = document.getElementById('submit-schedule-button')
      const { expirationDate, sameDayPurchase } = el.dataset

      const date = parseISO(expirationDate)

      const handleChange = (date) => {
        elDate.value = formatISO(date)
      }

      render(
        <VisitSchedule
          name="date"
          expirationDate={date}
          sameDayPurchase={sameDayPurchase}
          onChange={handleChange}
        />,
        el
      )

      if (submitButton) {
        submitButton.disabled = false
      }
    }

    function handleSubmitForm() {
      const elForm = document.querySelector('.visit-schedule__form'),
        elFormConfirm = document.querySelector(
          '.visit-schedule__form--has-confirm'
        )
      elForm.addEventListener('submit', (e) => {
        const formIsValid = validator.validateAll(elForm).length === 0

        elForm.classList.add('was-validated')

        const date = parseISO(elDate.value)
        const hours = getHours(date)

        const formIsvalid = hours > 0 && formIsValid

        if (!formIsvalid) {
          e.preventDefault()
          return
        }

        if (elFormConfirm) {
          confirmVisit(elDate.value)
            .then((response) => {
              render(
                <ConfirmationPopup
                  dealer={response.dealer.name}
                  onConfirmReservationClick={() =>
                    (window.location.href = response.next)
                  }
                />,
                document.createElement('div')
              )
            })
            .catch() //TODO: é necessário algum tratamento aqui?
          return
        }

        elForm.submit()
      })
    }

    renderCalendar()

    handleSubmitForm()
  })()
}
