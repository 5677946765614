import styled from 'styled-components'
import { rem } from 'polished'

export const AvaliationFlowContainer = styled.div`
  min-height: 71vh;
  background-color: #fff;
`

export const ContainerBase = styled.div`
  width: ${rem(488)};
  margin: 0 auto;
  @media (max-width: ${rem(600)}) {
    width: ${rem(312)};
  }
`

export const TitleBase = styled.h1`
  font-family: Roboto;
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  @media (max-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
`
