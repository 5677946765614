import React, { ReactNode } from 'react'

interface SwiperButtonProps {
  children: ReactNode
  className?: string
  id: string
}

export const SwiperButton = ({
  children,
  className,
  id,
}: SwiperButtonProps) => {
  return (
    <a href="#" className={`swiper-button ${className}`} id={id}>
      <>{children}</>
    </a>
  )
}
