import React from 'react'

export const ErrorCTAs = (props) => {
  return (
    <div className="">
      <div className="sales__financing-quick-solution">
        Você também pode utilizar uma das soluções rápidas abaixo.
      </div>
      <div className="sales__ctas sales__ctas--credit-analysis">
        <a
          href={props.continue_cta}
          className="sales__btn sales__btn--next sales__btn--primary-color"
        >
          Garantir Oferta
        </a>
        {
          props.whatsapp_store_cta &&
          <a
            href={props.whatsapp_store_cta}
            className="sales__btn sales__btn--basis-50 sales__btn--primary-color-bordered"
          >
            Falar com a concessionária
        </a>
        }
        <a
          href={props.new_simulation_cta}
          className="sales__btn sales__btn--next"
        >
          Tentar Novamente
        </a>
      </div>
    </div >

  )
}

export const CTAs = (props) => {
  return (
    <div className="sales__ctas">
      <a
        href={props.back_cta}
        className="sales__btn sales__btn--back"
      >
        Voltar
        </a>

      <a
        href={props.continue_cta}
        className="sales__btn sales__btn--next"
      >
        Continuar
          <i className="icon icon-chevron-right-solid"></i>
      </a>
    </div>
  )
}