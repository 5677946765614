import React, { useState } from 'react'
import { setCookie } from '../../utils/cookie'
import BottomSheet from '../BottomSheet'
import { Option, SearchInput } from './styled'

export const BottomSheetSelectState = ({
  options = [],
  isOpen = false,
  currentState = null,
  onClose = () => null,
  currentUrlStatePath = null,
}) => {
  const [search, setSearch] = useState('')

  const handleSearch = (event) => {
    setSearch(event.target.value.toUpperCase())
  }

  const filteredOptions = options.filter((option) =>
    option.label.toUpperCase().includes(search)
  )

  // start - este código só é necessário enquanto as rotas do fluxo de avaliação
  // não suportam o parâmetro de estado
  const handleSelect = (selectedState) => {
    const must_set_cookie = window.location.pathname.includes(
      'compramos-seu-usado'
    )

    if (!must_set_cookie) return

    setCookie({
      name: 'last_url_state',
      value: selectedState,
      expirationDays: 7,
    })
  }
  // end

  // Esta verificação também só é necessária enquanto as rotas do
  // fluxo de avaliação não suportam o parâmetro de estado
  const formatedPath = (value) =>
    window.location.pathname.includes('compramos-seu-usado')
      ? window.location.pathname
      : currentUrlStatePath.replace('url_state_param', value)

  return (
    <BottomSheet
      title="Qual o seu estado?"
      titleColor="var(--primary)"
      isOpen={isOpen}
      onClose={onClose}
    >
      {options.length > 5 && (
        <SearchInput type="text" placeholder="Buscar" onChange={handleSearch} />
      )}

      {filteredOptions.map((option, index) => (
        <Option
          key={index}
          href={formatedPath(option.value)}
          selected={option.value === currentState}
          onClick={() => handleSelect(option.value)}
        >
          {option.label}
        </Option>
      ))}
    </BottomSheet>
  )
}
