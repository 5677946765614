export function setCookie({ name, value, expirationDays }) {
  const date = new Date()
  const milisecondsPerDay = 24 * 60 * 60 * 1000

  date.setTime(date.getTime() + expirationDays * milisecondsPerDay)

  const expires = 'expires=' + date.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

export function getCookie(name) {
  const cookie = {}

  document.cookie.split(';').forEach(function (element) {
    const [key, value] = element.split('=')
    cookie[key.trim()] = value
  })

  return cookie[name]
}
