const OCCUPATION_CHANGED = 'financing-request/OCCUPATION_CHANGED'

export function occupationChanged(occupation) {
  return { type: OCCUPATION_CHANGED, value: occupation }
}

export default function reducer(state = { }, action = {}) {
  switch (action.type) {
    case OCCUPATION_CHANGED:
      return {
        ...state,
        occupation: action.value
      }

    default:
      return state
  }
}
