import { ready } from '../utils/misc'
;(() => {
  const container = document.querySelector('.sales--order-review')
  if (!container) return

  configureFloatingCardToggle()

  function configureFloatingCardToggle() {
    const toggler = document.querySelector('.car-info-float__header')
    const card = document.querySelector('#card-info') as HTMLElement
    const cardActiveClass = 'car-info-float__card--active'
    const overlay = document.querySelector('.card-info-float__overlay')
    const overlayActiveClass = 'card-info-float__overlay--active'
    const hideBtn = document.querySelector('.car-info-float__card-close')

    function activateCard() {
      card.classList.add(cardActiveClass)
      overlay.classList.add(overlayActiveClass)
    }

    function deactivateCard() {
      card.classList.remove(cardActiveClass)
      overlay.classList.remove(overlayActiveClass)
    }

    overlay.addEventListener('click', deactivateCard)
    toggler.addEventListener('click', activateCard)
    hideBtn.addEventListener('click', deactivateCard)

    // Evita que a transição aconteça ao carregar a página
    ready(() => {
      setTimeout(() => {
        overlay.classList.remove('no-transition')
        card.classList.remove('no-transition')
      }, 500)
    })
  }
})()
