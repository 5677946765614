import React from 'react'
import PropTypes from 'prop-types'

import Icon from './Icon'

import { Modal, Header, Title, Overlay } from './styled'

const BottomSheet = ({
  isOpen,
  title,
  titleColor,
  className,
  onClose,
  children,
}) => {
  return (
    <>
      {isOpen && <Overlay onClick={onClose} />}
      <Modal className={className} isOpen={isOpen}>
        <Header>
          <Title titleColor={titleColor}>{title}</Title>
          <Icon onClick={onClose} />
        </Header>
        {children}
      </Modal>
    </>
  )
}

BottomSheet.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  secondDescription: PropTypes.string,
  titleColor: PropTypes.string.isRequired,
}

BottomSheet.defaultProps = {
  isOpen: false,
  className: '',
  children: '',
  title: '',
  titleColor: '#000',
  onClose: () => null,
}

export default BottomSheet
