import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import store from '../store'
import TermsModal from '../components/TermsModal'
import {
  setPrivacyPolicyModalDisplay,
  setTermsOfUseDisplay,
} from '../store/ducks/term-modals'

function renderUseTermsModal(acceptConditionsCheckbox) {
  const modal = document.getElementById('signup-use-terms-modal')
  const { html } = modal.dataset

  const dispatchModalDisplay = (show) => {
    store.dispatch(setTermsOfUseDisplay(show))
  }

  function handleAcceptClick(e) {
    e.preventDefault()

    acceptConditionsCheckbox.checked = true
    dispatchModalDisplay(false)
  }

  function handleRejectClick(e) {
    e.preventDefault()

    acceptConditionsCheckbox.checked = false
    dispatchModalDisplay(false)
  }

  const elSiteUseTerms = document.getElementById('site-use-terms')

  if (elSiteUseTerms) {
    elSiteUseTerms.addEventListener('click', () => {
      dispatchModalDisplay(true)
    })
  }

  render(
    <Provider store={store}>
      <TermsModal
        html={html}
        selector={(state) => state.termModals.signup?.termsOfUse}
        handleHide={() => dispatchModalDisplay(false)}
        onAcceptClick={handleAcceptClick}
        onRejectClick={handleRejectClick}
      />
    </Provider>,
    modal
  )
}

// Há bastante semelhança na renderização dos Modais
// Acredito que futuramente poderiamos remover as duplicações.
// Mantendo por enquanto antecipando algum desalinhamento futuro entre eles
function renderPrivacyPolicyModal(acceptConditionsCheckbox) {
  const modal = document.getElementById('signup-privacy-policy-modal')
  const { html } = modal.dataset

  const dispatchModalDisplay = (show) => {
    store.dispatch(setPrivacyPolicyModalDisplay(show))
  }

  function handleAcceptClick(e) {
    e.preventDefault()

    acceptConditionsCheckbox.checked = true
    dispatchModalDisplay(false)
  }

  function handleRejectClick(e) {
    e.preventDefault()

    acceptConditionsCheckbox.checked = false
    dispatchModalDisplay(false)
  }

  const elPrivacyPolicy = document.getElementById('site-privacy-policy')

  if (elPrivacyPolicy) {
    elPrivacyPolicy.addEventListener('click', () => {
      dispatchModalDisplay(true)
    })
  }

  render(
    <Provider store={store}>
      <TermsModal
        html={html}
        selector={(state) => state.termModals.signup?.privacyPolicy}
        handleHide={() => dispatchModalDisplay(false)}
        onAcceptClick={handleAcceptClick}
        onRejectClick={handleRejectClick}
      />
    </Provider>,
    modal
  )
}

export { renderPrivacyPolicyModal, renderUseTermsModal }
