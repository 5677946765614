import { dispatchGtagEvent } from '../../utils'

export const configureHomeOfferCarouselsAnalytics = () => {
  const selectors = [
    '.section-carousel-offers-featured', // 0km
    '.section-carousel-offers-other' // seminovos
  ]

  // Utilizamos o atributo `aria-label` para identificar se os botões se
  // controle do carrossel clicados.
  const arrowLabels = ['Previous slide', 'Next slide']
  const [prevButtonLabel, nextButtonLabel] = arrowLabels

  const isCarouselArrow = (target) => arrowLabels.includes(target?.ariaLabel)

  const eventName = 'carousel_interaction'

  selectors.forEach((selector) => {
    const container = document.querySelector(selector)
    if (!container) return
    container.addEventListener('click', ({ target, currentTarget }) => {
      if (!target) return
      // @ts-ignore
      const { ariaLabel } = target
      // Indica o tipo de oferta para o carrossel
      // @ts-ignore
      const carouselOfferType = currentTarget?.dataset?.carouselCategory

      // Caso sejam setas de controle
      if (isCarouselArrow(target)) {
        const eventPayload = {
          offer_types: carouselOfferType,
          action: 'click',
          label: null
        }

        switch (ariaLabel) {
          case prevButtonLabel:
            eventPayload.label = 'prev_arrow'
            break
          case nextButtonLabel:
            eventPayload.label = 'next_arrow'
            break
        }

        dispatchGtagEvent(eventName, eventPayload)
      }
    })
  })
}