import styled from 'styled-components'
import { rem } from 'polished'

export const Container = styled.div`
  width: ${rem(1180)};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${rem(32)};
  @media (max-width: ${rem(1180)}) and (min-width: ${rem(460)}) {
    width: ${rem(456)};
  }
  @media (max-width: ${rem(460)}) {
    width: ${rem(312)};
    margin-top: ${rem(15)};
  }
`

export const BackButton = styled.div`
  width: ${rem(42)};
  height: ${rem(42)};
  background-color: var(--gray-3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
