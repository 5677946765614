import React from 'react'
import PropTypes from 'prop-types'
import Item from './Item'

const SocialLinks = ({ children, className = '' }) => {
  return (
    <ul className={`social-links ${className}`}>
      { children }
    </ul>
  )
}

SocialLinks.defaultProps = {
  items: [],
}

SocialLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string,
    })
  )
}

SocialLinks.Item = Item

export default SocialLinks
