import React from 'react'
import { render } from 'react-dom'
import Tip from '../components/Tip'
import {
  applyCreditCardNumberMask,
  applyCreditCardValidateMask,
  applyCpfMask,
  applyPositiveNumberMask,
} from '../utils/applyMasks'
import {
  applyCreditNumberPattern,
  applyCreditCardValidatePattern,
  applyCpfPattern,
} from '../utils/applyPatterns'
import validationFactory from '../utils/validation'
import {
  renderModalPurchaseTerm,
  handlePaymentAcceptTerms,
  renderModalReserveTerm,
  handleReserveAcceptTerms,
} from './payments/shared'
;(() => {
  // TODO: Este seletor futuramente pode ser modificado para um específico para reserva à vista,
  // caso seja necessário
  const paymentContainerEl = document.querySelector('.payment--reserve')
  if (!paymentContainerEl) return

  const validator = validationFactory('.payment__form')

  let emailAlreadyReSent = false
  const reSendEmailButton = document.querySelector('#resend-email-button')
  const reSendEmailLink =
    reSendEmailButton && reSendEmailButton.querySelector('a')

  function reSendEmail() {
    if (emailAlreadyReSent) {
      return
    }

    emailAlreadyReSent = true
    reSendEmailButton.innerHTML =
      'Reenviado <i class="icon icon-check-solid sales-step__icon-check"></i>'
  }

  reSendEmailButton &&
    reSendEmailButton.addEventListener('click', (event) => {
      event.preventDefault()
      reSendEmailLink.click()
    })

  reSendEmailLink &&
    reSendEmailLink.addEventListener('click', (event) => {
      event.preventDefault()
      reSendEmail()
    })

  function renderTipSignal() {
    const elTip = document.getElementById('payment__tip-signal')
    const elTipDesktop = document.getElementById('payment__tip-signal-desktop')

    const Content = (
      <div>
        O <strong>sinal de reserva</strong> é um pagamento inicial que você fará
        para assegurar a reserva. Após a confirmação do pagamento, o veículo
        será imediatamente retirado do nosso site e ficará reservado em seu
        nome.
      </div>
    )

    if (elTip) {
      render(
        <Tip content={Content}>
          <div className="payment__exclamation d-xl-none">!</div>
        </Tip>,
        elTip
      )
    }

    if (elTipDesktop) {
      render(
        <Tip content={Content}>
          <div className="payment__help-text d-none d-xl-flex">
            <i className="icon icon-warning payment__help-icon" />
          </div>
        </Tip>,
        elTipDesktop
      )
    }
  }

  function renderTipCVV() {
    const elTip = document.getElementById('payment__tip-cvv')
    const Content = (
      <div>
        O número CVV é representado pelos três últimos dígitos no verso do seu
        cartão. Em cartões American Express, o CVV é um número de 4 dígitos na
        frente do cartão.
      </div>
    )

    render(
      <Tip content={Content}>
        <div className="payment__help-text payment__help-text--cvv">
          <i className="icon icon-question-circle-regular" />
        </div>
      </Tip>,
      elTip
    )
  }

  const elImageCreditCardNumber = document.querySelector(
    '.payment__credit-card-draw-digit'
  )
  const elImageCreditCardName = document.querySelector(
    '.payment__credit-card-draw-item-value--name'
  )
  const elImageCreditCardValid = document.querySelector(
    '.payment__credit-card-draw-item-value--valid'
  )
  const elImageCreditCardCVV = document.querySelector(
    '.payment__credit-card-draw-item-value--cvv'
  )
  const elInputCreditCardNumber = document.getElementById(
    'operation_credit_card_number'
  )
  const elInputCreditCardName = document.getElementById(
    'operation_credit_card_name'
  )
  const elInputCreditCardExpirationDate = document.getElementById(
    'operation_credit_card_expiration_date'
  )
  const elInputCreditCardCVV = document.getElementById(
    'operation_credit_card_cvv'
  )

  /**
   * Renderiza número na imagem do cartão
   *
   * @param {String} value
   */
  function renderOnImageCreditCardNumber(value) {
    if (value) {
      elImageCreditCardNumber.innerHTML = value.replace(
        /\s/g,
        '&nbsp;&nbsp;&nbsp;'
      )
    } else {
      elImageCreditCardNumber.innerHTML =
        '****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;****'
    }
  }

  function renderOnImageCreditCardName(value) {
    if (value) {
      elImageCreditCardName.innerHTML = value.toUpperCase()
    } else {
      elImageCreditCardName.innerHTML = 'AAAA AAAAA'
    }
  }

  /**
   * Renderiza validade na imagem do cartão
   *
   * @param {String} value
   */
  function renderOnImageCreditCardValid(value) {
    if (value) {
      elImageCreditCardValid.innerHTML = value
    } else {
      elImageCreditCardValid.innerHTML = 'MM/AA'
    }
  }

  function renderOnImageCreditCardCVV(value) {
    if (value) {
      elImageCreditCardCVV.innerHTML = value
    } else {
      elImageCreditCardCVV.innerHTML = '000'
    }
  }

  /**
   * Altera informações na "foto" do cartão
   */
  function handleCreditCard() {
    elInputCreditCardNumber.addEventListener('keyup', (e) => {
      const { value } = e.target
      renderOnImageCreditCardNumber(value)
    })

    elInputCreditCardName.addEventListener('keyup', (e) => {
      const { value } = e.target
      renderOnImageCreditCardName(value)
    })

    elInputCreditCardExpirationDate.addEventListener('keyup', (e) => {
      const { value } = e.target
      renderOnImageCreditCardValid(value)
    })

    elInputCreditCardCVV.addEventListener('keyup', (e) => {
      const { value } = e.target
      renderOnImageCreditCardCVV(value)
    })
  }

  /**
   * @type {HTMLFormElement}
   */
  const elForm = document.querySelector('.payment__form')

  const isFormValid = () => {
    const formIsValid = validator.validateAll(elForm).length === 0

    if (formIsValid) elForm.classList.add('was-validated')

    return formIsValid
  }

  function generateCreditCardToken() {
    const elCreditCardToken = document.getElementById('operation_token')

    /**
     * @param {CreatePaymentTokenResponse} response
     */
    const onRequestPaymentTokenCreation = (response) => {
      elCreditCardToken.value = response.id

      if (response.errors) {
      } else {
        isFormValid() && elForm.submit()
      }
    }

    /**
     * @var {Iugu} Iugu
     */
    function configureTokenRequest() {
      const iuguAccountId = document.getElementById(
        'auto_gateway_account_id'
      ).value

      Iugu.setAccountID(iuguAccountId)

      if (JSON.parse(document.getElementById('presentation_mode').value)) {
        Iugu.setTestMode(true)
      }
    }

    elForm.addEventListener('submit', (event) => {
      event.preventDefault()

      configureTokenRequest()

      Iugu.createPaymentToken(elForm, onRequestPaymentTokenCreation)
    })
  }

  function submitAndContinue() {
    const btn = document.querySelector('#submit-continue')
    if (btn) {
      const input = document.querySelector('[name="submit-continue"]')
      btn.addEventListener('click', () => {
        input.value = 'true'
      })
    }
  }

  submitAndContinue()
  renderTipSignal()
  renderTipCVV()
  renderModalPurchaseTerm()
  renderModalReserveTerm()
  renderOnImageCreditCardNumber(elInputCreditCardNumber.value)
  renderOnImageCreditCardName(elInputCreditCardName.value)
  renderOnImageCreditCardValid(elInputCreditCardExpirationDate.value)
  renderOnImageCreditCardCVV(elInputCreditCardCVV.value)

  handlePaymentAcceptTerms()
  handleReserveAcceptTerms()
  handleCreditCard()

  applyCreditCardNumberMask(paymentContainerEl)
  applyCreditCardValidateMask(paymentContainerEl)
  applyCpfMask(paymentContainerEl)
  applyPositiveNumberMask(paymentContainerEl)

  applyCreditNumberPattern(paymentContainerEl)
  applyCreditCardValidatePattern(paymentContainerEl)
  applyCpfPattern(paymentContainerEl)

  generateCreditCardToken()
})()
