import SelectState from './pages/SelectState'
import AboutYou from './pages/AboutYou'
import FindVehicle from './pages/FindVehicle'
import SelectModel from './pages/FindVehicle/pages/SelectModel'
import SelectYear from './pages/FindVehicle/pages/SelectYear'
import FindMaker from './pages/FindVehicle/pages/FindMaker'
import SelectKilometer from './pages/SelectKilometer'
import SelectPlace from './pages/SelectPlace'
import SelectTime from './pages/SelectTime'
import SelectVersion from './pages/SelectVersion'
import AvaliationResult from './pages/AvaliationResult'
import FinishedSchedule from './pages/AvaliationResult/FinishedSchedule'
import CustomQuestions from './pages/CustomQuestions'

export default [
  { path: '/compramos-seu-usado/estado-de-inspecao', comp: SelectState },
  { path: '/compramos-seu-usado/identifique-seu-veiculo', comp: FindVehicle },
  { path: '/compramos-seu-usado/ano-do-veiculo', comp: SelectYear },
  { path: '/compramos-seu-usado/marca-do-veiculo', comp: FindMaker },
  { path: '/compramos-seu-usado/modelo-do-veiculo', comp: SelectModel },
  { path: '/compramos-seu-usado/versao-do-veiculo', comp: SelectVersion },
  {
    path: '/compramos-seu-usado/quilometragem-do-veiculo',
    comp: SelectKilometer,
  },
  {
    path: '/compramos-seu-usado/informacoes-do-veiculo',
    comp: CustomQuestions,
  },
  { path: '/compramos-seu-usado/local-de-inspecao', comp: SelectPlace },
  { path: '/compramos-seu-usado/dados-pessoais', comp: AboutYou },
  {
    path: '/compramos-seu-usado/resultado-da-avaliacao',
    comp: AvaliationResult,
  },
  { path: '/compramos-seu-usado/agendar-inspecao', comp: SelectTime },
  { path: '/compramos-seu-usado/avaliacao-confirmada', comp: FinishedSchedule },
]
