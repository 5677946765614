import styled from 'styled-components'
import { rem } from 'polished'

export const Card = styled.div`
  width: 100%;
  background-color: var(--gray-3);
  border-radius: ${rem(8)};
  padding: ${rem(16)} ${rem(24)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(24)};
  @media (max-width: ${rem(600)}) {
    padding: ${rem(16)};
  }
`

export const Label = styled.span`
  font-family: Roboto;
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(21)};
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray-5);
  @media (max-width: ${rem(600)}) {
    font-size: ${rem(12)};
  }
`

export const Info = styled.span`
  font-family: Roboto;
  font-size: ${rem(16)};
  font-weight: 400;
  line-height: ${rem(24)};
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: ${rem(600)}) {
    font-size: ${rem(14)};
  }
`

export const IconContainer = styled.div`
  background-color: #00c390;
  height: ${rem(32)};
  width: ${rem(32)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.i`
  color: #fafafa;
  font-size: ${rem(20)};
`
