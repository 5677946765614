import styled from 'styled-components'
import { ContainerBase } from '../../styled'
import { rem } from 'polished'

export const CardContainer = styled.div`
  margin: 0 auto ${rem(16)} auto;
  width: ${rem(488)};
  @media (max-width: ${rem(600)}) {
    width: ${rem(312)};
  }
`

export const Container = styled(ContainerBase)``

export const Title = styled.h1`
  font-family: Roboto;
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  margin: ${rem(48)} auto;
  @media (max-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
`
export const WarningContainer = styled.div`
  margin: 0 auto;
  margin-top: ${rem(50)};
  @media (max-width: ${rem(600)}) {
    margin-top: ${rem(16)};
  }
`

export const WarningText = styled.span`
  font-family: Roboto;
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(21)};
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray-5);
`

export const StyledIcon = styled.i`
  font-size: ${rem(22)};
  color: var(--primary);
  margin-right: ${rem(18)};
`
