import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Button, Card } from 'autocommerce-sb'
import { useHistory } from 'react-router-dom'

import useSmallWindow from '../../hooks/useSmallWindow'
import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
  clearAvaliationFlowForm,
} from '../../../../store/ducks/avaliation-flow'
import {
  Container,
  Title,
  StyledIcon,
  WarningText,
  StyledTextInput,
  SubTitle,
} from './styled'
import { goTop } from '../../actions'
import { fetchCustomQuestions } from './actions'
import { fetchPlaces } from '../SelectPlace/action'

const CustomQuestions = ({ formValues, customQuestions }) => {
  const dispatch = useDispatch()
  const smallWindow = useSmallWindow(600)
  const history = useHistory()
  const formRef = useRef(null)
  const [answers, setAnswers] = useState({})

  useEffect(() => {
    goTop
    dispatch(setAvaliationFlowStep(4))
  }, [])

  const [error, setError] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (
      !customQuestions.every(
        (customQuestion) => customQuestion.value in answers
      )
    ) {
      setError('Todas as perguntas são obrigatórias!')
      return
    }

    dispatch(
      setAvaliationFlowFormValues(
        Object.entries(answers).map((answerArr) => ({
          label: answerArr[0],
          value: answerArr[1],
        })),
        'custom_fields'
      )
    )

    if (formValues.city_name && formValues.state_inspection) {
      ;(async () => {
        const stores = await fetchPlaces(
          formValues.state_inspection,
          formValues.city_name
        )
        if (stores.length === 1) {
          dispatch(setAvaliationFlowFormValues(stores[0].store_id, 'store_id'))
          dispatch(
            setAvaliationFlowFormValues(stores[0].formated_address, 'address')
          )
          history.push({
            pathname: '/compramos-seu-usado/dados-pessoais',
            search: `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`,
          })
        } else {
          history.push({
            pathname: '/compramos-seu-usado/local-de-inspecao',
            search: `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`,
          })
        }
      })()
    } else {
      history.push({
        pathname: '/compramos-seu-usado/estado-de-inspecao',
        search: '',
      })
    }
  }

  return (
    <Container>
      <form onSubmit={handleSubmit} ref={formRef}>
        {customQuestions.map((customQuestion) => (
          <div className="d-flex flex-column align-items-center">
            <SubTitle>{customQuestion.label}</SubTitle>
            <div
              className="d-flex justify-content-center flex-wrap"
              style={{ width: '100%', marginBottom: '48px', gap: '16px' }}
            >
              {customQuestion.options.length > 0 ? (
                <>
                  {customQuestion.options.map((e, i) => (
                    <Card
                      key={i}
                      title={e.label}
                      width={smallWindow ? 148 : 176}
                      padding="20px 0"
                      selected={answers[customQuestion.value] === e.value}
                      onClick={() => {
                        setAnswers((answr) => ({
                          ...answr,
                          [customQuestion.value]: e.value,
                        }))
                        setError('')
                      }}
                      centerContent
                    />
                  ))}
                </>
              ) : (
                <>
                  <Card
                    title="Sim"
                    width={smallWindow ? 148 : 176}
                    padding="20px 0"
                    selected={!!answers[customQuestion.value]}
                    onClick={() => {
                      setAnswers((answr) => ({
                        ...answr,
                        [customQuestion.value]: true,
                      }))
                      setError('')
                    }}
                    centerContent
                  />
                  <Card
                    title="Não"
                    width={smallWindow ? 148 : 176}
                    padding="20px 0"
                    selected={
                      !answers[customQuestion.value] &&
                      customQuestion.value in answers
                    }
                    onClick={() => {
                      setAnswers((answr) => ({
                        ...answr,
                        [customQuestion.value]: false,
                      }))
                      setError('')
                    }}
                    centerContent
                  />
                </>
              )}
            </div>
          </div>
        ))}

        {!!error && (
          <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
        )}
        <Button
          label="Continuar"
          type="submit"
          width={smallWindow ? 312 : 488}
        />
      </form>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ margin: '50px auto 48px auto' }}
      >
        <StyledIcon className="icon icon-info-grid"></StyledIcon>
        <WarningText>
          Não se preocupe, usamos esses dados exclusivamente para precificar seu
          veículo
        </WarningText>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
  allowShielded: state.avaliationFlow.allowShielded,
  customQuestions: state.avaliationFlow.customQuestions,
})

export default connect(mapStateToProps)(CustomQuestions)
