import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import Modal from 'react-bootstrap/Modal'
import InputPhone from '../InputPhone'
import CustomCheck from '../CustomCheck'
import Spinner from 'react-bootstrap/Spinner'
import { emailPattern } from '../../utils/pattern'

const ModalFormActiveBestPrice = ({
  show,
  isSubmiting,
  linkPrivacyPolicy,
  linkTermsOfUse,
  onHide,
  onSubmit,
}) => {
  const { handleSubmit, register, errors, setError, clearError, getValues } =
    useForm()

  const oneOptionChecked = () => {
    const { contact_for_email, contact_for_phone, contact_for_whatsapp } =
      getValues()

    if (!contact_for_email && !contact_for_phone && !contact_for_whatsapp) {
      setError(
        'oneOptionChecked',
        'required',
        'Selecione pelo menos uma das opções de contato'
      )
      return false
    }

    clearError('oneOptionChecked')
    return true
  }

  useEffect(() => {
    Object.keys(errors).length && oneOptionChecked()
  }, [errors])

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      centered
      className="modal-form-active-best-price"
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header
          closeButton
          className="modal-form-active-best-price__modal-header"
        >
          <Modal.Title className="modal-form-active-best-price__modal-title">
            Ative o melhor preço
            <p className="modal-form-active-best-price__desc">
              Preencha seus dados para liberar
              <br /> um desconto exclusivo
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-form-active-best-price__modal-body">
          <div className="form-group">
            <label className="control-label modal-form-active-best-price__control-label">
              Nome
            </label>
            <input
              ref={register()}
              type="hidden"
              value="Liberar desconto"
              name="form_origin"
            />
            <input
              ref={register({ required: 'Informe seu nome' })}
              type="text"
              name="name"
              className={clsx(
                'form-control modal-form-active-best-price__form-control',
                {
                  'is-invalid': errors.name,
                }
              )}
              placeholder="Seu nome"
              maxLength="100"
            />
            <div className="invalid-feedback">
              {errors.name && errors.name.message}
            </div>
          </div>

          <div className="form-group">
            <label className="control-label modal-form-active-best-price__control-label">
              E-mail
            </label>
            <input
              ref={register({
                required: 'Informe seu e-mail',
                pattern: emailPattern,
              })}
              type="email"
              name="email"
              className={clsx(
                'form-control modal-form-active-best-price__form-control',
                {
                  'is-invalid': errors.email,
                }
              )}
              placeholder="E-mail:"
              maxLength="100"
            />
            <div className="invalid-feedback">
              {errors.email && errors.email.message}
            </div>
          </div>

          <div className="form-group">
            <label className="control-label modal-form-active-best-price__control-label">
              Celular
            </label>
            <InputPhone
              ref={(node) =>
                register(node, { required: 'Informe seu celular ou WhatsApp' })
              }
              type="text"
              name="phone"
              className={clsx(
                'form-control modal-form-active-best-price__form-control',
                {
                  'is-invalid': errors.phone,
                }
              )}
              placeholder="Seu celular"
            />
            <div className="invalid-feedback">
              {errors.phone && errors.phone.message}
            </div>
          </div>

          <div className="modal-form-active-best-price__title-checks text-left">
            Quero receber contato por:
          </div>

          <div className="form-group modal-form-active-best-price__checks d-flex justify-content-between flex-wrap">
            <label className="control-label modal-form-active-best-price__label-check">
              <CustomCheck
                ref={register({ validate: oneOptionChecked })}
                className="custom-check--inverse custom-check__checkmark--small"
                type="checkbox"
                onClick={oneOptionChecked}
                data-group="contact"
                name="contact_for_email"
                defaultChecked={true}
              />
              E-mail
            </label>

            <label className="control-label modal-form-active-best-price__label-check">
              <CustomCheck
                ref={register({ validate: oneOptionChecked })}
                className="custom-check--inverse custom-check__checkmark--small"
                type="checkbox"
                onClick={oneOptionChecked}
                data-group="contact"
                name="contact_for_phone"
                defaultChecked={true}
              />
              Celular
            </label>

            <label className="control-label modal-form-active-best-price__label-check">
              <CustomCheck
                ref={register({ validate: oneOptionChecked })}
                className="custom-check--inverse custom-check__checkmark--small"
                type="checkbox"
                onClick={oneOptionChecked}
                data-group="contact"
                name="contact_for_whatsapp"
                defaultChecked={true}
              />
              WhatsApp
            </label>

            <div className="form__invalid-feedback">
              {errors.oneOptionChecked && errors.oneOptionChecked.message}
            </div>
          </div>

          <label className="control-label modal-form-active-best-price__label-check">
            <CustomCheck
              ref={register({
                required:
                  'Declare que aceita nossa politica de privacidade clicando no campo acima',
              })}
              className="custom-check--inverse custom-check__checkmark--small"
              type="checkbox"
              name="accept_privacy_policy"
            />
            <span className="user-registration__use-terms">
              <span>Declaro que li e aceito a </span>
              <a href={linkPrivacyPolicy}>Política de Privacidade</a> e os{' '}
              <a href={linkTermsOfUse}>Termos de uso do site</a>
            </span>
          </label>
          <div className="form__invalid-feedback">
            {errors.accept_privacy_policy &&
              errors.accept_privacy_policy.message}
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-form-active-best-price__modal-footer">
          <button
            type="submit"
            disabled={isSubmiting}
            className="bnt modal-form-active-best-price__btn-submit"
          >
            {isSubmiting && <Spinner animation="border" size="sm" />}
            <i className="icon icon-lock-open-solid" />
            Liberar Desconto
          </button>

          <hr className="modal-form-active-best-price__hr" />

          <p className="modal-form-active-best-price__text-legal">
            Estamos comprometidos em resguardar suas informações. Conheça nossa{' '}
            <a href={linkPrivacyPolicy}>Política de privacidade</a>.
          </p>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

ModalFormActiveBestPrice.defaultProps = {
  show: false,
  isSubmiting: false,
  onHide() {},
}

ModalFormActiveBestPrice.propTypes = {
  show: PropTypes.bool,
  isSubmiting: PropTypes.bool,
  onHide: PropTypes.func,
}

export default ModalFormActiveBestPrice
