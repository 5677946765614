import React from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'
import validationFactory from '../utils/validation'
import {
  applyCpfMask,
  applyPhoneMask,
  applyMoneyMask,
  applyCepMask,
  applyCnpjMask,
} from '../utils/applyMasks'
import autofillCepData from './autofill-cep-function'
import { handleSelectAddressStateChange } from './autofill-cep-select-state-utils'

const elContainer = document.querySelector('.user-panel--my-data')

if (elContainer) {
  const validator = validationFactory('.user-register__form-my-data')

  /**
   * Abre todos os boxs
   */
  function openAllBox() {
    const els = document.querySelectorAll('.user-panel__box-toggle')

    els.forEach((el) => {
      if (!el.classList.contains('user-panel__box-toggle--open')) {
        el.classList.add('user-panel__box-toggle--open')
      }
    })
  }

  /**
   * Manipuladores de eventos do click no botão toggle do box
   */
  function handleBoxToggleBtnClick() {
    const elsBoxToggle = document.querySelectorAll('.user-panel__box-toggle')

    elsBoxToggle.forEach((elBoxToggle) => {
      const elToggleBtn = elBoxToggle.querySelector(
        '.user-panel__box-toggle-btn'
      )

      elToggleBtn.addEventListener('click', (e) => {
        e.preventDefault()

        const isOpen = elBoxToggle.classList.contains(
          'user-panel__box-toggle--open'
        )

        if (isOpen) {
          elBoxToggle.classList.remove('user-panel__box-toggle--open')
        } else {
          elBoxToggle.classList.add('user-panel__box-toggle--open')
        }
      })
    })
  }

  /**
   * Renderiza select de rg estado
   */
  function renderSelectRgState() {
    const elSelect = document.getElementById(
      'user-panel-my-data-select-rg-state'
    )
    const elDataSelect = document.querySelector(
      '[data-dom-id="user-panel-my-data-select-rg-state"'
    )
    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelect.innerHTML
    )

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
      />,
      elSelect
    )
  }

  /**
   * Renderiza select de gênero
   */
  function renderSelectGenre() {
    const elSelect = document.getElementById('user-panel-my-data-select-genre')
    const elDataSelect = document.querySelector(
      '[data-dom-id="user-panel-my-data-select-genre"'
    )
    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelect.innerHTML
    )

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={options.find((state) => state.value === defaultValue)}
      />,
      elSelect
    )
  }

  /**
   * Renderiza select de estado civil
   */
  function renderSelectMaritalStatus() {
    const elSelect = document.getElementById(
      'user-panel-my-data-select-marital-status'
    )
    const elDataSelect = document.querySelector(
      '[data-dom-id="user-panel-my-data-select-marital-status"'
    )
    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelect.innerHTML
    )

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={options.find((state) => state.value === defaultValue)}
      />,
      elSelect
    )
  }

  /**
   * Renderiza select de estado de origem
   */
  function renderSelectOriginState() {
    const elSelect = document.getElementById(
      'user-panel-my-data-select-origin-state'
    )
    const elDataSelect = document.querySelector(
      '[data-dom-id="user-panel-my-data-select-origin-state"'
    )
    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelect.innerHTML
    )

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={options.find((state) => state.value === defaultValue)}
      />,
      elSelect
    )
  }

  /**
   * Renderiza select de estado
   */
  function renderSelectState() {
    const elSelect = document.getElementById('user-panel-my-data-select-state')
    const { options, defaultValue } = elSelect.dataset
    const optionsJSON = JSON.parse(options)
    optionsJSON.push({ label: 'Selecione uma opção', value: '' })

    ReactDOM.render(
      <Select
        className="form__control-select"
        classNamePrefix="form__control-select"
        name="customer[state]"
        options={optionsJSON}
        placeholder="Selecione uma opção"
        defaultValue={optionsJSON.find(
          (state) => state.value === defaultValue || state.value == ''
        )}
        onChange={handleSelectAddressStateChange}
      />,
      elSelect
    )
  }

  /**
   * Validação do form
   */
  function validationForm() {
    const elForm = document.querySelector('.user-register__form-my-data')

    elForm.addEventListener(
      'submit',
      (e) => {
        const formIsValid = validator.validateAll(elForm).length === 0

        elForm.classList.add('was-validated')

        if (!formIsValid) {
          e.preventDefault()
          openAllBox()
          return
        }

        elForm.submit()
      },
      false
    )
  }

  /**
   * Ao inserir CEP, aplica outros campos de endereço automaticamente
   */

  autofillCepData(elContainer, 'user-panel-my-data-select-state')

  /**
   * Chamadas das funções
   */

  handleBoxToggleBtnClick()

  renderSelectRgState()
  renderSelectGenre()
  renderSelectMaritalStatus()
  renderSelectOriginState()
  renderSelectState()

  applyCpfMask(elContainer)
  applyPhoneMask(elContainer)
  applyMoneyMask(elContainer)
  applyCepMask(elContainer)
  applyCnpjMask(elContainer)

  validationForm()
}
