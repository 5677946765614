import React from 'react'
import PropTypes from 'prop-types'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import isMobile from 'is-mobile'

const Tip = ({
  children,
  content,
}) => {
  return (
    <OverlayTrigger
      trigger={isMobile() ? 'click' : ['hover', 'focus']}
      placement={isMobile() ? 'top' : 'right'}
      overlay={
        <Popover>
          <Popover.Content>
            {content}
          </Popover.Content>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

Tip.propTypes = {
  content: PropTypes.element,
}

export default Tip
