import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'

const TermsModal = ({
  html,
  selector,
  acceptText,
  rejectText,
  handleHide,
  onRejectClick = () => { },
  onAcceptClick = () => { },
}) => {

  /**
   * Passar um seletor de estado que irá indicar se o modal deve ser mostrado ou não
   * exemplo: state => state.modal.active
   * poderia-se mostrar ou ocultar o modal de modo fixo passando um seletor como state => true
  **/
  const show = useSelector(selector)
  const onHide = useCallback(() => handleHide(), [show])

  return (
    <Modal
      className="modal-purchase-term"
      show={show}
      size="xl"
      centered
      onHide={onHide}
    >
      <div dangerouslySetInnerHTML={{ __html: html }}></div>

      <div className="modal-purchase-term__ctas">
        <a
          href=""
          className="sales__btn sales__btn--back"
          onClick={onRejectClick}
        >
          {rejectText}
        </a>

        <a
          href="#"
          className="sales__btn sales__btn--next"
          onClick={onAcceptClick}
        >
          {acceptText}
          <i className="icon icon-chevron-right-solid"></i>
        </a>
      </div>

    </Modal>
  )
}

TermsModal.propTypes = {
  html: PropTypes.string,
  selector: PropTypes.func,
  acceptText: PropTypes.string,
  rejectText: PropTypes.string
}

TermsModal.defaultProps = {
  acceptText: 'Eu concordo',
  rejectText: 'Não concordo'
}

export default TermsModal
