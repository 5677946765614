import {
  creditCardPattern,
  creditCardValidatePattern,
  cpfPattern,
  zipCodePattern,
} from './pattern'

/**
 *
 * @param {HTMLElement} container
 */
export const applyCreditNumberPattern = (container) => {
  const elPatterns = container.querySelectorAll(
    'input[data-pattern="credit-card-number"]'
  )

  const patterns = creditCardPattern.map((item) => item.strValue).join('|')

  elPatterns.forEach((elPattern) => {
    elPattern.setAttribute('pattern', patterns)
  })
}

/**
 *
 * @param {HTMLElement} container
 */
export const applyCreditCardValidatePattern = (container) => {
  const elPatterns = container.querySelectorAll(
    'input[data-pattern="credit-card-validate"]'
  )

  elPatterns.forEach((elPattern) => {
    elPattern.setAttribute('pattern', creditCardValidatePattern.strValue)
  })
}

/**
 *
 * @param {HTMLElement} container
 */
export const applyCpfPattern = (container) => {
  const elPatterns = container.querySelectorAll('input[data-pattern="cpf"]')

  elPatterns.forEach((elPattern) => {
    elPattern.setAttribute('pattern', cpfPattern.strValue)
  })
}

/**
 *
 * @param {HTMLElement} container
 */
export const applyZipCodePattern = (container) => {
  const elPatterns = container.querySelectorAll(
    'input[data-pattern="zip-code"]'
  )

  elPatterns.forEach((elPattern) => {
    elPattern.setAttribute('pattern', zipCodePattern.strValue)
  })
}
