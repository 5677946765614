import { dispatchGtagEvent, getProductData } from '../../utils'

export const configureOrderReviewEvents = () => {
  const isSalesOrderReviewPage = document.querySelector('.sales--order-review')
  if (!isSalesOrderReviewPage) return

  const { productData } = getProductData()

  // Consideramos que ao entrar nessa página o usuário adicionou um produto no carrinho
  dispatchGtagEvent('add_to_cart', {
    currency: productData.currency,
    value: productData.price,
    items: [productData]
  })
}