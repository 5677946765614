import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Button, Accordion } from 'autocommerce-sb'
import { useHistory } from 'react-router-dom'

import { goTop } from '../../actions'
import LoadingPage from './LoadingPage'
import {
  setAvaliationFlowStep,
  setAvaliationFlowSaleInfo,
} from '../../../../store/ducks/avaliation-flow'
import useSmallWindow from '../../hooks/useSmallWindow'
import {
  Container,
  Title,
  Details,
  DetailsHighLight,
  StyledInput,
  CheckBoxLabel,
  CheckBoxLabelLink,
  CheckBoxContainer,
  DoubtsSection,
  DoubtsTitle,
} from './styled'
import {
  validateEmail,
  validateName,
  validatePhone,
  submitData,
  dealerHasPrivacyPolicy,
} from './action'

const AboutYou = ({ formValues }) => {
  const dispatch = useDispatch()
  const isSmallWindow = useSmallWindow(600)
  const history = useHistory()

  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(7))

    dealerHasPrivacyPolicy().then(({ data }) => {
      setHasPrivacyPolicy(data)
    })
  }, [])

  const [submitedAndLoading, setSubmitedAndLoading] = useState(false)
  const [hasPrivacyPolicy, setHasPrivacyPolicy] = useState(false)

  const formRef = useRef(null)

  const [formIsValid, setFormIsValid] = useState(false)
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
  })

  useEffect(() => {
    validate()
  })

  const validate = () => {
    const [name, email, phone] = [
      formRef.current?.elements['name'].value,
      formRef.current?.elements['email'].value,
      formRef.current?.elements['phone'].value,
    ]
    setFormIsValid(
      validateName(name) && validateEmail(email) && validatePhone(phone)
    )
  }

  const onNameFocusOut = () => {
    if (!validateName(formRef.current?.elements['name'].value)) {
      setErrors({ ...errors, name: 'Este campo não pode ser vazio' })
    }
  }
  const onNameFocus = () => {
    setErrors({ ...errors, name: '' })
  }

  const onEmailFocusOut = () => {
    if (!validateEmail(formRef.current?.elements['email'].value)) {
      setErrors({ ...errors, email: 'Insira um email válido' })
    }
  }
  const onEmailFocus = () => {
    setErrors({ ...errors, email: '' })
  }

  const onPhoneFocusOut = () => {
    if (!validatePhone(formRef.current?.elements['phone'].value)) {
      setErrors({ ...errors, phone: 'Insira um telefone válido' })
    }
    validate()
  }
  const onPhoneFocus = () => {
    setErrors({ ...errors, phone: '' })
  }

  const [nameValue, setNameValue] = useState('')
  const onNameChange = (name) => {
    if (
      name.length < 70 &&
      (name.match(/^[a-záàâãéèêíïóôõöúçñ ]+$/i) || name == '')
    ) {
      setNameValue(name)
    }
  }

  const redirect = (url) => {
    window.location.href = `${window.location.origin}/${url}`
  }

  const submit = async () => {
    setSubmitedAndLoading(true)
    const response = await submitData(formValues, [
      formRef.current?.elements['name'].value,
      formRef.current?.elements['email'].value.toLowerCase(),
      formRef.current?.elements['phone'].value,
    ])
    dispatch(setAvaliationFlowSaleInfo(response))
    history.push({
      pathname: '/compramos-seu-usado/resultado-da-avaliacao',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
    setSubmitedAndLoading(false)
  }

  return (
    <>
      <div className={`${submitedAndLoading && 'd-none'}`}>
        <Title>Quase lá! Nos conta um pouco sobre você </Title>
        <Details>
          Assim podemos criar uma proposta exclusiva e tirar dúvidas sobre o
          processo, <DetailsHighLight>sem spam :)</DetailsHighLight>
        </Details>
        <Container>
          <form ref={formRef}>
            <StyledInput
              label="Nome"
              placeholder="Ex: João Campos"
              width={isSmallWindow ? 312 : 384}
              inputConfig={{
                name: 'name',
                onBlur: onNameFocusOut,
                onFocus: onNameFocus,
                value: nameValue,
              }}
              onChange={(name) => {
                onNameChange(name)
                validate()
              }}
              error={errors['name']}
            />
            <StyledInput
              label="Email"
              placeholder="Ex: joao@email.com"
              width={isSmallWindow ? 312 : 384}
              inputConfig={{
                name: 'email',
                onBlur: onEmailFocusOut,
                onFocus: onEmailFocus,
              }}
              onChange={() => validate()}
              error={errors['email']}
            />
            <StyledInput
              label="Telefone"
              placeholder="Ex: (84) 99999-9999"
              width={isSmallWindow ? 312 : 384}
              inputConfig={{
                name: 'phone',
                onBlur: onPhoneFocusOut,
                onFocus: onPhoneFocus,
              }}
              onChange={() => validate()}
              mask={[
                {
                  mask: '(00) 0000-0000',
                },
                {
                  mask: '(00) 00000-0000',
                },
              ]}
              error={errors['phone']}
            />
            <CheckBoxContainer>
              <CheckBoxLabel>
                Ao clicar em continuar você autoriza que façamos contato com
                você e o tratamento dos seus dados para envio de informações
                comerciais personalizadas, conforme das disposições da
                {hasPrivacyPolicy ? (
                  <>
                    &nbsp;
                    <CheckBoxLabelLink
                      onClick={() => redirect('pages/privacy_policy')}
                    >
                      Política de privacidade
                    </CheckBoxLabelLink>{' '}
                    e os &nbsp;
                    <CheckBoxLabelLink
                      onClick={() => redirect('termos-de-uso')}
                    >
                      Termos de uso do site
                    </CheckBoxLabelLink>
                    .
                  </>
                ) : (
                  <>
                    &nbsp;<b>Política de privacidade</b> e os &nbsp;
                    <b>Termos de uso do site</b>.
                  </>
                )}
              </CheckBoxLabel>
            </CheckBoxContainer>
          </form>

          <Button
            label="Continuar"
            disable={!formIsValid}
            onClick={submit}
            width={isSmallWindow ? 312 : 384}
          />
        </Container>
        <DoubtsSection>
          <DoubtsTitle>Principais dúvidas</DoubtsTitle>
          <Accordion
            width={isSmallWindow ? '312px' : '800px'}
            title="Essa é uma empresa segura?"
            description="Totalmente, somos especializados na compra e venda de veículos, para sua maior segurança todos os nossos veículos possuem garantia e passam por uma inspeção rigorosa."
            descriptionHeight={isSmallWindow ? '150px' : '70px'}
            className="about-you_accordion"
          />
          <Accordion
            width={isSmallWindow ? '312px' : '800px'}
            title="Estou fazendo algum compromisso ao avaliar meu veículo?"
            description="Fique tranquilo, você não está fazendo nenhum compromisso. A avaliação online é uma estimativa de quanto podemos oferecer pelo veículo. Você poderá esclarecer todas as suas dúvidas com um de nossos atendentes ou ao realizar uma visita em um de nossos centros de inspeção"
            descriptionHeight={isSmallWindow ? '240px' : '85px'}
            className="about-you_accordion"
          />
        </DoubtsSection>
      </div>
      <div className={`${!submitedAndLoading && 'd-none'}`}>
        <LoadingPage></LoadingPage>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
})

export default connect(mapStateToProps)(AboutYou)
