import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
  clearAvaliationFlowForm,
} from '../../../../store/ducks/avaliation-flow'
import { Container, Title, StyledCard } from './styled'
import { fetchPlaces } from './action'
import useSmallWindow from '../../hooks/useSmallWindow'
import { goTop } from '../../actions'

const SelectPlace = ({ formValues }) => {
  const dispatch = useDispatch()
  const isSmallWindow = useSmallWindow(600)
  const history = useHistory()

  const [storeList, setStoreList] = useState([])
  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(6))
    dispatch(clearAvaliationFlowForm(['store_id']))
    ;(async () => {
      const stores = await fetchPlaces(
        formValues.state_inspection,
        formValues.city_name
      )
      if (stores.length === 1) {
        handleSelect(stores[0])
      }
      setStoreList(stores)
    })()
  }, [])

  const handleSelect = (store) => {
    dispatch(setAvaliationFlowFormValues(store.store_id, 'store_id'))
    dispatch(setAvaliationFlowFormValues(store.formated_address, 'address'))
    history.push({
      pathname: '/compramos-seu-usado/dados-pessoais',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
  }

  return (
    <>
      <Title>
        Selecione o local desejado para realizar sua inspeção presencial
      </Title>
      <Container>
        <div>
          {storeList.map((store) => (
            <StyledCard
              onClick={() => handleSelect(store)}
              title={store.display_name}
              description={store.formated_address}
              padding="20px 28px"
              width={isSmallWindow ? 312 : 488}
              infoStyle={
                isSmallWindow
                  ? { height: '110px', justifyContent: 'center' }
                  : {}
              }
            />
          ))}
        </div>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
})

export default connect(mapStateToProps)(SelectPlace)
