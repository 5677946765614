import { dispatchGtagEvent } from '../../utils'

export const configureFinancingSimulationEvents = () => {
  const isFinancingSimulationPage = document.querySelector('.sale--financing-simulation')
  if (!isFinancingSimulationPage) return
  configureSeeInstallmentDetailsEvents()
}

const configureSeeInstallmentDetailsEvents = () => {
  const pageContainer = document.querySelector('.sale--financing-simulation')
  const btnSelector = '.financing-installments__installment-item-detail'

  // Adicionamos o listener no container da página porque o componente pode não
  // estar renderizado ainda
  pageContainer?.addEventListener('click', (e) => {
    const target = e.target as HTMLElement
    if (!target?.matches(btnSelector)) return

    dispatchGtagEvent('finance_purchase_simulation', {
      action: 'clicked_view_details'
    })
  })
}