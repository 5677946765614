import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {useSelector} from 'react-redux'
import {getZflowProfessions} from '../../service/api'
import {AsyncPaginate} from "react-select-async-paginate";

const ProfessionsSelect = ({name, className, placeholder, currentValue}) => {
    const occupation = useSelector(state => state.financingRequests.occupation)
    const [value, setValue] = useState(currentValue);
    const [initialProfessions, setInitialProfessions] = useState([])
    const hasLoadedInitialProfessions = useMemo(() => initialProfessions.length > 0, [initialProfessions])

    useEffect(() => {
        (async () => {
            if (!occupation) return

            if(!hasLoadedInitialProfessions) {
              const result = await getZflowProfessions(occupation)
              setInitialProfessions(result)
            }

            const preselectedProfession = initialProfessions.find(profession => profession.value === value)
            if (!preselectedProfession) return
            setValue(preselectedProfession)
        })()
    }, [value, occupation, initialProfessions, hasLoadedInitialProfessions])

    const loadProfessionOptions = useCallback(async (search) => {
        return {
            options:
                initialProfessions.length === 0 ?
                    [] :
                    initialProfessions.filter(profession => profession.label.toLowerCase().includes(search.toLowerCase()))
        }

    }, [occupation, initialProfessions])

    return (
        <AsyncPaginate
            className={className}
            classNamePrefix="form__control-select"
            name={name}
            placeholder={placeholder}
            additional={{page: 1}}
            loadOptions={loadProfessionOptions}
            cacheUniqs={[occupation]}
            value={value}
            debounce={350}
            onChange={setValue}
        />

    )
}

export default ProfessionsSelect