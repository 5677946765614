import React from 'react'

import { Overlay } from './styledLoader'

const LoadingPlaceholder = ({ loading }) => {
  return (
    <Overlay loading={loading}>
      <div className="circle"></div>
      <div className="circle delayCircle"></div>
      <bold className="loadingText one">Carregando marcas ...</bold>
      <bold className="loadingText two">Buscando marcas ...</bold>
    </Overlay>
  )
}

export default LoadingPlaceholder
