import validationFactory from '../utils/validation'

const getStateInput = () => document.querySelector('[name="customer[state]"]')
const validator = validationFactory('.financing-requests__form')

function toggleClassErrorSelectAddressState(selectContainerId) {
  const elSelectContainer = document.getElementById(selectContainerId)

  const validate = validator.validate(getStateInput)

  const isValid = validate ? validate.valid : true

  if (isValid) {
    elSelectContainer.classList.remove('form__select-container--is-invalid')
  } else {
    elSelectContainer.classList.add('form__select-container--is-invalid')
  }
}

export const handleSelectAddressStateChange = (
  selectContainerId,
  { value }
) => {
  if (!value) return
  getStateInput().value = value
  toggleClassErrorSelectAddressState(selectContainerId)
}
