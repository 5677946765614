const getLocalItemAndClear = (key) => {
  try {
    const item = localStorage.getItem(key)
    localStorage.removeItem(key)
    return item ? JSON.parse(item) : ''
  } catch {
    return ''
  }
}

// Actions
const STEP = 'avaliation-flow/STEP'
const PRIMARY = 'avaliation-flow/PRIMARY'
const FORMVALUES = 'avaliation-flow/FORMVALUES'
const SCHEDULE = 'avaliation-flow/SCHEDULE'
const SALEINFO = 'avaliation-flow/SALEINFO'
const CLEARFORM = 'avaliation-flow/CLEARFORM'
const SETALLOWSHIELDED = 'avaliation-flow/SETALLOWSHIELDED'
const SETCUSTOMQUESTIONS = 'avaliation-flow/SETCUSTOMQUESTIONS'

// Actions Creators
export function setAvaliationFlowStep(step) {
  return { type: STEP, step }
}

export function setAvaliationFlowPrimary(primary) {
  return { type: PRIMARY, primary }
}
export function setAvaliationFlowFormValues(value, field) {
  return { type: FORMVALUES, value, field }
}

export function setAvaliationFlowSchedule(schedule) {
  return { type: SCHEDULE, schedule }
}

export function setAvaliationFlowSaleInfo(saleInfo) {
  return { type: SALEINFO, saleInfo }
}

export function clearAvaliationFlowForm(fields) {
  return { type: CLEARFORM, fields }
}

export function setAllowShielded(payload) {
  return { type: SETALLOWSHIELDED, payload }
}

export function setCustomQuestions(payload) {
  return { type: SETCUSTOMQUESTIONS, payload }
}

const initial_state = {
  step: 0,
  totalSteps: 10,
  primary: '',
  plateSearchMessage: getLocalItemAndClear('plate_not_found_warning_message'),
  formValues: {
    state_inspection: getLocalItemAndClear('state_inspection'),
    state_inspection_UF: getLocalItemAndClear('state_inspection_UF'),
    city_inspection: getLocalItemAndClear('city_inspection'),
    city_name: getLocalItemAndClear('city_name'),
    plate: getLocalItemAndClear('plate'),
    make_id: getLocalItemAndClear('make_id'),
    model_id: getLocalItemAndClear('model_id'),
    model: getLocalItemAndClear('model'),
    year: getLocalItemAndClear('year'),
    custom_fields: [],
  },
  saleInfo: {},
  scheduleTime: '',
  allowShielded: false,
  customQuestions: [],
}

// Reducer
export default function reducer(state = initial_state, action = {}) {
  switch (action.type) {
    case STEP:
      return {
        ...state,
        step: action.step,
      }
    case PRIMARY:
      return {
        ...state,
        primary: action.primary,
      }
    case FORMVALUES:
      return {
        ...state,
        formValues: { ...state.formValues, [`${action.field}`]: action.value },
      }
    case SCHEDULE:
      return {
        ...state,
        scheduleTime: action.schedule,
      }
    case SALEINFO:
      return {
        ...state,
        saleInfo: action.saleInfo,
      }
    case CLEARFORM:
      const newForm = state.formValues
      action.fields.forEach((field) => delete newForm[field])
      return {
        ...state,
        formValues: newForm,
      }
    case SETALLOWSHIELDED:
      return {
        ...state,
        allowShielded: action.payload,
      }
    case SETCUSTOMQUESTIONS:
      return {
        ...state,
        customQuestions: action.payload,
      }
    default:
      return state
  }
}
