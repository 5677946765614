import $, { Callbacks } from 'jquery'
import isMobile from 'is-mobile'
import { nextDay } from 'date-fns'

;(() => {
  if (!isMobile()) return

  const buyUsed = document.querySelector('.buy-used')
  if (!buyUsed) return

  const div = document.querySelector('.action-buttons')
  // inputs que aceitam texto
  const inputs_default = document
    .querySelector('form, .buy-used__form')
    ?.querySelectorAll('input:not([type="hidden"], [readonly])')
  // inputs readonly (não aceitam texto)
  const inputs_readonly = document
    .querySelector('form, .buy-used__form')
    ?.querySelectorAll('input:not([type="hidden"])[readonly]')

  function fastUnpinButton() {
    $(div)
      .finish()
      .css({ position: 'relative' })
      .removeClass('sticky-botton-on-mobile')
  }

  function unpinButton() {
    let run_animation = false
    let button_position
    const form_field_position = this.getBoundingClientRect()

    if (screen.availHeight > screen.availWidth) {
      $(div)
        .finish()
        .queue(function (next) {
          // passa a posição para relativa
          $(div).css({ position: 'relative' })
          next()
        })
        .queue(function (next) {
          // capturamos a posição do botão em sua posição relativa
          button_position = div.getBoundingClientRect()
          next()
        })
        .queue(function (next) {
          const field_will_be_above_button =
            form_field_position.bottom <=
            window.innerHeight * 0.6 - div.offsetHeight
          const button_will_be_under_keyboard =
            button_position.top >= window.innerHeight * 0.7
          if (field_will_be_above_button && button_will_be_under_keyboard) {
            $(div).css({ position: '' })
            run_animation = true
          } else {
            $(div).removeClass('sticky-botton-on-mobile')
          }
          next()
        })
        .delay('slow')
        .queue(function (next) {
          if (run_animation) {
            $(div).slideToggle('slow', () => {
              $(div).removeClass('sticky-botton-on-mobile').show('fast')
            })
          }
          next()
        })
    } else {
      $(div).finish().removeClass('sticky-botton-on-mobile')
    }
  }

  function pinButton() {
    $(div)
      .finish()
      .delay('fast')
      .queue(() => {
        $(div).css({ position: '' }).addClass('sticky-botton-on-mobile')
      })
  }

  // adicionado eventos para inputs que aceitam texto
  inputs_default?.forEach((input) => {
    input.addEventListener('focus', unpinButton)
    input.addEventListener('blur', pinButton)
  })

  // adicionado eventos para inputs readonly (não aceitam texto)
  inputs_readonly?.forEach((input) => {
    input.addEventListener('focus', fastUnpinButton)
    input.addEventListener('blur', pinButton)
  })
})()
