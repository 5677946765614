import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, IllustratedIcon, Accordion } from 'autocommerce-sb'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { SwiperButton } from '../../../SwiperSlideButtons'
import {
  setAvaliationFlowStep,
  setAvaliationFlowSchedule,
} from '../../../../store/ducks/avaliation-flow'
import {
  getDaysList,
  getScheduleInfo,
  getTimeList,
  submitSchedule,
} from './action'
import {
  Title,
  Description,
  SubTitle,
  TimeContainer,
  TimeCard,
  StyledButton,
  DaysContainer,
  DoubtsSection,
  DoubtsTitle,
} from './styled'
import {
  LeaveModalButton,
  LeaveMouseDesc,
  LeaveMouseModal,
  LeaveMouseOverlay,
  LeaveMouseTitle,
} from '../AvaliationResult/style'
import useSmallWindow from '../../hooks/useSmallWindow'
import { goTop } from '../../actions'
import { ChevronLeft, ChevronRight } from 'lucide-react'

const Calendar = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 20V14.7C40 12.1043 37.8957 10 35.3 10H12.7C10.1043 10 8 12.1043 8 14.7V20M40 20H8M40 20V23.5M8 20V37.3C8 39.8957 10.1043 42 12.7 42H22M16 6V14M32 6V14"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M28 38L32 42L42 32"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const SelectTime = ({ saleInfo, formValues }) => {
  const dispatch = useDispatch()
  const isSmallWindow = useSmallWindow(600)
  const history = useHistory()

  const [buttonLoading, setButtonLoading] = useState(false)
  const [sameDaySchedule, setSameDaySchedule] = useState()
  const [daysList, setDaysList] = useState([])
  const [timeList, setTimeList] = useState([])
  const [selectedDayId, setSelectedDayId] = useState(-1)
  const [selectedTimeId, setSelectedTimeId] = useState(-1)
  const thisDate = new Date()
  const thisHour = thisDate.getHours()
  const [selectedWeekDay, setSelectedWeekDay] = useState(
    window.defaultWeekDay || 1
  )

  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(9))
    setDaysList((window.daysList && window.daysList()) || getDaysList())
    setTimeList(
      ((window.timeList && window.timeList()) || getTimeList(formValues.state_inspection_UF))[selectedWeekDay]
    )
    ;(async () => {
      const scheduleInfo = await getScheduleInfo()
      setSameDaySchedule(scheduleInfo)
    })()
  }, [])

  useEffect(() => {
    setTimeList(
      ((window.timeList && window.timeList()) || getTimeList(formValues.state_inspection_UF))[selectedWeekDay]
    )
  }, [selectedWeekDay])

  const isFirstAttemptToLeave = useRef(true)

  const [leaveMouseModal, setLeaveMouseModal] = useState(false)

  const preventFirstLeave = () => {
    if (isFirstAttemptToLeave.current) {
      setLeaveMouseModal(true)
      isFirstAttemptToLeave.current = false
    }
  }

  const handleSubmit = () => {
    ;(async () => {
      setButtonLoading(true)
      const selectedDay = daysList.find((day) => day.id === selectedDayId)
      const selectedTime = timeList.find((time) => time.id === selectedTimeId)
      try {
        const response = await submitSchedule(
          selectedDay,
          selectedTime,
          saleInfo.sale_id
        )
        dispatch(setAvaliationFlowSchedule(response))
        history.push({
          pathname: '/compramos-seu-usado/avaliacao-confirmada',
          search: formValues.city_inspection
            ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
            : '',
        })
      } catch (e) {
        console.log(e)
      }
      setButtonLoading(false)
    })()
  }

  const handleSelectDay = (day) => {
    setSelectedWeekDay(day.weekDayValue)
    return selectedDayId === day.id
      ? setSelectedDayId(-1)
      : setSelectedDayId(day.id)
  }

  return (
    <>
      <Title>
        Qual o melhor dia e horário para você comparecer ao local de inspeção?
      </Title>
      <DaysContainer>
        <SwiperButton id="day-prev-button">
          <ChevronLeft strokeWidth={2} color={'#000000'} />
        </SwiperButton>
        <Swiper
          centerInsufficientSlide={true}
          className="swiper-container"
          grabCursor={true}
          modules={[Navigation]}
          navigation={{
            disabledClass: 'swiper-button--disabled',
            nextEl: '#day-next-button',
            prevEl: '#day-prev-button',
          }}
          preventClicks={true}
          slidesPerView="auto"
          spaceBetween={16}
          speed={200}
        >
          {daysList.map((day, index) => {
            if (index === 0 && !sameDaySchedule) return
            return (
              <SwiperSlide
                key={index}
                className={`swiper-slide-container avaliation-flow__swiper-slide`}
              >
                <Card
                  title={day.weekDay}
                  description={day.formattedDay}
                  width={120}
                  onClick={() => handleSelectDay(day)}
                  selected={selectedDayId === day.id}
                  padding="10px 24px"
                  centerContent
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <SwiperButton id="day-next-button">
          <ChevronRight strokeWidth={2} color={'#000000'} />
        </SwiperButton>
      </DaysContainer>
      <SubTitle>Selecione um horário</SubTitle>
      <TimeContainer thisHour={thisHour} selectedDayId={selectedDayId}>
        {timeList.map((time, index) => {
          if (thisHour >= time.value && selectedDayId == 0) return
          return (
            <TimeCard
              key={index}
              title={time.label}
              bigRadius
              centerContent
              onClick={() =>
                selectedTimeId === time.id
                  ? setSelectedTimeId(-1)
                  : setSelectedTimeId(time.id)
              }
              width={isSmallWindow ? 84 : 110}
              selected={selectedTimeId === time.id}
              padding="20px 28px"
            />
          )
        })}
      </TimeContainer>
      <StyledButton
        label="Confirmar agendamento"
        disable={selectedDayId < 0 || selectedTimeId < 0}
        loading={buttonLoading}
        width={isSmallWindow ? 312 : 272}
        onClick={handleSubmit}
      />
      <Description>
        É necessário levar seu carro a loja para realizarmos sua inspeção
        gratuitamente
      </Description>

      <DoubtsSection>
        <DoubtsTitle>Principais dúvidas</DoubtsTitle>
        <Accordion
          width={isSmallWindow ? '312px' : '800px'}
          title="Essa é uma empresa segura?"
          description="Totalmente, somos especializados na compra e venda de veículos, para sua maior segurança todos os nossos veículos possuem garantia e passam por uma inspeção rigorosa."
          descriptionHeight={isSmallWindow ? '150px' : '70px'}
          className="about-you_accordion"
        />
        <Accordion
          width={isSmallWindow ? '312px' : '800px'}
          title="Estou fazendo algum compromisso ao avaliar meu veículo?"
          description="Fique tranquilo, você não está fazendo nenhum compromisso. A avaliação online é uma estimativa de quanto podemos oferecer pelo veículo. Você poderá esclarecer todas as suas dúvidas com um de nossos atendentes ou ao realizar uma visita em um de nossos centros de inspeção"
          descriptionHeight={isSmallWindow ? '240px' : '90px'}
          className="about-you_accordion"
        />
        <Accordion
          width={isSmallWindow ? '312px' : '800px'}
          title="Quanto tempo demora a avaliação do veículo e quais documentos devo levar?"
          description="A avaliação dura em média 45 minutos. Deve trazer com você, obrigatoriamente, o CRLV e RG ou CNH do proprietário.
          Caso prossiga para o fechamento do negócio será necessário apresentar a chave reserva, manual (se possuir) e, se possível, o DUT."
          descriptionHeight={isSmallWindow ? '240px' : '90px'}
          className="about-you_accordion"
        />
        <Accordion
          width={isSmallWindow ? '312px' : '800px'}
          title="Como é feito o cálculo do preço do carro exibido no site?"
          description="A oferta inicial é um preço médio e o valor final depende da avaliação presencial do seu veículo, essa estimativa de valor é feita com base nos dados transacionais de mercado e o histórico de venda do seu seminovo."
          descriptionHeight={isSmallWindow ? '240px' : '90px'}
          className="about-you_accordion"
        />
        <Accordion
          width={isSmallWindow ? '312px' : '800px'}
          title="Existe algum custo para a avaliação do meu veículo?"
          description="Fique tranquilo, você não terá custos e nem está fazendo nenhum compromisso. A avaliação online é uma estimativa de quanto podemos oferecer pelo veículo. Você poderá esclarecer todas as suas dúvidas com um de nossos consultores ao realizar uma visita presencial em uma de nossas lojas."
          descriptionHeight={isSmallWindow ? '240px' : '90px'}
          className="about-you_accordion"
        />
      </DoubtsSection>
      <LeaveMouseOverlay onMouseLeave={preventFirstLeave} />
      <LeaveMouseModal leaveMouseModal={leaveMouseModal}>
        <div>
          <svg
            className="close-btn"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setLeaveMouseModal(false)}
          >
            <rect width="32" height="32" rx="16" fill="#F8F8F9" />
            <path
              d="M22 10L10 22"
              stroke="#0B0B0B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10L22 22"
              stroke="#0B0B0B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="icon-container">
            <IllustratedIcon
              Icon={() => <Calendar />}
              color="var(--primary)"
              borderColor={'var(--primaryLightest)'}
            />
          </div>
          <LeaveMouseTitle>Você ainda não agendou sua inspeção</LeaveMouseTitle>
          <LeaveMouseDesc>
            Ao realizar o agendamento você acelera seu processo de venda, é
            simples e direto na plataforma, basta selecionar dia e horário.
          </LeaveMouseDesc>
          <LeaveModalButton
            label="Entendi"
            onClick={() => setLeaveMouseModal(false)}
          />
        </div>
        <div
          className="overlay"
          onClick={() => setLeaveMouseModal(false)}
        ></div>
      </LeaveMouseModal>
    </>
  )
}

const mapStateToProps = (state) => ({
  saleInfo: state.avaliationFlow.saleInfo,
  formValues: state.avaliationFlow.formValues,
})

export default connect(mapStateToProps)(SelectTime)
