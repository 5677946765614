import styled from 'styled-components'
import { rem } from 'polished'
import { Button } from 'autocommerce-sb'

export const InputContainer = styled.div`
  margin: 0 auto 16px auto;
  width: ${rem(384)};
  @media (max-width: 600px) {
    width: ${rem(312)};
  }
  .avaliation-flow__warning-banner {
    margin-top: ${rem(24)};
  }
`

export const WarningContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(24)};
  @media (min-width: 600px) {
    margin-top: ${rem(64)};
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ManualInsertCTA = styled.p`
  text-align: center;
  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};
  color: var(--gray-5);
  @media (max-width: 600px) {
    font-size: ${rem(14)};
  }
  a {
    font-weight: 700;
    color: var(--primary) !important;
    cursor: pointer;
  }
`

export const Title = styled.h1`
  font-family: Roboto;
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  margin: ${rem(48)} auto;
  @media (max-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
`

export const Container = styled.div`
  width: ${rem(592)};
  margin: 0 auto;
  padding-bottom: ${rem(64)};
  @media (max-width: ${rem(600)}) {
    width: ${rem(312)};
    overflow-y: hidden;
  }
`

export const StyledIcon = styled.i`
  font-size: ${rem(22)};
  color: var(--primary);
  margin-right: ${rem(18)};
  display: grid;
  align-items: center;
`

export const WarningText = styled.span`
  font-family: Roboto;
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(21)};
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray-5);
`

export const StyledButton = styled(Button)`
  ${({ loading }) =>
    loading ? 'background-color: var(--primary-black) !important;' : ''}
`
