// Actions
const ERROR_INSTALLMENTS = 'financing-installments/ERROR_INSTALLMENTS'
const ERROR_API = 'financing-installments/ERROR_API'

// Actions creators
export const setErrorInstallments = (isError) => {
  return { type: ERROR_INSTALLMENTS, isError }
}

export const setErrorApi = (isError) => {
  return { type: ERROR_API, isError }
}

const initial_state = {
  errorsInstallments: false,
  errorApi: false,
}

// Reducer
export default function reducer(state = initial_state, action = {}) {
  switch (action.type) {
    case ERROR_INSTALLMENTS:
      return {
        ...state,
        errorsInstallments: action.isError
      }
    
    case ERROR_API:
      return {
        ...state,
        errorApi: action.isError,
      }
    
    default:
      return state
  }
}
