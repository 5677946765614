import React from 'react'
import ReactDOM from 'react-dom'
import CreditAnalysis from '../components/CreditAnalysis'
import CreditAnalysisCTAs from '../components/CreditAnalysis/CreditAnalysisCTAs/index'
import { Provider } from 'react-redux'
import store from '../store'

const elFinancingRequests = document.getElementById('financing-requests__credit-analysis')

if (elFinancingRequests) {

  const props = document.querySelector('[data-dom-id="financing-requests__credit-analysis"]')

  if (props) {
    const parsedProps = JSON.parse(props.innerHTML)

    ReactDOM.render(
      <Provider store={store}>
        <CreditAnalysis {...parsedProps} />
      </Provider>,
      elFinancingRequests
    )
  } 
}

const elCTAs = document.getElementById('financing-requests__credit-analysis-ctas')

if (elCTAs) {
  const props = document.querySelector('[data-dom-id="financing-requests__credit-analysis-ctas"]')

  if (props) {
    const parsedProps = JSON.parse(props.innerHTML)

    ReactDOM.render(
      <Provider store={store}>
        <CreditAnalysisCTAs {...parsedProps} />
      </Provider>,
      elCTAs
    )
  } 
}
