import React from 'react'
import clsx from 'clsx'
import { moneyFormat } from '../../utils/wNumb'
import CustomCheck from '../CustomCheck'

const InstallmentItem = ({
  times,
  value,
  checked = false,
  className = '',
  onChange = () => {},
  onDetailsClick = () => {},
}) => {
  return (
    <div className={clsx(`financing-installments__installment-item ${className}`, {
      'financing-installments__installment-item--active': checked
    })}>
      <CustomCheck
        className="financing-installments__installment-item-checkbox"
        type="checkbox"
        value={times}
        checked={checked}
        onChange={onChange}
      />
      <div className="financing-installments__installment-item-times">{ times }X</div>
      <div className="financing-installments__installment-item-value">{ value }</div>
      <div
        className="financing-installments__installment-item-detail"
        onClick={() => onDetailsClick(times)}
      >
        <i className="icon icon-info-circle-solid" />
        Ver detalhes
      </div>
    </div>
  )
}

export default InstallmentItem
