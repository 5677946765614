import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import AccordionContext from 'react-bootstrap/AccordionContext'
import AccordionUnitsBody from './AccortionUnitsBody'

function ContextAwareToggle({ children, className, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Button
      className={clsx('navbar-header__accordion-toggle', {
        'navbar-header__accordion-toggle--active': isCurrentEventKey,
        [className]: true,
      })}
      variant="link"
      onClick={decoratedOnClick}
    >
      {children}
      {isCurrentEventKey ? (
        <i className="icon icon-chevron-up-solid" />
      ) : (
        <i className="icon icon-chevron-down-solid" />
      )}
    </Button>
  )
}

const AccordionUnits = (props) => {
  const { defaultActiveKey, units, Icon, classNameToggle, onNavItemClick } =
    props

  return (
    <Accordion defaultActiveKey={defaultActiveKey}>
      {units.map((unit, index) => (
        <Card key={unit.name} className="navbar-header__card">
          {units.length > 1 && (
            <Card.Header className="navbar-header__accordion-card-header">
              <ContextAwareToggle
                eventKey={index + 1}
                className={clsx({
                  [classNameToggle]: classNameToggle,
                })}
              >
                {unit.name}
              </ContextAwareToggle>
            </Card.Header>
          )}
          <Accordion.Collapse
            {...(units.length <= 1 ? { in: true } : {})}
            eventKey={index + 1}
          >
            <Card.Body className="navbar-header__card-body">
              {unit.phones.map((phone, index) => (
                <AccordionUnitsBody
                  key={index}
                  phone={phone}
                  onNavItemClick={onNavItemClick}
                  Icon={Icon}
                />
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}

AccordionUnits.defaultProps = {
  items: [],
  defaultActiveKey: 0,
  onNavItemClick: null,
}

AccordionUnits.propTypes = {
  defaultActiveKey: PropTypes.number,
  items: PropTypes.array,
  Icon: PropTypes.node,
  classNameToggle: PropTypes.string,
  onNavItemClick: PropTypes.func,
}

export default AccordionUnits
