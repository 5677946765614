import {
  dispatchGtagEvent,
  ProductData,
  ProductDataExtra,
  getProductData,
} from '../utils'
import { debounce } from 'lodash'

const configureWhatsAppCtasEvents = (
  productData: ProductData,
  productDataExtra: ProductDataExtra
) => {
  ;[
    {
      selector: '#vehicle-show-online-consultation-btn-whatsapp',
      action: 'clicked_cta_negotiate_whatsapp',
    },
    {
      selector: '#vehicle-show-btn-talk-whatsapp',
      action: 'clicked_cta_whatsapp',
    },
  ].forEach(({ selector, action }) => {
    document.querySelector(selector).addEventListener('click', () => {
      dispatchGtagEvent('product_details', {
        action,
        item_brand: productData.item_brand,
        ...productDataExtra,
      })
    })
  })
}

const dispatchViewItemEvent = (productData: ProductData) => {
  // Evento recomendado do Analytics
  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#view_item
  dispatchGtagEvent('view_item', {
    currency: productData.currency,
    items: [productData],
    value: productData.price,
  })
}

const configureGalleryEvents = (
  productData: ProductData,
  productDataExtra: ProductDataExtra
) => {
  const galleryButtonsParams: { element: Element; action: string }[] = [
    {
      element: document.querySelector('.carousel-gallery__prev'),
      action: 'prev_arrow',
    },
    {
      element: document.querySelector('.carousel-gallery__next'),
      action: 'next_arrow',
    },
  ]

  galleryButtonsParams.forEach(({ element, action }) => {
    element?.addEventListener('click', () => {
      dispatchGtagEvent('product_details', {
        action,
        item_brand: productData.item_brand,
        ...productDataExtra,
      })
    })
  })

  const gallery = document.querySelector('.carousel-gallery')
  if (gallery) {
    gallery.addEventListener('click', ({ target }) => {
      const isImg = (target as HTMLElement | null)?.matches('img')
      if (!isImg) return

      dispatchGtagEvent('product_details', {
        action: 'clicked_photo_gallery',
        item_brand: productData.item_brand,
        ...productDataExtra,
      })
    })
  }
}

/**
 * Eventos do input de busca dos itens do veículo
 */
const configureItemSearchEvents = (
  productData: ProductData,
  productDataExtra: ProductDataExtra
) => {
  document.querySelector('.list-items-input-search__input')?.addEventListener(
    'change',
    debounce(
      (e) =>
        dispatchGtagEvent('product_details', {
          action: 'search_items',
          searched_term: e.target.value,
          item_brand: productData.item_brand,
          ...productDataExtra,
        }),
      350
    )
  )
}

export const configureProductDetailsEvents = () => {
  const isProductDetailPage = !!document.querySelector('.vehicle-show')
  if (!isProductDetailPage) return

  const { productData, productDataExtra } = getProductData()

  dispatchViewItemEvent(productData)
  configureGalleryEvents(productData, productDataExtra)
  configureItemSearchEvents(productData, productDataExtra)
  configureWhatsAppCtasEvents(productData, productDataExtra)
}
