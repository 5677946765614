import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ModalContact from '../ModalContact'
import { convert } from '../../service/api'
import { setOpenModalContact } from '../../store/ducks/modal-contact'

const ModalContactApp = () => {
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)

  const dispatch = useDispatch()
  const show = useSelector((state) => state.modalContact.open)

  const handleHide = () => {
    dispatch(setOpenModalContact(false))
  }

  const handleSubmit = async (data) => {
    setIsSubmiting(true)

    const formData = {
      ...data,
      message: 'Contato via Avaliação de veículo',
      unit: data.unit?.label,
      used_vehicle: true,
      plate: data.plate,
    }

    try {
      await convert(formData)
      window.location.href =
        'https://tradein.autoavaliar.com.br/group/grupo-barigui-move/6891zPjMALC?c=6775zPB'
    } catch (e) {
      console.error(e)
      setErrorSubmit(true)
    } finally {
      setIsSubmiting(false)
      handleHide()
    }
  }

  return (
    <ModalContact
      show={show}
      isSubmiting={isSubmiting}
      errorSubmit={errorSubmit}
      onHide={handleHide}
      onSubmit={handleSubmit}
    />
  )
}

export default ModalContactApp
