import React from 'react'

const Loading = () => {
  return (
    <div className="financing-installments__box">
      <div className="financing-installments__loading-icon"></div>

      <h4 className="financing-installments__loading-title">
        Verificando seus dados
      </h4>

      <p className="financing-installments__loading-desc">
      Por favor, aguarde alguns segundos<br className="d-none d-xl-inline" /> enquanto validamos seus dados junto ao banco.
      </p>

    </div>
  )
}

export default Loading
