import IMask from 'imask'
import { phoneMasks } from '../utils/masks'
import { applyYearMask } from '../utils/applyMasks'

import validationFactory from '../utils/validation'

const elContainer = document.querySelector('.buy-used.step-four')
const selectedPhoneField = document.querySelector(
  '.buy-used__form__control[type=phone]'
)

if (elContainer) {
  const validator = validationFactory('.buy-used__form')

  // esse código foi referenciado e adaptado de ./user-register.js

  function validationForm() {
    const elForm = document.querySelector('.buy-used__form')

    elForm.addEventListener(
      'submit',
      (e) => {
        const formIsValid = validator.validateAll(elForm).length === 0

        elForm.classList.add('was-validated')

        if (!formIsValid) {
          e.preventDefault()
          return
        }

        elForm.submit()
      },
      false
    )
  }

  const applyPhoneMaskKantam = (container) => {
    IMask(container, { mask: phoneMasks })
  }

  applyPhoneMaskKantam(selectedPhoneField)
  validationForm()
}

const anotherContainer = document.querySelector('.buy-used_small-section')

if (anotherContainer) {
  const selectedYearField = anotherContainer.querySelector(
    '.form__control[type=year]'
  )
  applyYearMask(anotherContainer)
}
