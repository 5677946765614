import React from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'
import Address from '../components/PaymentAddress/PaymentAddress'
import autofillCepData from './autofill-cep-function'
import { handleSelectAddressStateChange } from './payment-address-cep-select-state'

import { applyAddressNumberMask } from '../utils/applyMasks'
import { applyZipCodePattern } from '../utils/applyPatterns'
import validationFactory from '../utils/validation'
;(() => {
  const elContainer = document.querySelector('.payment-address')
  const validator = validationFactory('.payment-address__box-form')

  if (elContainer) {
    const toggleReceivementAddressFormDisplay = (e) => {
      e.preventDefault()
      addClassBoxWarning()

      const elContainerForm = document.querySelector(
        '.payment-address__box-form'
      )

      if (elContainerForm.classList.contains('d-none'))
        elContainerForm.classList.remove('d-none')
      else elContainerForm.classList.add('d-none')
    }

    /**
     * Renderiza select de estado do form de endereço
     */
    function renderSelectState() {
      const elSelect = document.getElementById(
        'payment-address-select-address-state'
      )
      const { options, defaultValue } = elSelect.dataset
      const optionsJSON = JSON.parse(options)
      optionsJSON.push({ label: 'Selecione uma opção', value: '' })

      ReactDOM.render(
        <Select
          className="form__control-select"
          classNamePrefix="form__control-select"
          name="payment_address[state]"
          options={optionsJSON}
          placeholder="Selecione uma opção"
          defaultValue={optionsJSON.find(
            (state) => state.value === defaultValue || state.value == ''
          )}
          onChange={handleSelectAddressStateChange}
        />,
        elSelect
      )
    }
    /**
     * Ao inserir CEP, aplica outros campos de endereço automaticamente
     */

    autofillCepData(
      elContainer,
      'payment-address-select-address-state',
      'input[name="payment_address[zip_code]"]',
      'input[name="payment_address[city]"]',
      'input[name="payment_address[neighborhood]"]',
      'input[name="payment_address[street]"]',
      'input[name="payment_address[state]"]'
    )

    /**
     * Chamadas das funções
     */

    function hydrateReceivementAddressComponentIfRendered() {
      const receivementAddressContainer =
        document.querySelector('#payment-address')

      if (receivementAddressContainer) {
        const props = JSON.parse(
          document.querySelector('[data-dom-id="payment-address"]').innerHTML
        )
        const saveAddressButton = document.querySelector('#save-address')

        ReactDOM.hydrate(
          <Address
            ref={saveAddressButton}
            customer={props.customer}
            onAddAddressClick={toggleReceivementAddressFormDisplay}
          />,
          receivementAddressContainer
        )
      }
    }

    function clearReatSelector(reactSelector, fieldInvalidMsg) {
      if (
        reactSelector.classList.contains('form__select-container--is-invalid')
      ) {
        reactSelector.classList.remove('form__select-container--is-invalid')
      }
      fieldInvalidMsg.innerText = ''
    }

    /**
     * Validação do formulário
     */
    const elForm = document.querySelector('#payment-address-form')
    const selectState = elForm.querySelector(
      '#payment-address-select-address-state'
    )
    // Valida do seletor de estado
    const isSelectStateValid = () => {
      if (!selectState) return
      // coletando elementos necessárias para validação
      const inputState = selectState.querySelector(
        'input[name="payment_address[state]"]'
      )
      const invalidStateMsg = elForm.querySelector('#invalid-state')
      const stateFormControl = selectState.querySelector(
        '.form__control-select'
      )
      // limpando o seletor
      clearReatSelector(stateFormControl, invalidStateMsg)
      // verificando se seletor não foi preenchido
      if (inputState.value == '') {
        // adicionando estilização de campo não preenchido
        stateFormControl.classList.add('form__select-container--is-invalid')
        invalidStateMsg.innerText = 'Por favor, selecione uma opção'
        return false
      } else {
        stateFormControl.classList.add('form__select-container--is-valid')
        return true
      }
    }

    // valida formulário
    const isFormValid = () => {
      const fieldsValids = validator.validateAll(elForm).length === 0
      const selectorIsValid = isSelectStateValid()
      const formIsValid = fieldsValids && selectorIsValid

      if (formIsValid) {
        elForm.classList.add('was-validated')
      }
      return formIsValid
    }

    elForm.addEventListener('submit', (e) => {
      e.preventDefault()

      isFormValid() && elForm.submit()
    })

    applyZipCodePattern(elForm)
    applyAddressNumberMask(elForm)

    renderSelectState()
    hydrateReceivementAddressComponentIfRendered()
  }
})()
