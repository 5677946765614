import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setOpenModalWhatsapp } from '../../store/ducks/modal-whatsapp'
import ModalWhatsapp from '../ModalWhatsapp'
import { convert } from '../../service/api'

const ModalWhatsappApp = (props) => {
  const { optionsUnits } = props

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)

  const dispatch = useDispatch()
  const show = useSelector((state) => state.modalWhatsapp.open)
  const selectedUnit = useSelector((state) => state.modalWhatsapp.unit)

  const handleHide = () => {
    dispatch(setOpenModalWhatsapp(false))
  }

  const handleSubmit = async (data) => {
    setIsSubmiting(true)

    const formData = {
      ...data,
      unit: data.unit?.label,
      store_id: data.unit?.store_id,
    }

    try {
      await convert(formData)
      const phone = selectedUnit ? selectedUnit.phone : data.unit.value
      const phoneOnlyDigits = phone.replace(/\D/g, '')
      const text = `Olá, estou no site da ${location.origin} e gostaria de mais informações!`

      const link = `https://api.whatsapp.com/send?phone=55${phoneOnlyDigits}&text=${text}`
      window.open(link, '_self')
    } catch (e) {
      console.error(e)
      setErrorSubmit(true)
    } finally {
      setIsSubmiting(false)
    }
  }

  return (
    <ModalWhatsapp
      show={show}
      optionsUnits={optionsUnits}
      isSubmiting={isSubmiting}
      errorSubmit={errorSubmit}
      onHide={handleHide}
      onSubmit={handleSubmit}
    />
  )
}

export default ModalWhatsappApp
