import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: [
      'Roboto:100,200,300,400,500,600,700,800,900',
      'Open Sans',
      'Poppins',
    ],
  },
})
