import React from 'react'
import styled from 'styled-components'
import { ChevronDown, MapPin } from 'react-feather'
import { states } from '../../utils/states'
import isMobile from 'is-mobile'
import { rem } from 'polished'

export const SelectStateHeader = ({ state = null, onClick = () => null }) => {
  const displayName = formatDisplayName(state)

  return (
    <Button onClick={onClick}>
      <MapPin size={14} />
      {displayName}
      <ChevronDown size={14} />
    </Button>
  )
}

const Button = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${rem(8)};
  color: #fff;
  border: 0;
  font-weight: 600;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  padding: ${rem(8)} 0;
`

const formatDisplayName = (state) => {
  if (!state) return 'Selecione'

  const screenWidth = window.innerWidth

  const upperCaseState = state.toUpperCase()

  return isMobile() || screenWidth < 768 // isMobile?
    ? upperCaseState
    : states[upperCaseState]
}
