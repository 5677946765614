import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { emailPattern } from '../../utils/pattern'
import InputPhone from '../InputPhone'
import { useSelector } from 'react-redux'
import CustomCheck from '../CustomCheck'
import axios from 'axios'

const ModalContact = (props) => {
  const { show, isSubmiting, onHide, onSubmit } = props

  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    getValues,
  } = useForm()
  const selectedUnit = useSelector((state) => state.modalWhatsapp.unit)

  const oneOptionChecked = () => {
    const { contact_for_email, contact_for_phone, contact_for_whatsapp } =
      getValues()

    if (!contact_for_email && !contact_for_phone && !contact_for_whatsapp) {
      setError(
        'oneOptionChecked',
        'required',
        'Selecione pelo menos uma das opções de contato'
      )
      return false
    }

    clearError('oneOptionChecked')
    return true
  }

  const [hasPrivacyPolicy, setHasPrivacyPolicy] = useState(false)
  const [hasTerms, setHasTerms] = useState(false)

  const baseURL = window.location.origin
  useEffect(() => {
    Object.keys(errors).length && oneOptionChecked()

    axios
      .get(`${baseURL}/api/v1/dealer/store/has_privacy_policy`)
      .then(({ data }) => setHasPrivacyPolicy(data))

    axios
      .get(`${baseURL}/api/v1/dealer/store/has_terms_of_use_description`)
      .then(({ data }) => setHasTerms(data))
  }, [errors])

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      centered
      className="modal-whatsapp"
    >
      <form className="" onSubmit={handleSubmit(onSubmit)} noValidate>
        <input
          type="hidden"
          name="nitro_trigger"
          value="evaluation__alternative_flow"
          ref={register({})}
        />
        <Modal.Header closeButton>
          <Modal.Title className="modal-whatsapp__title">
            Quero vender meu veículo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-whatsapp__desc">
            Preencha o formulário abaixo e converse agora com um consultor:
          </p>

          <div className="form-group">
            <label className="control-label">Nome</label>
            <input
              ref={register({ required: 'Informe seu nome' })}
              type="text"
              name="name"
              className={clsx('form-control', {
                'is-invalid': errors.name,
              })}
              placeholder="Seu nome"
              maxLength="100"
            />
            <div className="invalid-feedback">
              {errors.name && errors.name.message}
            </div>
          </div>

          <div className="form-group">
            <label className="control-label">E-mail</label>
            <input
              ref={register({
                required: 'Informe seu e-mail',
                pattern: emailPattern,
              })}
              type="email"
              name="email"
              className={clsx('form-control', {
                'is-invalid': errors.email,
              })}
              placeholder="Seu e-mail"
              maxLength="100"
            />
            <div className="invalid-feedback">
              {errors.email && errors.email.message}
            </div>
          </div>

          <div className="form-group">
            <label className="control-label">Celular</label>
            <InputPhone
              ref={(node) =>
                register(node, { required: 'Informe seu celular ou WhatsApp' })
              }
              type="text"
              name="phone"
              className={clsx('form-control', {
                'is-invalid': errors.phone,
              })}
              placeholder="Seu celular"
            />
            <div className="invalid-feedback">
              {errors.phone && errors.phone.message}
            </div>
          </div>

          <div className="form-group">
            <label className="control-label">Placa</label>
            <input
              ref={register({})}
              type="plate"
              name="plate"
              className={clsx('form-control')}
              placeholder="Sua placa"
              maxLength="100"
            />
          </div>

          <div className="modal-form-active-best-price__title-checks text-left">
            Quero receber contato por:
          </div>

          <div className="form-group modal-form-active-best-price__checks d-flex justify-content-between flex-wrap">
            <label className="modal-form-active-best-price__label-check">
              <CustomCheck
                ref={register({ validate: oneOptionChecked })}
                className="custom-check--inverse custom-check__checkmark--small"
                type="checkbox"
                onClick={oneOptionChecked}
                data-group="contact"
                name="contact_for_email"
              />
              E-mail
            </label>

            <label className="modal-form-active-best-price__label-check">
              <CustomCheck
                ref={register({ validate: oneOptionChecked })}
                className="custom-check--inverse custom-check__checkmark--small"
                type="checkbox"
                onClick={oneOptionChecked}
                data-group="contact"
                name="contact_for_phone"
              />
              Celular
            </label>

            <label className="modal-form-active-best-price__label-check">
              <CustomCheck
                ref={register({ validate: oneOptionChecked })}
                className="custom-check--inverse custom-check__checkmark--small"
                type="checkbox"
                onClick={oneOptionChecked}
                data-group="contact"
                name="contact_for_whatsapp"
                defaultChecked={true}
              />
              WhatsApp
            </label>

            <div className="form__invalid-feedback">
              {errors.oneOptionChecked && errors.oneOptionChecked.message}
            </div>
          </div>

          <label className="control-label modal-whatsapp__label-check">
            <span className="modal-whatsapp__label-terms">
              {hasPrivacyPolicy || hasTerms ? (
                <>
                  Ao clicar em Continuar você autoriza que façamos contato com
                  você e o tratamento dos seus dados para envio de informações
                  comerciais personalizadas, conforme das disposições da &nbsp;
                  {hasPrivacyPolicy && (
                    <u>
                      <a href={'/pages/privacy_policy'}>
                        Política de privacidade
                      </a>
                    </u>
                  )}
                  {!hasPrivacyPolicy && (
                    <strong>Política de privacidade</strong>
                  )}{' '}
                  e os &nbsp;
                  {hasTerms && (
                    <u>
                      <a href={'/termos-de-uso'}>Termos de uso do site</a>
                    </u>
                  )}
                  {!hasTerms && <strong>Termos de uso do site</strong>}.
                </>
              ) : (
                <>
                  Ao clicar em Converse Agora! você autoriza que façamos contato
                  com você e o tratamento dos seus dados para envio de
                  informações comerciais personalizadas, conforme das
                  disposições da <b>Política de privacidade</b> e os{' '}
                  <b>Termos de uso do site</b>.
                </>
              )}
            </span>
          </label>

          <div className="form__invalid-feedback">
            {errors.accept_privacy_policy &&
              errors.accept_privacy_policy.message}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="reset"
            disabled={isSubmiting}
            className="btn modal-whatsapp__btn-clear"
          >
            Limpar campos
          </button>
          <button
            type="submit"
            onClick={oneOptionChecked}
            disabled={isSubmiting}
            className="bnt modal-whatsapp__btn-submit"
          >
            {isSubmiting && <Spinner animation="border" size="sm" />}
            Continar
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

ModalContact.defaultProps = {
  onSubmit() {},
}

ModalContact.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ModalContact
