import React from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'
import {
  applyCpfMask,
  applyPhoneMask,
  applyMoneyMask,
  applyCepMask,
  applyCnpjMask,
} from '../utils/applyMasks'
import validationFactory from '../utils/validation'
import store from '../store'
import { occupationChanged } from '../store/ducks/financing-request'
import ProfessionsSelect from '../components/FinancingRequests/ProfessionsSelect'
import { Provider } from 'react-redux'
import IMask from 'imask'
import { dateMask } from '../utils/masks'
import autofillCepData from './autofill-cep-function'
import { handleSelectAddressStateChange } from './autofill-cep-select-state-utils'

const elFinancingRequests = document.querySelector('.financing-requests')

if (elFinancingRequests) {
  const getStateInput = () => document.querySelector('[name="customer[state]"]')

  const validator = validationFactory('.receive-vehicle__form-my-data')

  function renderSelectMaritalStatus() {
    const elSelectMaritalStatus = document.getElementById(
      'customer-select-marital-status'
    )
    const elDataSelectMaritalStatus = document.querySelector(
      '[data-dom-id="customer-select-marital-status"'
    )
    const elMaritalStatusContainer = document.querySelector(
      '.financing-request__marital-status-container'
    )

    if (!elDataSelectMaritalStatus) return

    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelectMaritalStatus.innerHTML
    )

    const handleChange = ({ value }) => {
      if (value && (value === 'married' || value === 'living_common_law')) {
        elMaritalStatusContainer.classList.remove('d-none')
      } else {
        elMaritalStatusContainer.classList.add('d-none')
      }
    }

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={options.find((state) => state.value === defaultValue)}
        onChange={handleChange}
      />,
      elSelectMaritalStatus
    )
  }

  /**
   * Renderiza select estados para endereços
   */
  function handleSelectAddressStateChange({ value }) {
    if (!value) return
    getStateInput().value = value
    toggleClassErrorSelectAddressState()
  }

  function renderSelectAddressState() {
    const elSelectAddressState = document.getElementById(
      'customer-select-address-state'
    )

    if (!elSelectAddressState) return

    const { options, defaultValue } = elSelectAddressState.dataset
    const optionsJSON = JSON.parse(options)

    ReactDOM.render(
      <Select
        className="form__control-select"
        classNamePrefix="form__control-select"
        name="customer[state]"
        options={optionsJSON}
        placeholder="Selecione uma opção"
        defaultValue={optionsJSON.find((uf) => uf.value === defaultValue)}
        onChange={handleSelectAddressStateChange}
      />,
      elSelectAddressState
    )
  }

  function handleOccupationChange(option) {
    const professionalDetailsContainer = document.querySelector(
      '.financing-request__professional-details-container'
    )
    const professionContainer = document.querySelector(
      '.financing-request__profession-container'
    )
    const companyDetailsContainer = document.querySelector(
      '.financing-request__company-details-container'
    )
    const elOccupationContainer = document.querySelector(
      '.financing-requests__occupation-container'
    )
    const elOccupationCompany = document.querySelector(
      '.financing-request__company'
    )

    const { value } = option

    if (value && value != 'no_gainful_employment') {
      elOccupationContainer.classList.remove('d-none')
    } else {
      elOccupationContainer.classList.add('d-none')
    }

    if (['retired_or_pensioner', 'rental_income'].includes(value)) {
      professionalDetailsContainer.classList.add('d-none')
    } else {
      professionalDetailsContainer.classList.remove('d-none')
    }

    if (value == 'social_owner') {
      companyDetailsContainer.classList.remove('d-none')
      professionContainer.classList.add('d-none')
    } else {
      companyDetailsContainer.classList.add('d-none')
      professionContainer.classList.remove('d-none')
    }

    if (['social_owner', 'salaried'].includes(value)) {
      elOccupationCompany.classList.remove('d-none')
    } else {
      elOccupationCompany.classList.add('d-none')
    }

    store.dispatch(occupationChanged(value))
  }

  function renderSelectOccupation() {
    const elSelectOccupation = document.getElementById(
      'customer-select-occupation'
    )
    const elDataSelectOccupation = document.querySelector(
      '[data-dom-id="customer-select-occupation"]'
    )

    if (!elDataSelectOccupation) return

    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelectOccupation.innerHTML
    )

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={options.find((uf) => uf.value === defaultValue)}
        onChange={handleOccupationChange}
      />,
      elSelectOccupation
    )
  }

  /**
   * Renderiza select estados para ocupação
   */
  function renderSelectOccupationState() {
    const elSelectOccupationState = document.getElementById(
      'customer-select-occupation-state'
    )
    const elDataSelectOccupationState = document.querySelector(
      '[data-dom-id="customer-select-occupation-state"]'
    )

    if (!elDataSelectOccupationState) return

    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelectOccupationState.innerHTML
    )

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={options.find(
          (occupation) => occupation.value === defaultValue
        )}
      />,
      elSelectOccupationState
    )
  }

  ;(function applyDateMaskToDateBirthInput() {
    IMask(document.getElementById('customer_date_birth'), dateMask)
  })()

  function renderProfessionsSelect() {
    const elSelectProfession = document.getElementById(
      'financing-requests-select-profession'
    )
    const elDataSelectOccupation = document.querySelector(
      '[data-dom-id="financing-requests-select-profession"]'
    )

    if (!elDataSelectOccupation) return

    const { name, className, placeholder, currentValue } = JSON.parse(
      elDataSelectOccupation.innerHTML
    )

    ReactDOM.render(
      <Provider store={store}>
        <ProfessionsSelect
          name={name}
          className={className}
          placeholder={placeholder}
          currentValue={currentValue}
        />
      </Provider>,
      elSelectProfession
    )
  }

  /**
   * Adiciona ou remove class de erro para os selects
   *
   * @param {HTMLElement} elInputHidden
   * @param {HTMLElement} elSelectContainer
   */
  function toggleClassErrorSelect({ elInputHidden, elSelectContainer }) {
    const validate = validator.validate(elInputHidden)

    const isValid = validate ? validate.valid : true

    if (isValid) {
      elSelectContainer.classList.remove('form__select-container--is-invalid')
    } else {
      elSelectContainer.classList.add('form__select-container--is-invalid')
    }
  }

  /**
   * Adiciona ou remove class de erro para os selects de estado do endereço.
   */
  function toggleClassErrorSelectAddressState() {
    if (!getStateInput()) return

    toggleClassErrorSelect({
      elInputHidden: getStateInput(),
      elSelectContainer: document.getElementById(
        'customer-select-address-state'
      ),
    })
  }

  function hideFieldErrorsOnInputKeyup() {
    const inputs = [...document.querySelectorAll('.form__control.is-invalid')]
    inputs.map((input) => {
      input.addEventListener('keyup', (e) => {
        input.classList.remove('is-invalid')
        input
          .closest('.form-group')
          .querySelector('span')
          .classList.add('d-none')
      })
    })
  }

  function handleFormValidation() {
    const elForm = document.querySelector('.financing-requests__form')

    elForm.addEventListener(
      'submit',
      (e) => {
        const formIsValid = validator.validateAll(elForm).length === 0

        toggleClassErrorSelectAddressState()

        if (!formIsValid) {
          e.preventDefault()
          return
        }

        // TODO: remover código abaixo após corrigir envio de valores com ponto(.) no formato R$ 1.000,00
        const elInputIncomeSpouse = document.getElementById(
          'financing_request_income_spouse'
        )
        const elInputIncome = document.getElementById(
          'financing_request_income'
        )

        if (elInputIncomeSpouse) {
          elInputIncomeSpouse.value = elInputIncomeSpouse.value.replace(
            /\./,
            ''
          )
        }

        if (elInputIncome) {
          elInputIncome.value = elInputIncome.value.replace(/\./, '')
        }
        // END TODO

        elForm.submit()
      },
      false
    )
  }

  /**
   * Ao inserir CEP, aplica outros campos de endereço automaticamente
   */

  autofillCepData(elFinancingRequests, 'customer-select-address-state')

  /**
   * Chamadas das funções
   */

  renderSelectMaritalStatus()
  renderSelectAddressState()
  renderSelectOccupationState()
  renderSelectOccupation()
  renderProfessionsSelect()

  applyCpfMask(elFinancingRequests)
  applyPhoneMask(elFinancingRequests)
  applyMoneyMask(elFinancingRequests)
  applyCepMask(elFinancingRequests)
  applyCnpjMask(elFinancingRequests)

  hideFieldErrorsOnInputKeyup()

  handleFormValidation()

  const currentOccupation = document.getElementsByName(
    'customer[occupation]'
  )[0]

  if (currentOccupation) {
    // atualizando a visualização dos componentes via front
    handleOccupationChange({ value: currentOccupation.value })
  }
}

const elFinancingRequestsManual = document.querySelector(
  '.financing-requests-manual'
)

if (elFinancingRequestsManual) {
  function renderCustomerManualInstallments() {
    const elSelectManualInstallments = document.getElementById(
      'customer-manual-installments'
    )
    const elDataSelectManualInstallments = document.querySelector(
      '[data-dom-id="customer-manual-installments"'
    )

    if (!elDataSelectManualInstallments) return

    const { name, options, className, placeholder, defaultValue } = JSON.parse(
      elDataSelectManualInstallments.innerHTML
    )

    ReactDOM.render(
      <Select
        className={className}
        classNamePrefix="form__control-select"
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={options.find((state) => state.value === defaultValue)}
      />,
      elSelectManualInstallments
    )
  }

  renderCustomerManualInstallments()
}
