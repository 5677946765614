import React from 'react'

import { Overlay } from './styled'

const Loadingpage = () => {
  return (
    <Overlay>
      <div className="circle"></div>
      <div className="circle delayCircle"></div>
      <bold className="loadingText one">Buscando a melhor condição...</bold>
      <bold className="loadingText two">Avaliando seu veículo...</bold>
      <bold className="loadingText three">Criando sua cotação...</bold>
    </Overlay>
  )
}

export default Loadingpage
