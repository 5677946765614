import wNumb from 'wnumb'

export const moneyFormat = wNumb({
  decimals: 2,
  mark: ',',
  thousand: '.',
  prefix: 'R$ ',
})

export const mileageFormat = wNumb({
  decimals: 0,
  mark: ',',
  thousand: '.'
})
