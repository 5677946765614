import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

const Card = ({ display_name, address, zip_code, sectors, map }) => {
  return (
    <Container>
      <section className="stores-carousel-card-header">
        <Title>{display_name}</Title>
        <Address>
          {address}{' '}
          {zip_code ? (
            <>
              {' '}
              - <span className="text-nowrap">CEP {zip_code}</span>
            </>
          ) : (
            <></>
          )}
        </Address>
      </section>

      {!sectors.length ? (
        <></>
      ) : (
        <section className="stores-carousel-card-sectors">
          <SubTitle>Horários de atendimento:</SubTitle>

          {sectors.map((item, index) => (
            <Sector key={index}>
              <b>{item.name}</b>

              {item.hours.map((hour, index) => (
                <Hour key={index}>{hour.description}</Hour>
              ))}
            </Sector>
          ))}
        </section>
      )}

      <section className="stores-carousel-card-footer">
        {map ? (
          <MapLink href={map} target="_blank">
            Ver no mapa
          </MapLink>
        ) : (
          <></>
        )}
      </section>
    </Container>
  )
}

const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  gap: ${rem(32)};
  flex-direction: column;
  border-radius: ${rem(8)};
  padding: ${rem(24)} ${rem(24)} ${rem(32)};
  width: 100%;
  height: 100%;
`

const Title = styled.h3`
  color: #0b0b0b;
  font-size: ${rem(18)};
  font-weight: 700;
  line-height: ${rem(27)};
`

const SubTitle = styled.h4`
  font-size: ${rem(16)};
  font-weight: 700;
  line-height: ${rem(24)};
`

const Sector = styled.section`
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(21)};
  color: #737380;
`

const Hour = styled.p`
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(21)};
  color: #737380;
  margin-bottom: ${rem(0)};
`

const Address = styled.p`
  color: #737380;
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(21)};
  margin: 0;
`

const MapLink = styled.a`
  color: var(--primary);
  font-size: ${rem(16)};
  font-weight: 700;
  line-height: ${rem(24)};
  text-decoration: none;
  cursor: pointer;
`

export default Card
