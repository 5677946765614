import IMask from 'imask'
import {
  moneyMask,
  cpfMasks,
  phoneMasks,
  cepMasks,
  cnpjMasks,
  plateMasks,
  kmMask,
  creditNumberMasks,
  creditValidateMasks,
  yearMasks,
  positiveNumberMask,
  addressNumberMask,
} from './masks'

/**
 * @param {HTMLElement} container
 */
export const applyMoneyMask = (container) => {
  const elsMasks = container.querySelectorAll('input[data-mask="money"]')

  elsMasks.forEach((elMask) => {
    // Verificamos se casas decimais são aceitas
    const { noDecimal } = elMask?.dataset
    const mask = { ...moneyMask }

    if (noDecimal) {
      mask.blocks.num = {
        mask: Number,
        thousandsSeparator: '.',
        scale: 0,
      }
    }

    IMask(elMask, { mask: moneyMask })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyYearMask = (container) => {
  const elMasks = container.querySelector('input[data-mask="year"]')

  if (elMasks) {
    elMasks.forEach((elMask) => {
      IMask(elMask, { mask: yearMasks })
    })
  }
}

/**
 * @param {HTMLElement} container
 */
export const applyCpfMask = (container) => {
  const elsMasks = container.querySelectorAll('input[data-mask="cpf"]')

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: cpfMasks,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyPhoneMask = (container) => {
  const elsMasks = container.querySelectorAll('input[data-mask="phone"]')

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: phoneMasks,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyCepMask = (container) => {
  const elsMasks = container.querySelectorAll('input[data-mask="cep"]')

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: cepMasks,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyCnpjMask = (container) => {
  const elsMasks = container.querySelectorAll('input[data-mask="cnpj"]')

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: cnpjMasks,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyPlateMask = (container) => {
  const elsMasks = container.querySelectorAll('input[data-mask="plate"]')

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: plateMasks,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyKmMask = (container) => {
  const elsMasks = container.querySelectorAll('input[data-mask="km"]')

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: kmMask,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyCreditCardNumberMask = (container) => {
  const elsMasks = container.querySelectorAll(
    'input[data-mask="credit-card-number"]'
  )

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: creditNumberMasks,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyCreditCardValidateMask = (container) => {
  const elsMasks = container.querySelectorAll(
    'input[data-mask="credit-card-validate"]'
  )

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: creditValidateMasks,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyPositiveNumberMask = (container) => {
  const elsMasks = container.querySelectorAll(
    'input[data-mask="positive-number"]'
  )

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: positiveNumberMask,
    })
  })
}

/**
 * @param {HTMLElement} container
 */
export const applyAddressNumberMask = (container) => {
  const elsMasks = container.querySelectorAll(
    'input[data-mask="address-number"]'
  )

  elsMasks.forEach((elMask) => {
    IMask(elMask, {
      mask: addressNumberMask,
    })
  })
}

/**
 * Aplica todas as máscaras relacionadas à dados pessoais / corporativos
 * @param {HTMLElement} container
 * @returns null
 */
export const applyAllIdentityMasks = (container) => {
  applyCepMask(container)
  applyCnpjMask(container)
  applyCpfMask(container)
  applyPhoneMask(container)
  applyPositiveNumberMask(container)
  applyAddressNumberMask(container)
}
