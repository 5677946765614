import React from 'react'
import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import Select from 'react-select'
import clsx from 'clsx'

import {
  getVehicleMakes,
  getVehicleModels,
  getVehicleVersions,
  getSmartPriceVersions,
} from '../../service/api'

const AutoAvaliarPricing = (props) => {
  const yearRef = useRef(null)

  //os itens a seguir são arrays que são preenchidos conforme as requisições acontecem, e são usados pelos select
  const [options, setOptions] = useState(props.options)
  const [versionsNotFound, setVersionsNotFound] = useState(false)
  const [plateNotFound, setPlateNotFound] = useState(false)
  const [modelsOptions, setModelsOptions] = useState([])
  const [finalVersionOptions, setFinalVersionOptions] = useState([])

  // os itens a seguir são os valores conforme o usuário escolhe seu modelo, versão, marca e ano, respectivamente
  const [currentModel, setCurrentModel] = useState({})
  const [currentVersion, setCurrentVersion] = useState({})
  const [name, setName] = useState(props.name || '') //name é a marca
  const [currentYear, setCurrentYear] = useState(props.year)

  // os itens a seguir referem-se ao layout da página, quando algum erro acontece
  const [loading, setLoading] = useState(false)

  const [plate, setPlate] = useState(props.plate)
  const [dealerId, setDealerId] = useState(props.dealerId)
  const [state, setState] = useState(props.state)

  const [byPlate, setByPlate] = useState(false)

  if (props.plate && props.state) {
    const setPlateVersions = useCallback(() => {
      setLoading(true)

      getSmartPriceVersions(props.plate, props.dealerId, props.state).then(
        (data) => {
          const year = data.year,
            makeName = data.make && data.make.name,
            makeId = data.make && data.make.id,
            modelName = data.model && data.model.name,
            modelId = data.model && data.model.id,
            versions = data.versions

          setLoading(false)

          if (year && makeName && makeId && modelName && modelId && versions) {
            setByPlate(true)

            setCurrentYear(data.year)
            setName({ label: data.make.name, value: data.make.id })
            setCurrentModel({ label: data.model.name, value: data.model.id })
            setModelsOptions([0])
            setFinalVersionOptions(
              data.versions.map((data) => ({
                label: data.name,
                value: data.id,
              }))
            )
            return
          } else {
            setPlateNotFound(true)
          }
        }
      )
    })

    useEffect(setPlateVersions, [plate])
  }

  useEffect(() => {
    if (byPlate || !currentYear) return

    setPlateNotFound(false)
    setOptions([])
    setLoading(true)

    getVehicleMakes(currentYear).then((data) => {
      setVersionsNotFound(false)
      setLoading(false)

      setOptions(data.map((data) => ({ label: data.name, value: data.id })))
    })
  }, [currentYear])

  useEffect(() => {
    if (byPlate) return

    if (name && currentYear > 1955) {
      getVehicleModels(name.value, currentYear).then((data) => {
        setModelsOptions(
          data.map((data) => ({ label: data.name, value: data.id }))
        )
      })
    } else {
      setModelsOptions([])
      setFinalVersionOptions([])
      setName('')
      setCurrentModel('')
      setCurrentVersion('')
    }
  }, [name])

  useEffect(() => {
    if (byPlate) return

    if (currentModel.value) {
      getVehicleVersions(currentModel.value, currentYear).then((data) => {
        if (data.length == 0) {
          setVersionsNotFound(true)
        }
        setFinalVersionOptions(
          data.map((data) => ({ label: data.name, value: data.id }))
        )
      })
    }
  }, [currentModel])

  useEffect(() => {
    if (byPlate) return

    yearRef.current.addEventListener('keyup', () => {
      setCurrentYear(yearRef.current.value), setName('')
    })
  })

  useEffect(() => {
    if (byPlate) return

    setCurrentModel('')
  }, [name])

  useEffect(() => {
    if (byPlate) return

    setCurrentVersion('')
  }, [currentModel])

  return (
    <div className="row">
      <div className="col-lg-6">
        {plateNotFound && (
          <div className="text-center form__invalid-feedback form__invalid-feedback--inverse">
            Sua placa não foi encontrada, por favor, preencha os campos abaixo.
          </div>
        )}
        <div className="form-group">
          <label className="form__control-label">Ano do modelo</label>
          <input
            placeholder="Ex.: 1999"
            className={clsx('form__control', {
              'form__control--loading': loading,
            })}
            required="required"
            data-mask="year"
            data-bouncer-target="#invalid-year"
            id="used_vehicle_yearRef"
            ref={yearRef}
            type="number"
            min="1955"
            max="2023"
            value={currentYear}
            onChange={(e) => setCurrentYear(e.target.value)}
          />
          <input name="used_vehicle[year]" type="hidden" value={currentYear} />
          <div id="invalid-year" className="form__invalid-feedback" />
        </div>
      </div>
      <div
        className={clsx('col-lg-6', {
          'element-disabled': currentYear > 1955 ? false : true,
        })}
      >
        <div className="form-group">
          <label className="form__control-label">Marca do veículo</label>
          <Select
            options={options}
            value={name}
            onChange={setName}
            className="form__control-select"
            classNamePrefix="form__control-select"
            placeholder="Selecione uma opção"
            name="used_vehicle[make_id]"
            isDisabled={currentYear > 1955 && !byPlate ? false : true}
          />
          <input
            name="used_vehicle[name]"
            type="hidden"
            value={name.label}
            data-bouncer-target="#invalid-name"
            required
          />
          {!name.label && (
            <div
              id="invalid-name"
              className={clsx('form__invalid-feedback', {
                'element-disabled': currentYear > 1955 ? false : true,
              })}
            />
          )}
        </div>
      </div>

      <div
        className={clsx('col-12', {
          'element-disabled': !modelsOptions.length,
        })}
      >
        <div className="form-group">
          <label className="form__control-label">Modelo</label>
          <div className="row">
            <div className="col">
              <Select
                options={modelsOptions}
                value={currentModel}
                onChange={setCurrentModel}
                className="form__control-select"
                classNamePrefix="form__control-select"
                placeholder="Selecione uma opção"
                name="used_vehicle[model_id]"
                isDisabled={modelsOptions.length && !byPlate ? false : true}
              />
              <input
                name="used_vehicle[model]"
                type="hidden"
                value={currentModel.label || ''}
                data-bouncer-target="#invalid-model"
                required
              />
              {!currentModel.label && (
                <div
                  id="invalid-model"
                  className={clsx('form__invalid-feedback', {
                    'element-disabled': !modelsOptions.length,
                  })}
                />
              )}
              {versionsNotFound && (
                <div className="form__invalid-feedback form__invalid-feedback--inverse mt-3">
                  <div className="error-message">
                    Não encontramos versões disponíveis.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={clsx('col-lg-6', {
          'element-disabled': !finalVersionOptions.length,
        })}
      >
        <div className="form-group">
          <label className="form__control-label">
            Selecione a versão do veículo
          </label>
          <Select
            options={finalVersionOptions}
            value={currentVersion}
            onChange={setCurrentVersion}
            className="form__control-select"
            classNamePrefix="form__control-select"
            placeholder="Selecione uma opção"
            isDisabled={!finalVersionOptions.length}
          />
          <input
            name="used_vehicle[version_id]"
            type="hidden"
            value={currentVersion.value || ''}
            data-bouncer-target="#invalid-final-version"
            required
          />
          <input
            name="used_vehicle[version]"
            type="hidden"
            value={currentVersion.label || ''}
            data-bouncer-target="#invalid-version"
          />
          <input name="state" type="hidden" value={props.state} />
          {!currentVersion.value && (
            <div
              id="invalid-final-version"
              className={clsx('form__invalid-feedback', {
                'element-disabled': !finalVersionOptions.length,
              })}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AutoAvaliarPricing
