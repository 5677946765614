import React from 'react'
const getSelectStateAndKM = () => import('../components/SelectStateAndKM')
import { render } from 'react-dom'
;(() => {
  const elContainer = document.querySelector('.buy-used_small-section')

  if (!elContainer) return

  const selectStateAndKMContainer = document.getElementById(
    'select-state-and-km-container'
  )
  if (!selectStateAndKMContainer) return

  getSelectStateAndKM().then((mod) => {
    const SelectStateAndKM = mod.default
    const domData = document.querySelector(
      '[data-dom-id="select-state-and-km-container"]'
    )
    const props = JSON.parse(domData.innerHTML)

    render(<SelectStateAndKM {...props} />, selectStateAndKMContainer)
  })
})()
