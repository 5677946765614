import styled from 'styled-components'
import { rem } from 'polished'

export const ScheduleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: ${rem(809)};
  margin: ${rem(16)};
  font-size: ${rem(14)};
  font-weight: 400;
  text-align: center;
  color: var(--gray-5);
  margin-bottom: ${rem(64)};
  > svg {
    margin: ${rem(40)} auto 0;
  }
  @media (min-width: 820px) {
    margin: ${rem(104)} auto;
    font-size: ${rem(16)};
  }
`
export const Title = styled.h1`
  font-size: ${rem(18)};
  font-weight: 700;
  margin: ${rem(24)} 0 ${rem(16)};
  color: var(--gray-8);
  @media (min-width: 820px) {
    font-size: ${rem(24)};
  }
`
export const YourScheduleCard = styled.div`
  margin-top: ${rem(32)};
  border: 1px solid var(--gray-4);
  border-radius: ${rem(16)};
  text-align: start;
`
export const YourScheduleCTA = styled.div`
  border-bottom: 1px solid var(--gray-4);
  @media (min-width: 800px) {
    display: flex;
    justify-content: space-between;
    margin: ${rem(32)} ${rem(32)} 0;
    padding-bottom: ${rem(16)};
    > div {
      box-sizing: content-box;
      max-width: ${rem(300)};
    }
  }
`
export const YourScheduleCTATitle = styled.h2`
  font-size: ${rem(18)};
  font-weight: 700;
  margin: ${rem(24)} ${rem(24)} ${rem(4)};
  color: var(--gray-8);
  @media (min-width: 800px) {
    margin: 0 0 ${rem(4)};
  }
`
export const YourScheduleCTADesc = styled.p`
  margin: 0 ${rem(24)} ${rem(24)};
  @media (min-width: 800px) {
    font-size: ${rem(14)};
    margin: 0;
  }
`
export const YourScheduleInfo = styled.div`
  margin: ${rem(24)} ${rem(24)} 0;
  display: flex;
  gap: ${rem(16)};
  > div {
    width: calc(100% - 60px);
  }
  svg {
    rect {
      fill: var(--primaryLightest);
    }
    path {
      stroke: var(--primary);
      fill: var(--primaryLightest);
    }
  }
  div p {
    margin-bottom: 0;
  }
  @media (min-width: 800px) {
    margin: 0;
  }
`
export const YourScheduleInfoContainer = styled.div`
  @media (min-width: 800px) {
    margin: ${rem(24)} ${rem(32)} ${rem(32)};
    display: flex;
    justify-content: space-between;
  }
`
export const YourScheduleInfoTitle = styled.h3`
  color: var(--gray-8);
  font-size: ${rem(14)};
  font-weight: 700;
  text-align: start;
  margin-bottom: 0;
`
export const YourScheduleInfoBtn = styled.a`
  display: flex;
  justify-content: space-between;
  margin: ${rem(34)} ${rem(24)} ${rem(24)};
  border-radius: ${rem(8)};
  background-color: var(--primary);
  color: #fff;
  padding: ${rem(12)} ${rem(20)};
  line-height: ${rem(16)};
  i {
    vertical-align: center;
    font-size: ${rem(16)};
  }
  span {
    text-align: center;
    display: inline-block;
    width: calc(100% - 32px);
    font-weight: 700;
  }
  @media (min-width: 820px) {
    margin: 0;
    width: ${rem(221)};
    height: ${rem(40)};
  }
  &:hover {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
  }
`
export const AtcbDropdown = styled.div``
