import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { atcb_action } from 'add-to-calendar-button'
import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
} from '../../../../../store/ducks/avaliation-flow'
import useSmallWindow from '../../../hooks/useSmallWindow'
import {
  ScheduleContainer,
  Title,
  YourScheduleCard,
  YourScheduleCTA,
  YourScheduleCTADesc,
  YourScheduleCTATitle,
  YourScheduleInfo,
  YourScheduleInfoContainer,
  YourScheduleInfoBtn,
  YourScheduleInfoTitle,
} from './style'
import { getDealerName } from './action'
import { goTop } from '../../../actions'

const FinishedSchedule = ({ scheduleTime, formValues, saleInfo }) => {
  const [date, setDate] = useState(new Date())
  const [dealerName, setDealerName] = useState('')
  const dispatch = useDispatch()
  const isSmallWindow = useSmallWindow(800)
  const month = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'December',
  ]
  const atcbConfig = {
    name: `Inspeção de veículo presencial ${
      dealerName ? dealerName : '- ' + dealerName
    }`,
    description: `Sua inspeção está agendada para o dia ${date.getDate()}/${date.getMonth()}/${date.getFullYear()} às ${date.getHours()}:${
      date.getMinutes() == 0 ? '00' : date.getMinutes()
    }, em ${
      formValues.address
    }. Um dos nossos consultores entrará em contato para esclarecer eventuais dúvidas.<br/><br/>Será um prazer recebê-lo e ajudá-lo a ter uma melhor experiência na venda do seu veículo.`,
    startDate: `${date.getFullYear()}-${date.getMonth()}-${`0${date.getDate()}`.slice(
      -2
    )}`,
    endDate: `${date.getFullYear()}-${date.getMonth()}-${`0${date.getDate()}`.slice(
      -2
    )}`,
    startTime: `${`0${date.getHours()}`.slice(-2)}:${
      date.getMinutes() == 0 ? '00' : date.getMinutes()
    }`,
    endTime: `${`0${date.getHours() + 1}`.slice(-2)}:${
      date.getMinutes() == 0 ? '00' : date.getMinutes()
    }`,
    options: ['Apple', 'Google', 'Outlook.com'],
    trigger: 'click',
    listStyle: 'dropdown',
    timeZone: 'America/Fortaleza',
  }

  function formatDate(date) {
    const newDate = date
      .replaceAll('/', '-')
      .replace(/\sàs\s/, '-')
      .replace(/:/, '-')
    const [day, month, year, hour, min] = newDate.split('-')
    const formatedDate = new Date(
      year,
      month,
      day,
      hour,
      min,
      '00',
      '00',
      'GMT-3'
    )
    setDate(formatedDate)
  }

  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(-1))
    ;(async () => {
      const dealer = await getDealerName(saleInfo.sale_id)
      setDealerName(dealer.data)
    })()
  }, [])
  useEffect(() => {
    formatDate(scheduleTime)
  }, [scheduleTime])

  return (
    <>
      <ScheduleContainer>
        <svg
          width="104"
          height="104"
          viewBox="0 0 104 104"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="104" height="104" rx="52" fill="#F2FFF7" />
          <rect x="12" y="12" width="80" height="80" rx="40" fill="#00C390" />
          <path
            d="M68 48V42.7C68 40.1043 65.8957 38 63.3 38H40.7C38.1043 38 36 40.1043 36 42.7V48M68 48H36M68 48V51.5M36 48V65.3C36 67.8957 38.1043 70 40.7 70H50M44 34V42M60 34V42"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M56 66L60 70L70 60"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Title>
          Parabéns! Você deu o primeiro passo para vender seu veículo
        </Title>
        <p>
          Em breve entraremos em contato para confirmar a inspeção do seu
          veículo, o valor final da nossa oferta depende do resultado da sua
          avaliação presencial.
        </p>
        <YourScheduleCard>
          <YourScheduleCTA>
            <div>
              <YourScheduleCTATitle>Seu agendamento</YourScheduleCTATitle>
              <YourScheduleCTADesc>
                Revise os detalhes do seu agendamento e adicione o evento em sua
                agenda.
              </YourScheduleCTADesc>
            </div>
            {!isSmallWindow && (
              <YourScheduleInfoBtn
                id="schedule-btn-desk"
                onClick={(e) => {
                  e.preventDefault()
                  atcb_action(
                    atcbConfig,
                    document.querySelector('#schedule-btn-desk')
                  )
                }}
              >
                <span>Adicionar a agenda</span>
                <i className="icon icon-calendar"></i>
              </YourScheduleInfoBtn>
            )}
          </YourScheduleCTA>
          <YourScheduleInfoContainer>
            <YourScheduleInfo>
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="44" height="44" rx="16" fill="#F1EFFF" />
                <path
                  d="M29 14H15C13.8954 14 13 14.8954 13 16V30C13 31.1046 13.8954 32 15 32H29C30.1046 32 31 31.1046 31 30V16C31 14.8954 30.1046 14 29 14Z"
                  stroke="#5D4DD9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 20H31"
                  stroke="#5D4DD9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26 12V16"
                  stroke="#5D4DD9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 12V16"
                  stroke="#5D4DD9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div>
                <YourScheduleInfoTitle>Data</YourScheduleInfoTitle>
                <p>{`Dia ${date.getDate()} de ${
                  month[date.getMonth() - 1]
                } às ${date.getHours()}h${
                  date.getMinutes() == 0 ? '00' : date.getMinutes()
                }`}</p>
              </div>
            </YourScheduleInfo>

            <YourScheduleInfo>
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="#F1EFFF"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="44" height="44" rx="16" fill="#F1EFFF" />
                <path
                  d="M31 20C31 27 22 33 22 33C22 33 13 27 13 20C13 17.6131 13.9482 15.3239 15.636 13.636C17.3239 11.9482 19.6131 11 22 11C24.3869 11 26.6761 11.9482 28.364 13.636C30.0518 15.3239 31 17.6131 31 20Z"
                  stroke="#5D4DD9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 23C23.6569 23 25 21.6569 25 20C25 18.3431 23.6569 17 22 17C20.3431 17 19 18.3431 19 20C19 21.6569 20.3431 23 22 23Z"
                  stroke="#5D4DD9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div>
                <YourScheduleInfoTitle>Local</YourScheduleInfoTitle>
                <p>{formValues.address}</p>
              </div>
            </YourScheduleInfo>
          </YourScheduleInfoContainer>
          {isSmallWindow && (
            <YourScheduleInfoBtn
              id="schedule-btn-mob"
              onClick={(e) => {
                e.preventDefault()
                atcb_action(
                  atcbConfig,
                  document.querySelector('#schedule-btn-mob')
                )
              }}
            >
              <span>Adicionar a agenda</span>
              <i className="icon icon-calendar"></i>
            </YourScheduleInfoBtn>
          )}
        </YourScheduleCard>
      </ScheduleContainer>
    </>
  )
}
const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
  scheduleTime: state.avaliationFlow.scheduleTime,
  saleInfo: state.avaliationFlow.saleInfo,
})

export default connect(mapStateToProps)(FinishedSchedule)
