export default function reducer(
  state = { toggleCheckGallery: true },
  action = {}
) {
  switch (action.type) {
    case 'TOGGLE_GALLERY_360/360':
      return {
        ...state,
        toggleCheckGallery: true,
      }

    case 'TOGGLE_GALLERY_360/GALLERY':
      return {
        ...state,
        toggleCheckGallery: false,
      }

    default:
      return state
  }
}
