import styled from 'styled-components'
import { rem } from 'polished'
import { Button } from 'autocommerce-sb'

export const Container = styled.div`
  width: ${rem(554)};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-y: hidden;
  @media (max-width: ${rem(600)}) {
    width: ${rem(312)};
  }
  button {
    margin: 0 auto ${rem(36)};
    @media (min-width: ${rem(600)}) {
      margin-bottom: ${rem(138)};
    }
  }
`
export const Results = styled.p`
  color: var(--gray-8);
  font-family: Roboto;
  font-size: ${rem(18)};
  font-weight: 400;
  line-height: ${rem(21)};
  letter-spacing: -2%;
  text-align: center;
  margin: ${rem(48)} auto ${rem(24)};
  @media (min-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
  strong {
    font-weight: 600;
  }
`
export const ResultValue = styled.p`
  font-weight: 400;
  font-size: ${rem(32)};
  text-align: center;
  strong {
    font-weight: 900;
    font-size: ${rem(48)};
  }
`
export const WarningContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: var(--primary);
`
export const StyledIcon = styled.i`
  font-size: ${rem(22)};
  color: var(--primary);
  margin-right: ${rem(18)};
`
export const WarningText = styled.strong`
  font-family: Roboto;
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(21)};
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary);
`
export const CotationWarning = styled.div`
  margin: ${rem(16)} auto ${rem(32)};
  display: inline-block;
  min-width: ${rem(312)};
  max-width: ${rem(423)};
  width: auto;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border: ${rem(1)} solid var(--gray-4);
  border-radius: 1rem;
  ${({ breakLine }) => (breakLine ? 'padding: 16px;' : '')}
  p {
    margin-bottom: 0;
    color: var(--gray-5);
    strong {
      font-weight: 700;
      color: var(--gray-8);
      ${({ breakLine }) =>
        breakLine ? 'display: flex; flex-direction: column' : ''}
    }
  }
`
export const BonusBanner = styled.div`
  font-family: Roboto;
  display: flex;
  color: var(--primary);
  background-color: var(--primaryLightest);
  border-radius: ${rem(16)};
  padding: ${rem(16)};
  margin-bottom: ${rem(48)};
  i {
    color: #fff;
    background-color: var(--primary);
    height: ${rem(32)};
    width: ${rem(32)};
    border-radius: 50%;
    padding: 1rem;
    position: relative;
    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`
export const BonusTitle = styled.h2`
  font-size: ${rem(18)};
  font-weight: 700;
`
export const BonusCTA = styled.p`
  font-size: ${rem(14)};
  font-weight: 400;
  margin-bottom: 0;
  strong {
    font-weight: 900;
  }
`
export const ScheduleVisit = styled.p`
  font-size: ${rem(14)};
  font-weight: 400;
  color: var(--gray-5);
  text-align: center;
`
export const JoinUsContainer = styled.div`
  margin: 0 auto;
  padding-left: ${rem(32)};
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    height: ${rem(48)};
    width: ${rem(48)};
    border-radius: 50%;
  }
  @media (min-width: ${rem(600)}) {
    margin-top: ${rem(138)};
  }
`
export const JoinUsCTA = styled.p`
  font-size: ${rem(14)};
  font-weight: 700;
  color: var(--gray-5);
  text-align: center;
  max-width: ${rem(326)};
  margin: 0 auto ${rem(72)};
  @media (min-width: ${rem(600)}) {
    margin-bottom: ${rem(191)};
  }
`
export const BigScreenModal = styled.div`
  position: absolute;
  display: ${(props) => (props.bigScreenModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  transition: 500ms ease-in-out;
  > div {
    height: ${rem(300)};
    width: ${rem(520)};
    background-color: #fff;
    z-index: 1022;
    border-radius: ${rem(8)};
    padding: ${rem(40)} ${rem(32)} ${rem(24)};
    position: relative;
    svg {
      cursor: pointer;
      position: relative;
      right: calc(24px - 100%);
      top: -1rem;
    }
  }
  div.overlay {
    content: '';
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: 0.32;
    z-index: 1021;
    cursor: pointer;
  }
`
export const BigScreenModalTitle = styled.h2`
  font-size: ${rem(18)};
  font-weight: 700;
  color: var(--primary);
`
export const BigScreenModalDesc = styled.p`
  font-size: ${rem(16)};
  font-weight: 400;
  color: var(--gray-5);
  line-height: ${rem(24)};
`
export const LeaveMouseOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 1rem;
`
export const LeaveMouseModal = styled.div`
  position: absolute;
  display: ${(props) => (props.leaveMouseModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  transition: 500ms ease-in-out;
  > div {
    height: ${rem(484)};
    width: ${rem(639)};
    background-color: #fff;
    z-index: 1022;
    border-radius: ${rem(8)};
    padding: ${rem(24)} ${rem(24)} ${rem(64)};
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    svg.close-btn {
      cursor: pointer;
      position: absolute;
      top: ${rem(24)};
      right: ${rem(24)};
    }
    svg.calendar-icon {
      margin: ${rem(60)} auto ${rem(24)};
      rect {
        fill: var(--primaryLightest);
      }
      circle {
        fill: var(--primary);
      }
    }
  }
  div.overlay {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: 0.32;
    z-index: 1021;
    cursor: pointer;
  }
  .icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: ${rem(24)};
  }
`
export const LeaveMouseTitle = styled.h2`
  font-size: ${rem(24)};
  font-weight: 700;
  color: var(--primary);
`
export const LeaveMouseDesc = styled.p`
  font-size: ${rem(16)};
  font-weight: 400;
  color: var(--gray-5);
  padding-inline: ${rem(52)};
  margin-bottom: ${rem(64)};
`
export const LeaveModalButton = styled(Button)`
  margin: 0 auto;
  margin-bottom: 0 !important;
  background-color: var(--primary) !important;
`
export const FailedRequisitionContainer = styled.div`
  max-width: ${rem(312)};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-y: hidden;
  text-align: center;
  @media (min-width: ${rem(800)}) {
    max-width: ${rem(800)};
  }
  button {
    margin: 0 auto;
  }
  > svg {
    height: ${rem(80)};
    width: ${rem(80)};
    transform: scale(0.769);
    margin: ${rem(63)} auto ${rem(16)};
    rect.outside-ring {
      fill: var(--primaryLightest);
    }
    rect.inside-ring {
      fill: var(--primary);
    }
  }
  .failed-req-accordion {
    margin-bottom: ${rem(16)};
    padding: ${rem(16)};
    text-align: start;
    div > svg {
      margin-left: ${rem(8)};
    }
    span {
      font-size: ${rem(14)};
    }
    .open-description {
      height: auto;
    }
    &:last-of-type {
      margin-bottom: ${rem(80)};
    }
    @media (min-width: ${rem(800)}) {
      &:first-of-type {
        margin-top: ${rem(8)};
      }
      padding: ${rem(24)};
      span {
        font-size: ${rem(16)};
      }
    }
  }
  @media (min-width: ${rem(800)}) {
    > svg {
      height: ${rem(104)};
      width: ${rem(104)};
      transform: scale(1);
      margin: ${rem(103)} auto ${rem(24)};
    }
  }
`
export const FailedRequisitionTitle = styled.h1`
  font-size: ${rem(18)};
  font-weight: 700;
  margin-bottom: ${rem(12)};
  color: var(--gray-8);
`
export const FailedRequisitionCTA = styled.p`
  font-size: ${rem(14)};
  font-weight: 400;
  color: var(--gray-5);
  margin-bottom: ${rem(48)};
`
export const FailedRequisitionFaqTitle = styled.h2`
  font-size: ${rem(16)};
  font-weight: 700;
  margin-top: ${rem(64)};
  margin-bottom: ${rem(24)};
  color: var(--gray-8);
`
