import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from '../store'
import { ShortNavbarHeader } from '../components/ShortNavbarHeader'

const elData = document.querySelector('#short_header__navbar')

if (elData) {
  const { logoPath, unitsPhone, unitsWhatsapp, socialLinks, url_state } =
    elData.dataset

  ReactDOM.hydrate(
    <Provider store={store}>
      <ShortNavbarHeader
        logoPath={logoPath}
        unitsPhone={JSON.parse(unitsPhone)}
        unitsWhatsapp={JSON.parse(unitsWhatsapp)}
        socialLinks={JSON.parse(socialLinks)}
        url_state={JSON.parse(url_state)}
      />
    </Provider>,
    document.getElementById('short_header__navbar')
  )
}
