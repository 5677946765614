import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Dropzone from 'react-dropzone'
import filesize from 'filesize'
import { useCallback } from 'react'
import { Download } from 'react-feather'

const UploadDropzoneTwo = ({
  number,
  title,
  inputName,
  maxSize = 15728640,
  classDocumentIcon = 'icon-file-alt-solid',
  onRemoveFile = () => {},
  onAddFile = () => {},
  iconCheckRenderFn,
  iconTrashRenderFn,
  iconFileRenderFn,
  linkDownload,
}) => {
  const [file, setFile] = useState(null)
  const [failed, setFailed] = useState(false)
  const getFileName = useCallback(() => file && file?.name, [file])

  const handleDropAccepted = useCallback(
    (acceptedFiles) => {
      setFailed(false)

      const [acceptedFile] = acceptedFiles
      onAddFile(inputName)

      setFile(acceptedFile)
    },
    [file]
  )

  const handleDropRejected = useCallback(
    (fileRejections) => {
      setFailed(true)

      const [fileRejected] = fileRejections
      const { file } = fileRejected

      setFile(file)
    },
    [file]
  )

  const handleRemoveClick = useCallback(
    (inputRef) => (e) => {
      e.preventDefault()
      e.stopPropagation()
      onRemoveFile(inputName)

      setFile(null)

      if (inputRef) {
        inputRef.current.value = null
      }
    },
    [file]
  )

  return (
    <Dropzone
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
      accept="image/*,application/pdf"
      multiple={false}
      maxSize={maxSize}
    >
      {({ getRootProps, getInputProps, inputRef }) => (
        <div
          {...getRootProps({
            className: 'upload-dropzone-two',
          })}
        >
          <input
            {...getInputProps({
              name: inputName,
            })}
            data-testid="inputFile"
          />

          {title && (
            <div
              className="upload-dropzone-two__title"
              onClick={(e) => e.stopPropagation()}
            >
              {number && (
                <div
                  className="upload-dropzone-two__number d-none d-xl-flex"
                  onClick={(e) => e.stopPropagation()}
                >
                  {number}
                </div>
              )}
              {title}
            </div>
          )}

          <div
            className={clsx('upload-dropzone-two__container', {
              'upload-dropzone-two__container--error': failed,
            })}
          >
            {linkDownload && !getFileName() && (
              <a
                target="_blank"
                href={linkDownload}
                onClick={(e) => e.stopPropagation()}
              >
                <Download className="upload-dropzone-two__download-icon" />
              </a>
            )}
            {getFileName() ? (
              <>
                <div className="upload-dropzone-two__file-name d-xl-none">
                  {getFileName()}
                  {!failed ? (
                    <div className="upload-dropzone-two__check-solid">
                      {iconCheckRenderFn ? (
                        iconCheckRenderFn()
                      ) : (
                        <i className="icon icon-check-circle-solid" />
                      )}
                    </div>
                  ) : (
                    <>
                      <div className="upload-dropzone-two__text-resend">
                        Reenviar
                      </div>
                      <div className="upload-dropzone-two__times-solid">
                        {iconCheckRenderFn ? (
                          iconCheckRenderFn()
                        ) : (
                          <i className="icon icon-check-circle-solid" />
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="upload-dropzone-two__document d-none d-xl-block">
                  <div
                    className="upload-dropzone-two__btn-trash"
                    onClick={handleRemoveClick(inputRef)}
                  >
                    {iconTrashRenderFn ? (
                      iconTrashRenderFn()
                    ) : (
                      <i className="upload-dropzone-two__icon-trash icon icon-trash-solid" />
                    )}
                  </div>
                  <div className="upload-dropzone-two__document-content">
                    {failed ? (
                      <div className="upload-dropzone-two__times-solid">
                        <i className="icon icon-times-circle-solid" />
                      </div>
                    ) : (
                      <div className="upload-dropzone-two__check-solid">
                        <i className="icon icon-check-circle-solid" />
                      </div>
                    )}
                    <i
                      className={`upload-dropzone-two__document-icon icon ${classDocumentIcon}`}
                    />
                  </div>
                  <div>
                    {!failed ? (
                      file && filesize(file.size)
                    ) : (
                      <div className="upload-dropzone-two__text-resend">
                        Reenviar
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="upload-dropzone-two__icon d-xl-none">
                  {iconFileRenderFn ? (
                    iconFileRenderFn()
                  ) : (
                    <i className="icon icon-paperclip-solid" />
                  )}
                </div>
                <div className="upload-dropzone-two__text">
                  Busque o arquivo
                </div>
                <div className="upload-dropzone-two__subtitle d-none d-xl-block">
                  Ou arraste e solte sobre essa área.
                </div>
              </>
            )}
          </div>

          {getFileName() && (
            <div
              className={clsx(
                'upload-dropzone-two__file-name-footer d-none d-xl-flex',
                {
                  'upload-dropzone-two__file-name-footer--error': failed,
                }
              )}
            >
              {!failed ? (
                <i className="upload-dropzone-two__file-name-footer-icon-check icon icon-check-solid" />
              ) : (
                <i className="upload-gropzone-two__file-name-footer-icon-time icon icon-times-solid" />
              )}
              {getFileName()}
              {failed && (
                <div className="upload-gropzone-two__file-name-footer-text-failed">
                  Falhou
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Dropzone>
  )
}

UploadDropzoneTwo.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  inputName: PropTypes.string,
  classDocumentIcon: PropTypes.string,

  /**
   * Tamanho máximo permitido no upload
   */
  maxSize: PropTypes.number,
  iconCheckRenderFn: PropTypes.func,
  iconTrashRenderFn: PropTypes.func,
  iconFileRenderFn: PropTypes.func,
}

export default UploadDropzoneTwo
