import styled from 'styled-components'
import { rem } from 'polished'
import { SearchInput, Card } from 'autocommerce-sb'

import { ContainerBase, TitleBase } from '../../../../styled'

export const Container = styled(ContainerBase)`
  padding: ${rem(40)} 0;
`

export const Title = styled(TitleBase)`
  margin: 0 auto;
`

export const StyledCard = styled(Card)`
  margin-top: ${rem(16)};
  &:first-child {
    margin-top: ${rem(24)};
  }
`

export const StyledSearchInput = styled(SearchInput)`
  margin-top: ${rem(48)};
`

export const Logo = styled.img`
  margin-left: ${rem(24)};
  width: ${rem(40)};
  height: auto;
`
