import { getCepData } from '../service/api'
import { handleSelectAddressStateChange } from './autofill-cep-select-state-utils'

// Funcão que força uma pausa na execução
const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function autofillCepData(
  container,
  selectContainerId,
  zip_field = 'input[name="customer[zip_code]"]',
  city_field = 'input[name="customer[city]"]',
  neighborhood_field = 'input[name="customer[neighborhood]"]',
  stree_field = 'input[name="customer[street]"]',
  state_field = 'input[name="customer[state]"]'
) {
  const inputCep = container.querySelector(zip_field)
  const inputCity = container.querySelector(city_field)
  const inputDistrict = container.querySelector(neighborhood_field)
  const inputStreet = container.querySelector(stree_field)

  if (!inputCep) return

  inputCep.addEventListener('input', () => {
    const cep = inputCep.value.replace('-', '').trim()

    if (cep.length !== 8) return

    const inputUF = container.querySelector(state_field)
    const ufStateLabel = document.querySelector(
      `#${selectContainerId} .form__control-select__single-value`
    )
    const cepData = getCepData(cep)

    // start loading animation
    inputCep.classList.add('form__control--loading')
    sleep(1000).then(() => {
      cepData
        .then((data) => {
          if (data.erro) return

          inputCity && (inputCity.value = data.localidade)
          inputDistrict && (inputDistrict.value = data.bairro)
          inputStreet && (inputStreet.value = data.logradouro)
          inputUF && (inputUF.value = data.uf)

          handleSelectAddressStateChange({ value: data.uf }, selectContainerId)
          ufStateLabel.innerText = data.uf
        })
        .then(() => {
          // finishing loading anumation
          inputCep.classList.remove('form__control--loading')
        })
    })
  })
}

export default autofillCepData
