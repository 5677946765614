import styled from 'styled-components'
import { rem } from 'polished'
import { Card, Button, Accordion } from 'autocommerce-sb'

import { TitleBase } from '../../styled'

export const Title = styled(TitleBase)`
  margin: ${rem(56)} auto 0 auto;
`

export const Description = styled.h5`
  font-family: Roboto;
  margin: ${rem(24)} auto 0;
  color: var(--gray-5);
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  font-weight: 400;
  text-align: center;
  max-width: ${rem(330)};
`

export const DaysContainer = styled.div`
  display: flex;
  gap: ${rem(36)};
  align-items: center;
  justify-content: center;
  margin: ${rem(48)} auto 0 auto;
  width: 100%;
  max-width: ${rem(1920)};

  .swiper-container {
    border-radius: ${rem(8)};
    margin: 0;
    width: 100%;
    padding: 0;
    max-width: ${rem(664)};

    @media (max-width: 1092px) {
      padding: 0 ${rem(24)};
      max-width: ${rem(712)};
    }
  }

  .swiper-slide-container {
    width: auto;
    height: auto;
  }
`

export const SubTitle = styled.h6`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--gray-5);
  margin: ${rem(64)} auto 0 auto;
`

export const TimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: ${rem(33)} auto 0 auto;
  width: ${rem(624)};
  /* height: ${(props) =>
    props.thisHour > 12 && props.selectedDayId > 1 ? rem(152) : rem(76)}; */
  //height: ${rem(152)};
  @media (max-width: ${rem(600)}) {
    width: ${rem(312)};
    //height: ${rem(300)};
  }
`

export const TimeCard = styled(Card)`
  margin-bottom: ${rem(24)};
  margin-inline: ${rem(7)};
  max-width: ${rem(120)};
  max-height: ${rem(64)};
  @media (max-width: ${rem(600)}) {
    margin-bottom: ${rem(20)};
    max-width: ${rem(94)};
    max-height: ${rem(42)};
  }
`

export const StyledButton = styled(Button)`
  margin: ${rem(64)} auto 0 auto;
  @media (max-width: ${rem(600)}) {
    margin: ${rem(48)} auto 0 auto;
  }
`
export const DoubtsSection = styled.div`
  margin-top: ${rem(64)};
  display: flex;
  flex-direction: column;
  align-items: center;
  .about-you_accordion {
    margin-bottom: ${rem(16)};
    @media (max-width: ${rem(600)}) {
      width: ${rem(312)} !important;
    }
    @media (min-width: ${rem(601)}) and (max-width: ${rem(800)}) {
      width: ${rem(600)} !important;
    }
    @media (min-width: ${rem(801)}) {
      width: ${rem(800)} !important;
    }
  }
`

export const DoubtsTitle = styled.h4`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: ${rem(24)};
`
