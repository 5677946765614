import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import store from '../store'
import SocialLinks from '../components/SocialLinks'
import ModalWhatsappApp from '../components/ModalWhatsappApp'
import ModalContactApp from '../components/ModalContactApp'

const elData = document.querySelector('[data-dom-id="footer__social-links"]')
const elBackToTop = document.querySelector(
  '.footer__container-back-to-top-button'
)
const elBuyAndSellButton = document.querySelector(
  '#footer_mobile_toggle_compra_e_venda'
)
const elAboutButton = document.querySelector('#footer_mobile_toggle_sobre')
const elSupportButton = document.querySelector('#footer_mobile_toggle_suporte')
const elModalWhatsapp = document.getElementById('footer__modal-whatsapp')
const elModalContact = document.getElementById('footer__modal-contact')

if (elData) {
  const { items } = JSON.parse(elData.innerHTML)

  const SocialLinksFooter = (props) => {
    return (
      <SocialLinks {...props}>
        {items.map((item, index) => (
          <SocialLinks.Item
            key={index}
            url={item.url}
            title={`New-${item.title}`}
            classNameItemLink="social-links__item-link--medium"
          />
        ))}
      </SocialLinks>
    )
  }

  ReactDOM.render(
    <SocialLinksFooter items={items} />,
    document.getElementById('footer__social-links')
  )
}

if (elBackToTop) {
  elBackToTop.addEventListener('click', (e) => {
    e.preventDefault()
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  })
}

if (elBuyAndSellButton) {
  elBuyAndSellButton.addEventListener('click', (e) => {
    e.preventDefault()
    const elLinkList = document.querySelector('#footer_mobile_compra_e_venda')
    if (elLinkList.classList.contains('mobile-dn')) {
      elLinkList.classList.replace(
        'mobile-dn',
        'footer__container-links-mobile-container'
      )
    } else if (
      elLinkList.classList.contains('footer__container-links-mobile-container')
    ) {
      elLinkList.classList.replace(
        'footer__container-links-mobile-container',
        'mobile-dn'
      )
    }
  })
}

if (elAboutButton) {
  elAboutButton.addEventListener('click', (e) => {
    e.preventDefault()
    const elLinkList = document.querySelector('#footer_mobile_sobre')
    if (elLinkList.classList.contains('mobile-dn')) {
      elLinkList.classList.replace(
        'mobile-dn',
        'footer__container-links-mobile-container'
      )
    } else if (
      elLinkList.classList.contains('footer__container-links-mobile-container')
    ) {
      elLinkList.classList.replace(
        'footer__container-links-mobile-container',
        'mobile-dn'
      )
    }
  })
}

if (elSupportButton) {
  elSupportButton.addEventListener('click', (e) => {
    e.preventDefault()
    const elLinkList = document.querySelector('#footer_mobile_suporte')
    if (elLinkList.classList.contains('mobile-dn')) {
      elLinkList.classList.replace(
        'mobile-dn',
        'footer__container-links-mobile-container'
      )
    } else if (
      elLinkList.classList.contains('footer__container-links-mobile-container')
    ) {
      elLinkList.classList.replace(
        'footer__container-links-mobile-container',
        'mobile-dn'
      )
    }
  })
}

if (elModalWhatsapp) {
  const { optionsUnits } = elModalWhatsapp.dataset
  const units = JSON.parse(optionsUnits)

  ReactDOM.hydrate(
    <Provider store={store}>
      <ModalWhatsappApp optionsUnits={units} />
    </Provider>,
    elModalWhatsapp
  )
}

if (elModalContact) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <ModalContactApp />
    </Provider>,
    elModalContact
  )
}
