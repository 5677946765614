import styled from 'styled-components'
import { rem } from 'polished'

export const Select = styled.select`
  max-width: ${rem(300)};
  margin-bottom: ${rem(32)};
  background-color: #ffffff;
  border-color: #ced4da;
  box-shadow: none;

  @media screen and (max-width: 768px) {
    margin-bottom: ${rem(24)};
  }
`

export const Title = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: ${rem(36)};
  line-height: 120%;
  margin: ${rem(32)} 0;
`
