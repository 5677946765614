import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Dropzone from 'react-dropzone'
import { useEffect } from 'react'
import isMobile from 'is-mobile'

const UploadDropzone = ({
  title,
  inputName,
  imageName,
  imageUrl,
  maxSize = 15728640,
  onDropAccepted = () => {},
  onDropRejected = () => {},
  onDelete = () => {},
}) => {
  const [file, setFile] = useState(null)

  const handleDropAccepted = useCallback(acceptedFiles => {    
    const [acceptedFile] = acceptedFiles

    const customFile = {
      ...acceptedFile,
      thumb: URL.createObjectURL(acceptedFile)
    }

    setFile(customFile)

    onDropAccepted(customFile)
  }, [])

  const handleDeleteClick = useCallback(inputRef => (e) => {
    e.preventDefault()
    e.stopPropagation()
    
    URL.revokeObjectURL(file.thumb)
    
    setFile(null)

    if (inputRef) {
      inputRef.current.value = null
    }

    onDelete()
  }, [file])

  useEffect(() => {
    if (imageName) {
      let newFile = new File([imageUrl || imageName], imageName, {
        type: 'image/png'
      })
      setFile({
        ...newFile,
        thumb: imageUrl
      })
    }
  }, [imageName, imageUrl])

  useEffect(() => () => {
    if (file) {
      URL.revokeObjectURL(file.thumb)
    }
  }, [file])

  return (
    <Dropzone
      onDropAccepted={handleDropAccepted}
      onDropRejected={onDropRejected}
      accept='image/*,application/pdf'
      multiple={false}
      maxSize={maxSize}
    >
      {({
        getRootProps,
        getInputProps,
        inputRef
      }) => (
        <div {...getRootProps({
          className: clsx('upload-dropzone', {
            'upload-dropzone--with-thumb': file && file.thumb,
            'upload-dropzone--desktop': !isMobile(),
          })
        })}>
          <input
            {...getInputProps({
              name: inputName,
            })}
            data-testid="inputFile"
          />
          {file ? 
            <>
              <img src={file.thumb} className="upload-dropzone__thumb" data-testid="thumb" />
              <a
                href="#"
                className="upload-dropzone__delete"
                onClick={handleDeleteClick(inputRef)}
              >
                <i className="icon icon-trash-alt-regular" /> Excluir
              </a>
            </>
          :
            <>
              <div className="upload-dropzone__plus">+</div>
              <div className="upload-dropzone__title">{title}</div>
            </>
          }
        </div>
      )}
    </Dropzone>
  )
}

UploadDropzone.propTypes = {
  title: PropTypes.string,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onDelete: PropTypes.func,

  /**
   * Tamanho máximo permitido no upload
   */
  maxSize: PropTypes.number,
}

export default UploadDropzone
