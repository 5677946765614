import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { applyCepMask } from '../../utils/applyMasks'
import { addReceivementAddress } from '../../service/api'

const ReceivementAddress = (props, saveAddressButton) => {
  const [customer, setCustomer] = useState(props.customer)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const addAddressRef = useRef()

  const addressErrorContainers = useRef()

  const onAddAddressClick = (e) => {
    props.onAddAddressClick(e)

    const addAddressButton = addAddressRef.current

    if (
      addAddressButton.classList.contains(
        'receive-vehicle__btn-add-address--open'
      )
    )
      addAddressButton.classList.remove(
        'receive-vehicle__btn-add-address--open'
      )
    else
      addAddressButton.classList.add('receive-vehicle__btn-add-address--open')
  }

  const updateCustomerAddress = debounce(() => {
    const form = document.querySelector('.receive-vehicle__form-my-data')
    const formData = new FormData(form)

    setLoading(true)

    addReceivementAddress(form.action, formData)
      .then((data) => {
        setCustomer(data)
        setErrors({})

        const elContainerForm = document.querySelector(
          '.receive-vehicle__box-form'
        )
        elContainerForm.classList.add('d-none')

        window.location = '#receive-form'
      })
      .catch((error) => {
        setErrors(error.response.data)
      })
      .finally(() => {
        setLoading(false)
        addAddressRef.current.classList.remove(
          'receive-vehicle__btn-add-address--open'
        )
      })
  }, 400)

  useEffect(() => {
    saveAddressButton.addEventListener('click', (_) => {
      updateCustomerAddress()
    })
  }, [])

  useEffect(() => {
    addressErrorContainers.current = {
      zip_code: document.getElementById('invalid-cep'),
      street: document.getElementById('invalid-street'),
      number: document.getElementById('invalid-number'),
      neighborhood: document.getElementById('invalid-neighborhood'),
      city: document.getElementById('invalid-city'),
      state: document.getElementById('invalid-state'),
    }

    applyCepMask(addressErrorContainers.current.zip_code.parentElement)
  }, [])

  const firstEffectFlag = useRef(true)

  useEffect(() => {
    // evita que efeito rode na primeira execução, deixando que errors renderizados pelo servidor permaneçam
    if (firstEffectFlag.current) {
      firstEffectFlag.current = false
      return
    }

    const errorContainers = addressErrorContainers.current

    Object.entries(errorContainers).map((entry) => (entry[1].innerHTML = ''))

    errors.zip_code
      ? (errorContainers.zip_code.innerHTML = errors.zip_code[0])
      : ''
    errors.street ? (errorContainers.street.innerHTML = errors.street[0]) : ''
    errors.number ? (errorContainers.number.innerHTML = errors.number[0]) : ''
    errors.neighborhood
      ? (errorContainers.neighborhood.innerHTML = errors.neighborhood[0])
      : ''
    errors.city ? (errorContainers.city.innerHTML = errors.city[0]) : ''
    errors.state ? (errorContainers.state.innerHTML = errors.state[0]) : ''
  }, [errors])

  // TODO: criar um indicador de salvamento mais expressivo
  // é apenas uma garantia caso o servidor demore muito a responder, para dar algum feedback ao usuário
  useEffect(() => {
    if (loading) saveAddressButton.innerText = 'Salvando...'
    else saveAddressButton.innerText = 'Salvar'
  }, [loading])

  return (
    <div className="receive-vehicle__address-container">
      <div className="receive-vehicle__address">
        {customer.street ? (
          <>
            <div className="receive-vehicle__address-title">
              {customer.street}, nº {customer.number}{' '}
              {customer.complement && `, ${customer.complement}`}
            </div>
            <div className="receive-vehicle__address-desc">
              {customer.city}, {customer.state}
              {customer.zip_code && (
                <>
                  <br />
                  {customer.zip_code}
                </>
              )}
            </div>
          </>
        ) : (
          'Sem endereço cadastrado'
        )}
        <div className="receive-vehicle__cta-address">
          <button
            type="button"
            className="receive-vehicle__btn-add-address"
            ref={addAddressRef}
            onClick={onAddAddressClick}
          >
            {customer.street ? 'Editar endereço' : 'Adicionar endereço'}
          </button>

          <div className="receive-vehicle__warning receive-vehicle__warning--desktop d-none d-xl-block">
            <strong>Atenção: </strong>
            As entregas em endereços fora da cidade de nossa loja poderão ter um
            custo adicional.
          </div>

          <div className="receive-vehicle__warning d-xl-none">
            <strong>Atenção: </strong>
            As entregas em endereços fora da cidade de nossa loja poderão ter um
            custo adicional.
          </div>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(ReceivementAddress)
