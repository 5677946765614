import styled from 'styled-components'
import { TextInput, Button } from 'autocommerce-sb'
import { rem } from 'polished'

import { ContainerBase } from '../../styled'

export const Container = styled(ContainerBase)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(32)} 0;
`

export const Title = styled.h1`
  font-family: Roboto;
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: ${rem(48)};
  @media (max-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
`

export const WarningText = styled.span`
  width: ${rem(270)};
  font-family: Roboto;
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(21)};
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray-5);
`

export const StyledIcon = styled.i`
  font-size: ${rem(22)};
  color: var(--primary);
  margin-right: ${rem(18)};
`

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${rem(48)};
`
export const SubTitle = styled.h3`
  font-family: Roboto;
  font-size: ${rem(20)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: ${rem(48)};
  @media (max-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
`
