import React from 'react'
import PropTypes from 'prop-types'

const CreditAnalysisInfoUsedVehicle = (props) => {
  return (
    <div className="financing-requests__info-item">
      <div className="financing-requests__info-item-label">Entrada (Seminovo na troca):</div>
      <div className="financing-requests__info-item-value">
        <i className="icon icon-exclamation-triangle-solid"></i>{props.used_vehicle_value}
      </div>
    </div>
  )
}

CreditAnalysisInfoUsedVehicle.propTypes = {
  used_vehicle_value: PropTypes.string.isRequired
}

export default CreditAnalysisInfoUsedVehicle