import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { ModalBody, IconContainer, SubTitle, Title } from './styled'
import { PrimaryButton } from '../Buttons'
import { Info } from 'react-feather'
import { setCookie } from '../../utils/cookie'

export const ModalVehicle = () => {
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setShow(false)
    setCookie({
      name: 'accept_modal_vehicle',
      value: true,
      expirationDays: 7,
    })
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0" />

      <ModalBody>
        <IconContainer>
          <Info size={34} />
        </IconContainer>

        <Title>Atenção</Title>
        <SubTitle>
          Não podemos faturar veículos novos para endereços fora da nossa região
          operacional.
        </SubTitle>

        <PrimaryButton onClick={handleClose}>Entendi</PrimaryButton>
      </ModalBody>
    </Modal>
  )
}
