import styled from 'styled-components'
import { rem } from 'polished'

export const Modal = styled.div`
  position: fixed;
  background-color: var(--white);
  border-radius: ${rem(24)} ${rem(24)} 0 0;
  padding: ${rem(32)} ${rem(32)} ${rem(48)} ${rem(32)};
  z-index: 1000;
  top: 100%;
  left: 0;
  right: 0;
  transition: transform 500ms ease;
  transform: translate(0, ${({ isOpen }) => (isOpen ? '-100%' : '0')});
  max-height: 60vh;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(22)};
`

export const Title = styled.span`
  font-family: Roboto;
  font-size: ${rem(18)};
  color: ${({ titleColor }) => titleColor};
  font-weight: bold;
`

export const Description = styled.p`
  font-family: Roboto;
  font-size: ${rem(14)};
  color: var(--light-grey);
  line-height: 150%;
`

export const Overlay = styled.div`
  position: fixed;
  background-color: #000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.32;
  z-index: 1000;
`
