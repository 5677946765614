import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Navbar from 'react-bootstrap/Navbar'
import UserDropDown from '../UserDropDown'
import { MobileMenu } from './MobileMenu'
import { SocialLinks } from './SocialLinks'
import SearchInput from './SearchInput'
import menu from '../../../../assets/images/menu.png'
import { BottomSheetSelectState } from '../BottomSheetSelectState'
import { states as statesNames } from '../../utils/states'
import { SelectStateHeader } from '../SelectStateHeader'
import { ModalSelectState } from '../ModalSelectState'
import isMobile from 'is-mobile'
import { getCookie, setCookie } from '../../utils/cookie'
import initLazyLoading from '../../utils/lazyLoading'

const NavbarApp = ({
  logoPath,
  showDropdownWhatsapp,
  unitsPhone,
  unitsWhatsapp,
  socialLinks,
  customer_signed_in,
  current_customer_name,
  buy_your_vehicle,
  states_and_cities_for_dealer,
  url_state,
  show_modal_state_page,
  content_by_location,
  current_url_state_path,
}) => {
  const openMobileMenuRef = useRef(null)

  const hasSocialLinks = () => socialLinks.length > 0

  const [showNavbar, setShowNavbar] = useState(false)

  const [showStatesBottomSheet, setShowStatesBottomSheet] = useState(false)
  const [showStatesModal, setShowStatesModal] = useState(false)

  const [showNavbarMobile, setShowNavbarMobile] = useState(false)

  const closeMobileMenu = () => {
    setShowNavbarMobile(false)
  }

  const openMobileMenu = () => {
    setShowNavbarMobile(true)
  }

  const handleNavbarToggle = (isOpen) => {
    setShowNavbar(isOpen)
  }

  const stateOptions = () => {
    return Object.keys(states_and_cities_for_dealer).map((state) => ({
      label: statesNames[state],
      value: state,
    }))
  }

  const handleSelectState = () => {
    const isMobileResolution = window.innerWidth < 768

    if (isMobile() || isMobileResolution) {
      setShowStatesBottomSheet(true)
      return
    }

    setShowStatesModal(true)
  }

  const modalStatesCookieName = 'acm_modal_select_state_already_showed'
  const modal_states_already_showed = getCookie(modalStatesCookieName)

  const showModalSelectState =
    show_modal_state_page &&
    content_by_location &&
    !modal_states_already_showed &&
    !url_state

  useEffect(() => {
    initLazyLoading()

    if (showModalSelectState) {
      setCookie({
        name: modalStatesCookieName,
        expirationDays: 100,
        value: true,
      })

      setTimeout(() => handleSelectState(), 1500)
    }
  }, [])

  const justifyHeaderContent = content_by_location
    ? 'justify-content-between'
    : 'justify-content-end'

  const hasStatePrefix = !!url_state ? `/${url_state}` : ''

  const homePath = hasStatePrefix + (!!hasStatePrefix ? '' : '/')
  const advancedSearchPath = hasStatePrefix + '/veiculos'
  const buyYourVehiclePath = hasStatePrefix + '/compramos-seu-usado'
  const aboutUsPath = hasStatePrefix + '/quem-somos'
  const contactUsPath = hasStatePrefix + '/empresa-fale-conosco'

  // Este código só é necessário enquanto as rotas do
  // fluxo de avaliação não suportam o parâmetro de estado
  url_state = keep_url_state_avaliation_flow(url_state)

  return (
    <>
      <header className="bg-primary">
        <div
          className={`menu-header d-flex align-items-center ${justifyHeaderContent}`}
        >
          {content_by_location && (
            <SelectStateHeader onClick={handleSelectState} state={url_state} />
          )}

          <div>
            <a className="d-xl-none text-white mr-3" href={advancedSearchPath}>
              Quero comprar
            </a>
            {buy_your_vehicle && (
              <a className="d-xl-none text-white" href={buyYourVehiclePath}>
                Quero vender
              </a>
            )}
            <a
              className="d-none d-xl-inline text-white mr-5"
              href={aboutUsPath}
            >
              Quem Somos
            </a>
            <a className="d-none d-xl-inline text-white" href={contactUsPath}>
              Fale Conosco
            </a>
          </div>
        </div>
      </header>

      <div
        className={clsx('navbar-header navbar-header--sticky ', {
          'navbar-header--expanded': showNavbar,
        })}
      >
        <Navbar
          expanded={showNavbar}
          className="navbar-header__navbar"
          expand="xl"
          onToggle={handleNavbarToggle}
        >
          <div className="container navbar-header__container">
            <div className="navbar-header__padding">
              <Navbar.Brand className="havbar-header__navbar-brand">
                <a href={homePath}>
                  <img
                    src=""
                    data-src={logoPath}
                    className="navbar-header__logo img-lazy"
                    alt="logomarca"
                  />
                </a>
              </Navbar.Brand>
            </div>

            <SearchInput
              isMobile={false}
              advancedSearchPath={advancedSearchPath}
            />

            <a
              className="d-none d-xl-flex navbar-header__nav-link"
              href={advancedSearchPath}
              data-cy="quero-comprar-header-link"
            >
              Quero Comprar
            </a>

            {buy_your_vehicle && (
              <a
                className="d-none d-xl-flex navbar-header__nav-link"
                href={buyYourVehiclePath}
              >
                Quero Vender
              </a>
            )}

            <SocialLinks
              unitsPhone={unitsPhone}
              unitsWhatsapp={unitsWhatsapp}
              setShowNavbar={setShowNavbar}
              closeButton={openMobileMenuRef}
            />

            <span
              onClick={() => openMobileMenu()}
              className="navbar-header__navbar-toggler"
              ref={openMobileMenuRef}
            >
              <img src={menu} />
            </span>

            <MobileMenu
              advancedSearchPath={advancedSearchPath}
              customer_signed_in={customer_signed_in}
              closeMobileMenu={closeMobileMenu}
              hasSocialLinks={hasSocialLinks()}
              logoPath={logoPath}
              show={showNavbarMobile}
              buy_your_vehicle={buy_your_vehicle}
              showDropdownWhatsapp={showDropdownWhatsapp}
              // RenderedSocialLinks={RenderedSocialLinks}
              unitsPhone={unitsPhone}
              unitsWhatsapp={unitsWhatsapp}
              setShowNavbar={setShowNavbar}
            />

            <div className="navbar-header__user d-none d-xl-flex align-items-center">
              {!customer_signed_in ? (
                <a
                  href="/customers/sign_in"
                  className="d-flex justify-content-between align-items-center font-weight-bold text-dark"
                >
                  <i icon-name="user" className="d-inline" />
                  <span className="mx-2">Entrar</span>
                </a>
              ) : (
                <UserDropDown
                  currentCustomerName={current_customer_name}
                  url_state={url_state}
                />
              )}
            </div>
          </div>
        </Navbar>

        <BottomSheetSelectState
          isOpen={showStatesBottomSheet}
          onClose={() => setShowStatesBottomSheet(false)}
          options={stateOptions()}
          currentState={url_state}
          currentUrlStatePath={current_url_state_path}
        />

        <ModalSelectState
          show={showStatesModal}
          onHide={() => setShowStatesModal(false)}
          options={stateOptions()}
          currentState={url_state}
          currentUrlStatePath={current_url_state_path}
        />
      </div>
    </>
  )
}

// start - este código só é necessário enquanto as rotas do fluxo de avaliação
// não suportam o parâmetro de estado
const keep_url_state_avaliation_flow = (url_state) => {
  const must_set_cookie =
    window.location.pathname.includes('compramos-seu-usado') &&
    !getCookie('last_url_state') &&
    !!url_state

  const must_return_cookie =
    window.location.pathname.includes('compramos-seu-usado') &&
    !!getCookie('last_url_state')

  if (must_set_cookie) {
    setCookie({
      name: 'last_url_state',
      value: url_state,
      expirationDays: 7,
    })
  } else if (must_return_cookie) {
    return getCookie('last_url_state')
  }

  return url_state
}
// end

NavbarApp.defaultProps = {
  onDropdownPhoneToggle() {},
  onDropdownWhatsappToggle() {},
  onNavbarToggle() {},
  onNavItemWhatsappClick() {},
  showNavbar: false,
  showDropdownPhone: false,
  showDropdownWhatsapp: false,
  unitsPhone: [],
  unitsWhatsapp: [],
  socialLinks: [],
  customer_signed_in: false,
  current_customer_name: '',
  buy_your_vehicle: false,
  advancedSearchPath: '/veiculos',
}

NavbarApp.proptypes = {
  onDropdownPhoneToggle: PropTypes.func.isRequired,
  onDropdownWhatsappToggle: PropTypes.func.isRequired,
  onNavbarToggle: PropTypes.func.isRequired,
  onNavItemWhatsappClick: PropTypes.func,
  /**
   * Url do logo
   */
  logoPath: PropTypes.string.isRequired,

  /**
   * Prop de Navbar
   */
  showNavbar: PropTypes.bool,
  showDropdownPhone: PropTypes.bool,
  showDropdownWhatsapp: PropTypes.bool,
  unitsPhone: PropTypes.array,
  unitsWhatsapp: PropTypes.array,
  socialLinks: PropTypes.array,
  advancedSearchPath: PropTypes.string,

  /* Usúario logado? */
  customer_signed_in: PropTypes.array,
  current_customer_name: PropTypes.string,
}

export default (props) => <NavbarApp {...props} />
