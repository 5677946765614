export default function reducer(state = { errorOnAnalysis: false, loadingAnalysis: true }, action = {}) {
  switch (action.type) {
    case "CREDIT_ANALYSIS/REJECTED":
      return {
        ...state,
        loadingAnalysis: false,
        errorOnAnalysis: true
      }

    case "CREDIT_ANALYSIS/APPROVED":
      return {
        ...state,
        loadingAnalysis: false,
        errorOnAnalysis: false
      }
    
    default:
      return state
  }
}