import React from 'react'
import { useDispatch } from 'react-redux'
import { setOpenModalWhatsapp } from '../../store/ducks/modal-whatsapp'

const WhatsAppButtonBuyUsed = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(setOpenModalWhatsapp(true))
  }

  return (
    <button
      id="evaluation__whatsapp-button"
      className="btn w-100 p-2 rounded-pill bg-whatsapp text-white font-weight-bold d-flex align-items-center justify-content-center"
      onClick={handleClick}
    >
      <i className="icon icon-whatsapp mr-3"></i> Enviar mensagem
    </button>
  )
}

export default WhatsAppButtonBuyUsed
