import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import {
  ModalBody,
  IconContainer,
  SelectContainer,
  SubTitle,
  Title,
  customSelectStyle,
} from './styled'
import { PrimaryButton } from '../Buttons'
import { MapPin } from 'react-feather'
import CustomSelect from '../../admin/components/CustomSelect'
import { setCookie } from '../../utils/cookie'

export const ModalSelectState = ({
  show,
  onHide,
  options,
  currentState = null,
  currentUrlStatePath = null,
}) => {
  const [selectedState, setSelectedState] = useState(currentState)

  const handleSelect = (selectedOption) => {
    setSelectedState(selectedOption.value)
  }

  const formatedPath = (value) =>
    currentUrlStatePath.replace('url_state_param', value)

  const handleSubmit = () => {
    // start - este código só é necessário enquanto as rotas do fluxo de avaliação
    // não suportam o parâmetro de estado
    const must_set_cookie = window.location.pathname.includes(
      'compramos-seu-usado'
    )
    const must_redirect_with_cookie = window.location.pathname.match(
      /compramos-seu-usado\/[a-z]/
    )

    if (must_set_cookie) {
      setCookie({
        name: 'last_url_state',
        value: selectedState,
        expirationDays: 7,
      })
    }

    if (must_redirect_with_cookie) {
      return window.location.assign(window.location.pathname)
    }
    // end

    window.location.assign(formatedPath(selectedState))
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton className="border-0" />

      <ModalBody>
        <IconContainer>
          <MapPin size={34} />
        </IconContainer>

        <Title>Qual o seu estado?</Title>
        <SubTitle>
          Para oferecermos resultados mais próximos de você, preencha sua
          localização abaixo.
        </SubTitle>

        <SelectContainer>
          <label htmlFor="select_state">
            <strong>Selecione sua localização</strong>
          </label>
          <CustomSelect
            id="select_state"
            placeholder="Clique para selecionar"
            options={options}
            value={selectedState}
            onChange={handleSelect}
            styles={customSelectStyle}
          />
        </SelectContainer>

        <PrimaryButton disabled={!selectedState} onClick={handleSubmit}>
          Buscar loja mais próxima
        </PrimaryButton>
      </ModalBody>
    </Modal>
  )
}
