import styled from 'styled-components'
import { rem } from 'polished'
import { SearchInput, Card } from 'autocommerce-sb'
import { ContainerBase } from '../../../../styled'

export const Container = styled(ContainerBase)`
  padding: ${rem(40)} 0;
`

export const Title = styled.h1`
  font-family: Roboto;
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: ${rem(48)};
  @media (max-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
`

export const StyledCard = styled(Card)`
  margin-bottom: ${rem(16)};
`

export const StyledSearch = styled(SearchInput)`
  margin-bottom: ${rem(24)};
`
