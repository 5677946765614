import React, { useState, useEffect, useRef } from 'react'
import CustomCheck from '../CustomCheck'

const installments = [12, 24, 36, 48, 60]

const SimpleInstallments = () => {
  const [selectedInstallment, setSelectedInstallment] = useState(null)

  const isFirstRender = useRef(true)

  const handleSelectInstallments = (installment) => {
    const installmentValue = installment.target.value
    setSelectedInstallment(installmentValue)
    const elInputInstallments = document.getElementById(
      'financing_simulation_installments'
    )
    if (installment) {
      elInputInstallments.value = installmentValue
    } else {
      elInputInstallments.value = null
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      const elInputBank = document.getElementById('financing_simulation_bank')
      elInputBank.value = 'Fandi'
      isFirstRender.current = false
    }
  })

  return (
    <div>
      <h4 className="financing-installments__title">
        Em quantas parcelas você quer dividir?
      </h4>
      <div className="financing-installments__simple-container">
        {installments.map((installmentNumber) => {
          return (
            <div className="financing-installments__simple-box">
              <CustomCheck
                key={installmentNumber}
                type="checkbox"
                onChange={handleSelectInstallments}
                value={installmentNumber}
                checked={installmentNumber == selectedInstallment}
              />
              <p>{installmentNumber} Parcelas</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SimpleInstallments
