import validationFactory from '../utils/validation'

(() => {
  const passwordResetFormSelector = '.password-reset__form'
  const passwordResetForm = document.querySelector(passwordResetFormSelector)
  if (!passwordResetForm) return

  const validator = validationFactory(passwordResetFormSelector)

  passwordResetForm.addEventListener('submit', (e) => {
    const form = e.target
    const formIsValid = validator.validateAll(form).length === 0

    form.classList.add('was-validated')

    if (!formIsValid) {
      e.preventDefault()
      return
    }

    form.submit()

  }, false)
})()