import React from 'react'
import { render } from 'react-dom'
import { applyMoneyMask, applyPlateMask } from '../utils/applyMasks'
import validationFactory from '../utils/validation'
import UploadDropzone from '../components/UploadDropzone'
import Select from 'react-select'
import UsedVehiclePricing from '../components/UsedVehiclePricing'

(() => {
  const elSalesContainer = document.querySelector('.sales__used-evaluation-container')
  if (!elSalesContainer) return

  const validator = validationFactory('.sales__used-evaluation-form');

  // Renderização do seletor de versão do veículo
  (() => {
    const vehiclePricingContainer = document.getElementById('used-vehicle-pricing')
    if (!vehiclePricingContainer) return

    const domData = document.querySelector('[data-dom-id="used-vehicle-pricing"]')
    const props = JSON.parse(domData.innerHTML)

    render(
      <UsedVehiclePricing {...props} />,
      vehiclePricingContainer
    )
  })()

  /**
   * Renderiza componentes de upload
   */
  function renderUploads() {
    const elUploadPhotoFront = document.getElementById('sales__upload-photo-front')
    const elUploadPhotoBack = document.getElementById('sales__upload-photo-back')
    const elUploadPhotoPanel = document.getElementById('sales__upload-photo-panel')
    const elUploadPhotoSideRight = document.getElementById('sales__upload-photo-side-right')
    const elUploadPhotoSideLeft = document.getElementById('sales__upload-photo-side-left')
    const elUploadPhotoDriveView = document.getElementById('sales__upload-photo-drive-view')

    const uploads = [
      { title: 'Frente', element: elUploadPhotoFront },
      { title: 'Traseira', element: elUploadPhotoBack },
      { title: 'Painel', element: elUploadPhotoPanel },
      { title: 'Lateral direita', element: elUploadPhotoSideRight },
      { title: 'Lateral esquerda', element: elUploadPhotoSideLeft },
      { title: 'Interna motorista', element: elUploadPhotoDriveView }
    ]

    uploads.forEach(({ title, element }) => {
      if (element) {
        const { inputName, imageName, imageUrl } = element.dataset

        render(
          <UploadDropzone
            title={title}
            inputName={inputName}
            imageName={imageName}
            imageUrl={imageUrl}
          />,
          element
        )
      }
    })
  }

  /**
   * Manipulador para mundança de check "usado possui saldo de empréstimo pendente?"
   */
  function handleCheckDebtChange() {
    const el = document.getElementById('used_vehicle_debt')
    el.addEventListener('change', e => {
      const { checked } = e.target
      const elInput = document.getElementById('used_vehicle_balance_due')
      elInput.disabled = !checked
    })
  }

  /**
   * Validação do form
   */
  function handleValidationForm() {
    const elForm = document.querySelector('.sales__used-evaluation-form')

    elForm.addEventListener('submit', (e) => {
      const formIsValid = validator.validateAll(elForm).length === 0

      if (!formIsValid) {
        e.preventDefault()
        return
      }

      // TODO: remover código abaixo após corrigir envio de valores com ponto(.) no formato R$ 1.000,00
      const elInputBalanceDue = document.getElementById('used_vehicle_balance_due')

      if (elInputBalanceDue) {
        elInputBalanceDue.value = elInputBalanceDue.value.replace(/\./, '')
      }
      // END TODO

      elForm.submit()
    })
  }

  renderUploads()

  handleValidationForm()
  handleCheckDebtChange()

  applyMoneyMask(elSalesContainer)
  applyPlateMask(elSalesContainer)
})()