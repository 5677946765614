import React, { useRef, useEffect, forwardRef } from 'react'
import IMask from 'imask'
import { phoneMasks } from '../../utils/masks'

const InputPhone =  (props, ref) => {
  const el = useRef(null)

  useEffect(() => {
    IMask(el.current, {
      mask: phoneMasks
    })
  })

  return (
    <input
      {...props}
      ref={node => {
        el.current = node
        ref && ref(node)
      }}      
    />
  )
}

export default forwardRef(InputPhone)
