import styled, { keyframes } from 'styled-components'
import { rem } from 'polished'

const grow = keyframes`
    from {
        height: ${rem(0)};
        width: ${rem(0)};
        box-shadow:
            0 0 0 1px var(--primary),
            0 0 0 3px var(--primary),
            0 0 0 4px var(--primary);
    }
    to {
        height: ${rem(120)};
        width: ${rem(120)};
        transform: translate(-60px, -60px);
        box-shadow:
            0 0 0 1px var(--primary),
            0 0 0 3px #FFF,
            0 0 0 4px var(--primary);
    }
`

const fade = keyframes`
    0% {
        opacity: .3;
        top: 57vh;
    }
    5% {
        opacity: .3;
        top: 55vh;
    }
    33% {
        opacity: .3;
        top: 55vh;
    }
    38% {
        opacity: 1;
        top: 50vh;
    }
    66% {
        opacity: 1;
        top: 50vh;
    }
    71% {
        opacity: .3;
        top: 50vh;
    }
    99% {
        opacity: .3;
        top: 50vh
    }
`

const fadeRightAway = keyframes`
    0% {
        opacity: .3;
        top: 55vh;
    }
    5% {
        opacity: 1;
        top: 50vh;
    }
    33% {
        opacity: 1;
        top: 50vh;
    }
    38% {
        opacity: 0;
        top: 55vh;
    }
    66% {
        opacity: 0;
        top: 55vh;
    }
    67% {
        opacity: 0;
        top: 53vh;
    }
    71% {
        opacity: 1;
        top: 53vh;
    }
    99% {
        opacity: 1;
        top: 53vh;
    }
`

export const Overlay = styled.div`
  display: ${(props) => (props.loading ? 'block' : 'none')};
  width: 100%;
  height: 60vh;
  transform: translate(0, -100px);

  .circle {
    position: absolute;
    top: 30vh;
    left: 50%;
    border-radius: 50%;
    animation: ${grow} 1s infinite;
    animation-timing-function: ease-out;
  }

  .delayCircle {
    animation-delay: 0.5s;
  }

  .loadingText {
    position: absolute;
    opacity: 0;
    font-family: Poppins;
    font-size: ${rem(24)};
    font-weight: 700;
    line-height: ${rem(36)};
    letter-spacing: 0em;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
    @media (max-width: ${rem(600)}) {
      font-size: ${rem(12)};
    }
  }

  .one {
    animation: ${fadeRightAway} 5s infinite;
  }

  .two {
    animation: ${fade} 5s infinite;
  }
`
