import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Button, Card } from 'autocommerce-sb'
import { useHistory } from 'react-router-dom'

import useSmallWindow from '../../hooks/useSmallWindow'
import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
  clearAvaliationFlowForm,
  setCustomQuestions,
} from '../../../../store/ducks/avaliation-flow'
import {
  Container,
  Title,
  StyledIcon,
  WarningText,
  StyledTextInput,
  SubTitle,
} from './styled'
import { goTop } from '../../actions'
import { fetchPlaces } from '../SelectPlace/action'
import { fetchCustomQuestions } from '../CustomQuestions/actions'

const SelectKilometer = ({ formValues, allowShielded }) => {
  const dispatch = useDispatch()
  const smallWindow = useSmallWindow(600)
  const history = useHistory()
  const formRef = useRef(null)

  useEffect(() => {
    goTop
    dispatch(setAvaliationFlowStep(4))
    dispatch(clearAvaliationFlowForm(['km']))
  }, [])

  const [error, setError] = useState('')

  const [selectedShield, setSelectedShield] = useState(0)

  const handleSubmit = (e) => {
    e.preventDefault()
    const kilometers = formRef.current.elements['km'].value.replace(
      /[^\d]/g,
      ''
    )
    if (kilometers == '') {
      setError('Este campo não pode ficar vazio!')
      return
    }

    dispatch(setAvaliationFlowFormValues(parseInt(kilometers), 'km'))
    if (selectedShield !== 0) {
      dispatch(setAvaliationFlowFormValues(selectedShield == 1, 'shielded?'))
    }

    ;(async () => {
      const customQuestions = await fetchCustomQuestions()
      if (customQuestions.length) {
        dispatch(setCustomQuestions(customQuestions))
        history.push({
          pathname: '/compramos-seu-usado/informacoes-do-veiculo',
        })
        return
      }
      if (formValues.city_name && formValues.state_inspection) {
        const stores = await fetchPlaces(
          formValues.state_inspection,
          formValues.city_name
        )
        if (stores.length === 1) {
          dispatch(setAvaliationFlowFormValues(stores[0].store_id, 'store_id'))
          dispatch(
            setAvaliationFlowFormValues(stores[0].formated_address, 'address')
          )
          history.push({
            pathname: '/compramos-seu-usado/dados-pessoais',
            search: `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`,
          })
        } else {
          history.push({
            pathname: '/compramos-seu-usado/local-de-inspecao',
            search: `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`,
          })
        }
      } else {
        history.push({
          pathname: '/compramos-seu-usado/estado-de-inspecao',
          search: '',
        })
      }
    })()
  }

  return (
    <Container>
      <Title>Qual a quilometragem atual do seu veículo?</Title>
      <form onSubmit={handleSubmit} ref={formRef}>
        <StyledTextInput
          label="Quilometragem atual (Em km)"
          width={smallWindow ? 312 : 384}
          placeholder="Ex: 5.000"
          error={error}
          onChange={() => setError('')}
          inputConfig={{ name: 'km' }}
          mask={[
            {
              mask: Number,
              thousandsSeparator: '.',
            },
          ]}
          useUnmaskedValue
        />
        {allowShielded ? (
          <div className="d-flex flex-column align-items-center">
            <SubTitle>O seu veículo é blindado?</SubTitle>
            <div
              className="d-flex justify-content-between"
              style={{ width: '100%', marginBottom: '48px' }}
            >
              <Card
                title="Sim"
                width={smallWindow ? 148 : 176}
                padding="20px 0"
                selected={selectedShield == 1}
                onClick={() => setSelectedShield(1)}
                centerContent
              />
              <Card
                title="Não"
                width={smallWindow ? 148 : 176}
                padding="20px 0"
                selected={selectedShield == 2}
                onClick={() => setSelectedShield(2)}
                centerContent
              />
            </div>
          </div>
        ) : null}
        <Button
          label="Continuar"
          type="submit"
          width={smallWindow ? 312 : 384}
        />
      </form>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ margin: '50px auto 48px auto' }}
      >
        <StyledIcon className="icon icon-info-grid"></StyledIcon>
        <WarningText>
          Não se preocupe, usamos esses dados exclusivamente para precificar seu
          veículo
        </WarningText>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
  allowShielded: state.avaliationFlow.allowShielded,
})

export default connect(mapStateToProps)(SelectKilometer)
