import React from 'react'
import clsx from 'clsx'
import NavbarSearchInput from '../NavbarSearchInput'

const SearchInput = (props) => {
  const { isMobile, advancedSearchPath } = props

  return (
    <div
      className={clsx('navbar-header__search-input', {
        'd-block d-md-none mb-4': isMobile,
        'd-none d-md-flex align-items-center': !isMobile,
      })}
    >
      <form
        action={advancedSearchPath}
        acceptCharset="UTF-8"
        method="get"
        className={clsx('', { 'w-100': !isMobile })}
      >
        <NavbarSearchInput buttonProps={{ type: 'submit' }} inputProps={{}} />
      </form>
    </div>
  )
}

SearchInput.defaultProps = {
  isMobile: true,
}

export default (props) => <SearchInput {...props} />
