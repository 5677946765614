import React from 'react'

const AccordionUnitsBody = ({phone, onNavItemClick, Icon}) => {
  if (onNavItemClick) {
    return (
      <div className="navbar-header__accordion-item" onClick={() => onNavItemClick(phone)}>
        <div>
          <div className="navbar-header__unit-name">{phone.name}</div>
          <div className="navbar-header__unit-phone">{phone.phone}</div>
        </div>
        {Icon && Icon}
      </div>
    )
  }

  const phoneOnlyNumbers = phone.phone.replace(/\D/g, '')
  const phoneWithCountryCode = `+55${phoneOnlyNumbers}`

  return (
    <a href={`tel:${phoneWithCountryCode}`}>
      <div className="navbar-header__accordion-item">
        <div>
          <div className="navbar-header__unit-name">{phone.name}</div>
          <div className="navbar-header__unit-phone">{phone.phone}</div>
        </div>
        {Icon && Icon}
      </div>
    </a>
  )
}

export default AccordionUnitsBody