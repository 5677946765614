import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import InstallmentItem from './InstallmentItem'
import ErrorApi from './ErrorApi'
import Loading from './Loading'

const InstallmentList = ({
  banks = [],
  activeBank,
  installments = [],
  checkedInstallment = {},
  errorsInstallments = false,
  errorApi = false,
  loading = false,
  onBankClick = () => {},
  onCheckChange = () => {},
  onDetailsClick = () => {},
}) => {

  const content = () => {
    if (loading) {
      return <Loading />
    }

    if (errorApi) {
      return <ErrorApi />
    }

    return (
      <>
        <div className="financing-installments__list-body">
          {installments.map(installment => (
            <InstallmentItem
              key={installment.times}
              className={clsx({
                'financing-installments__installment-item--error': errorsInstallments
              })}
              times={installment.times}
              value={installment.value}
              checked={checkedInstallment === installment.times}
              onChange={onCheckChange}
              onDetailsClick={onDetailsClick}
            />
          ))}
        </div>
        { errorsInstallments && <div className="financing-installments__label-error">Você deve escolher uma opção de parcelamento.</div>}
      </>
    )
  }

  return (
    <div className="financing-installments__list">
      <header className="financing-installments__list-header">
        <h4 className="financing-installments__title financing-installments__title--plots">Parcelas</h4>
      </header>
      { content() }
    </div>
  )
}

InstallmentList.propTypes = {
  activeBank: PropTypes.string,
  banks: PropTypes.array,
  checkedInstallment: PropTypes.number,
}

export default InstallmentList
