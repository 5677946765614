import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalFormActiveBestPrice from '../ModalFormActiveBestPrice'
import { useSelector, useDispatch } from 'react-redux'
import { setOpenModalActiveBestPrice } from '../../store/ducks/modal-active-best-price'
import { convert } from '../../service/api'

const ModalFormActiveBestPriceApp = (props) => {
  const show = useSelector(state => state.modalActiveBestPrice.open)
  const dispatch = useDispatch()

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)


  const handleHide = () => {
    dispatch(setOpenModalActiveBestPrice(false))
  }

  const handleSubmit = async (data) => {
    setIsSubmiting(true)

    try {
      await convert(data)
      props.onSubmitted(data)
    } catch (err) {
      console.error(err)
      setErrorSubmit(true)
    } finally {
      setIsSubmiting(false)
      handleHide()
    }
  }

  return (
    <ModalFormActiveBestPrice
      show={show}
      isSubmiting={isSubmiting}
      errorSubmit={errorSubmit}
      onHide={handleHide}
      onSubmit={handleSubmit}
      {...props}
    />
  )
}

ModalFormActiveBestPriceApp.defaultProps = {
  onSubmitted() {},
}

ModalFormActiveBestPriceApp.propTypes = {
  onSubmitted: PropTypes.func,
}

export default ModalFormActiveBestPriceApp
