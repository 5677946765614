/*
 * Esse arquivo contém configurações padrão de validação de campos.
 * Também está disponível uma factory para o validador que é o que deve ser
 * utilizado para gerar um novo.
 */

import Bouncer from 'formbouncerjs/dist/bouncer.polyfills'
import {
  isValid,
  isExpirationDateValid,
  isSecurityCodeValid,
} from 'creditcard.js'

import { validateCpf } from './cpf'

const defaultValidationOptions = {
  disableSubmit: true,
  fieldClass: 'is-invalid',
  customValidations: {
    oneOptionChecked: (field) => {
      /**
       * Defina data-one-option-checked="true" e data-group="contact_method" em inputs tipo check_box para usar esta validação
       * data-group será o grupo de inputs tipo check_box que estão no mesmo contexto. Ex: check_boxs de forma de contato, Email, Celular, WhatsApp
       */
      // defina data-one-option-checked="true" e data-group="group_name" em inputs tipo check_box para usar esta validação
      if (field.getAttribute('data-one-option-checked') !== 'true') return

      const checkedOption = document.querySelector(
        `[data-group=${field.getAttribute('data-group')}]:checked`
      )
      const alreadyHasError = document.querySelector(
        `${field.getAttribute('data-bouncer-target')} .error-message`
      )

      if (checkedOption === null && alreadyHasError) {
        return false
      }

      alreadyHasError && alreadyHasError.remove()
      return !checkedOption
    },
    phone(field) {
      if (field.getAttribute('type') === 'phone') {
        return !/^(\([0-9]{2}\))\s([0-9]{4,5})-([0-9]{4})$/.test(field.value)
      }

      return false
    },
    avatar(field) {
      if (field.getAttribute('type') === 'file' && field.files.length) {
        const image_type = field.files[0].type
        return !['image/png', 'image/jpeg', 'image/jpg'].includes(image_type)
      }

      return false
    },
    isCreditCardNumberInvalid(field) {
      return (
        field?.dataset?.type === 'credit_card_number' && !isValid(field.value)
      )
    },
    isCardOwnerNameInvalid(field) {
      return (
        field?.dataset?.type === 'credit_card_owner' &&
        !field?.value?.trim().includes(' ')
      )
    },
    isCreditCardExpirationDateInvalid(field) {
      if (field?.dataset?.type !== 'credit_card_expiration_date') return false
      const [month, year] = field?.value.split('/')
      return !isExpirationDateValid(month, year)
    },
    isCpfInvalid(field) {
      if (field?.dataset?.type !== 'credit_card_cpf') return false
      return !validateCpf(field?.value)
    },
    isSecurityCodeInvalid(field) {
      if (field?.dataset?.type !== 'credit_card_cvv') return false
      /** @type {HTMLInputElement|null} */
      const cardNumberField = document.querySelector(
        '[data-type="credit_card_number"]'
      )
      return !isSecurityCodeValid(cardNumberField?.value, field.value)
    },
    valueMismatch: function (field) {
      const selector = field.getAttribute('data-bouncer-match')
      if (!selector) return false

      const otherField = field.form.querySelector(selector)
      if (!otherField) return false

      const isInvalid = otherField.value !== field.value

      if (isInvalid) {
        field.setCustomValidity('Certifique-se de que os campos correspondem.')
      } else {
        field.setCustomValidity('')
      }

      return isInvalid
    },
  },
  messages: {
    oneOptionChecked: 'Selecione pelo menos uma das opções de contato.',
    isCreditCardNumberInvalid: 'Número do cartão inválido',
    isCardOwnerNameInvalid: 'Preencha com seu nome e sobrenome',
    isCreditCardExpirationDateInvalid: 'Validade do cartão inválida',
    isCpfInvalid: 'Cpf inválido',
    isSecurityCodeInvalid: 'Código de segurança inválido',
    phone: 'Por favor, informe um telefone válido',
    avatar: 'Por favor, envie uma imagem válida(JPG, PNG ou JPEG)',
    valueMismatch: function (field) {
      const customMessage = field.getAttribute('data-bouncer-mismatch-message')
      return customMessage
        ? customMessage
        : 'Certifique-se de que os campos correspondem.'
    },
    missingValue: {
      checkbox: (field) => {
        return (
          field.getAttribute('data-bouncer-mismatch-message') ||
          'Esse campo é obrigatório'
        )
      },
      radio: 'Por favor, selecione uma opção',
      select: 'Por favor, selecione uma opção',
      'select-multiple': 'Por favor, selecione pelo menos um valor',
      default: (field) => {
        return (
          field.getAttribute('data-bouncer-mismatch-message') ||
          'Por favor, preencha esse campo'
        )
      },
    },
    patternMismatch: {
      email: 'Por favor, informe um e-mail válido',
      url: 'Por favor, informe uma URL',
      number: 'Por favor, informe um número',
      color: 'Por favor, escolha uma cor com o seguinte formato: #rrggbb',
      date: 'Por favor, utilize uma data num formato válido',
      time: 'Por favor, utilize um formato de 24 horas',
      month: 'Por favor, utilize o formato YYYY-MM',
      default: 'Por favor, preencha com o formato indicado',
    },
    outOfRange: {
      over: 'Por favor, selecione uma opção que é menor que {max}',
      under: 'Por favor, selecione uma opção que é maior que {min}',
    },
    wrongLength: {
      over: 'O tamanho máximo do texto é {maxLength}. Atualmente você tem {length} caracteres.',
      under: 'Tamanho mínimo não é válido.',
    },
  },
}

/**
 * Factory de validação de formulários.
 * @param {string} formSelector Seletor do form a ser validado
 * @param {*} options As [opções do Bouncer](https://github.com/cferdinandi/bouncer#options-and-settings)
 * @returns {Bouncer}
 */
export default function validationFactory(formSelector, options = {}) {
  return new Bouncer(formSelector, { ...defaultValidationOptions, ...options })
}
