import React, { useEffect, useState } from 'react'

const VehicleLocationFilter = ({
  options,
  filteredByUrlState = false,
  selectedState: selectedStateParam = '',
  selectedCity: selectedCityParam = '',
}: VehicleLocationFilterProps) => {
  const [selectedState, setSelectedState] = useState(selectedStateParam)
  const [selectedCity, setSelectedCity] = useState(selectedCityParam)

  const stateOptions = [
    <option key="" value="">
      Escolha um estado
    </option>,
  ].concat(
    Object.keys(options).map((state) => (
      <option key={state} value={state}>
        {state}
      </option>
    ))
  )

  const citiesOptions = [
    <option key="" value="">
      Escolha uma cidade
    </option>,
  ].concat(
    options[selectedState]?.map((city) => (
      <option value={city} key={city}>
        {city}
      </option>
    ))
  )

  useEffect(() => {
    setSelectedCity('')
  }, [selectedState])

  return (
    <>
      {!filteredByUrlState && (
        <div className="form-group mb-4">
          <label className="advanced-search-filters__section-title mt-0">
            Estado
          </label>
          <select
            name="state"
            aria-label="Estado"
            className="custom-select"
            defaultValue={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            {stateOptions}
          </select>
        </div>
      )}

      <div className="form-group mb-0">
        <label className="advanced-search-filters__section-title mt-0">
          Cidade
        </label>
        <select
          name="city"
          aria-label="Cidade"
          disabled={!selectedState}
          className="custom-select"
          defaultValue={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
        >
          {citiesOptions}
        </select>
      </div>
    </>
  )
}

export default VehicleLocationFilter

export interface VehicleLocationFilterProps {
  options: Record<string, string[]>
  selectedState?: string
  filteredByUrlState?: boolean
  selectedCity?: string
  urlState?: string
}
