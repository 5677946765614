import styled from 'styled-components'
import { rem } from 'polished'
import { TextInput, Accordion } from 'autocommerce-sb'

import { ContainerBase } from '../../styled'

export const Container = styled(ContainerBase)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${rem(384)};
`

export const Title = styled.h1`
  font-family: Roboto;
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  margin: ${rem(48)} auto ${rem(4)} auto;
  @media (max-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
    max-width: ${rem(312)};
  }
`

export const Details = styled.p`
  font-family: Roboto;
  color: var(--gray-5);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto ${rem(48)} auto;
  @media (max-width: ${rem(600)}) {
    line-height: ${rem(22)};
    font-size: ${rem(14)};
    max-width: ${rem(312)};
  }
`

export const DetailsHighLight = styled.span`
  font-weight: 700;
`

export const StyledInput = styled(TextInput)`
  margin-bottom: ${rem(24)};
`

export const CheckBoxContainer = styled.div`
  text-align: justify;
  margin-bottom: ${rem(48)};
`

export const CheckBoxLabel = styled.span`
  max-width: ${rem(344)};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.15rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray-5);
  @media (max-width: ${rem(600)}) {
    max-width: ${rem(272)};
  }
`

export const CheckBoxLabelLink = styled.span`
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
`

export const DoubtsSection = styled.div`
  margin-top: ${rem(64)};
  display: flex;
  flex-direction: column;
  align-items: center;
  .about-you_accordion {
    margin-bottom: ${rem(16)};
    @media (max-width: ${rem(600)}) {
      width: ${rem(312)} !important;
    }
    @media (min-width: ${rem(601)}) and (max-width: ${rem(800)}) {
      width: ${rem(600)} !important;
    }
    @media (min-width: ${rem(801)}) {
      width: ${rem(800)} !important;
    }
  }
`

export const DoubtsTitle = styled.h4`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: ${rem(24)};
`
