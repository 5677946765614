import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Card, SearchInput } from 'autocommerce-sb'
import { useHistory } from 'react-router-dom'

import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
  clearAvaliationFlowForm,
} from '../../../../store/ducks/avaliation-flow'
import { fetchVersions } from './actions'
import useSmallWindow from '../../hooks/useSmallWindow'

import { Container, CardContainer, Title } from './styled'
import SelectedCarCard from './SelectedCarCard'
import { goTop } from '../../actions'

const SelectVersion = ({ formValues }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSmallWindow = useSmallWindow(600)

  const [fullList, setFullList] = useState([])
  const [showingList, setShowingList] = useState([])
  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(3))
    dispatch(clearAvaliationFlowForm(['version', 'version_id']))
    ;(async () => {
      const list = await fetchVersions(formValues.model_id, formValues.year)
      setFullList(list)
      setShowingList(list)
    })()
  }, [])

  const [search, setSearch] = useState('')
  useEffect(() => {
    setShowingList(
      fullList.filter((version) => {
        return version.name.toLowerCase().includes(search.toLowerCase())
      })
    )
  }, [search])

  const handleSelect = (version) => {
    dispatch(setAvaliationFlowFormValues(version.id, 'version_id'))
    dispatch(setAvaliationFlowFormValues(version.name, 'version'))
    history.push({
      pathname: '/compramos-seu-usado/quilometragem-do-veiculo',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
  }

  return (
    <Container>
      <Title>Qual a versão do seu veículo?</Title>
      <SelectedCarCard model={formValues.model} plate={formValues.plate} />
      <SearchInput
        onChange={(e) => setSearch(e.target.value)}
        width={isSmallWindow ? 312 : 488}
        containerPadding={isSmallWindow ? '12px 16px' : undefined}
      />
      <div>
        {showingList.map((version) => (
          <CardContainer>
            <Card
              title={`${version.name}`}
              padding="20px 28px"
              width={isSmallWindow ? 312 : 488}
              onClick={() => handleSelect(version)}
              titleSize={isSmallWindow ? 14 : 16}
            />
          </CardContainer>
        ))}
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
})

export default connect(mapStateToProps)(SelectVersion)
