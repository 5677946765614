import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { applyCepMask } from '../../utils/applyMasks'

const ReceivementAddress = (props, saveAddressButton) => {
  const [errors, setErrors] = useState({})

  const addressErrorContainers = useRef()

  useEffect(() => {
    addressErrorContainers.current = {
      zip_code: document.getElementById('invalid-cep'),
      street: document.getElementById('invalid-street'),
      number: document.getElementById('invalid-number'),
      neighborhood: document.getElementById('invalid-neighborhood'),
      city: document.getElementById('invalid-city'),
      state: document.getElementById('invalid-state'),
    }

    applyCepMask(addressErrorContainers.current.zip_code.parentElement)
  }, [])

  const firstEffectFlag = useRef(true)

  useEffect(() => {
    // evita que efeito rode na primeira execução, deixando que errors renderizados pelo servidor permaneçam
    if (firstEffectFlag.current) {
      firstEffectFlag.current = false
      return
    }

    const errorContainers = addressErrorContainers.current

    Object.entries(errorContainers).map((entry) => (entry[1].innerHTML = ''))

    errors.zip_code
      ? (errorContainers.zip_code.innerHTML = errors.zip_code[0])
      : ''
    errors.street ? (errorContainers.street.innerHTML = errors.street[0]) : ''
    errors.number ? (errorContainers.number.innerHTML = errors.number[0]) : ''
    errors.neighborhood
      ? (errorContainers.neighborhood.innerHTML = errors.neighborhood[0])
      : ''
    errors.city ? (errorContainers.city.innerHTML = errors.city[0]) : ''
    errors.state ? (errorContainers.state.innerHTML = errors.state[0]) : ''
  }, [errors])

  return (
    <div className="payment-address__address-container">
      <div className="payment-address__address">
        <div className="payment-address__cta-address"></div>
      </div>
    </div>
  )
}

export default forwardRef(ReceivementAddress)
