import React from 'react'
import { render } from 'react-dom'
import { Provider, useDispatch } from 'react-redux'
import store from '../store'
import { moneyFormat } from '../utils/wNumb'
import FinancingInstallments from '../components/FinancingInstallments'
import SimpleInstallments from '../components/FinancingInstallments/SimpleInstallments'
import { setErrorInstallments } from '../store/ducks/financing-installments'
import { Spinner } from '../admin/components/Spinner'

window.addEventListener('load', function () {
  const elFinacingInstallments = document.getElementById(
    'sales__financing-installments'
  )
  const elFormFinancingSimulation = document.getElementById(
    'financing_simulation_form'
  )
  const elSimpleFinancingInstallments = document.getElementById(
    'sales__simple-installments'
  )
  const elSalesBtnNext = document.getElementById('sales__btn--next')

  if (elSalesBtnNext) {
    elSalesBtnNext.addEventListener('click', () => {
      if (elSalesBtnNext.disabled) return

      elSalesBtnNext.disabled = true

      render(Spinner({ size: 24, color: '#fff' }), elSalesBtnNext)
      elFormFinancingSimulation.submit()
    })
  }

  if (elFinacingInstallments) {
    const elsResult = document.querySelectorAll(
      '.sales__financing-installment-result'
    )
    const elInputValue = document.getElementById(
      'financing_simulation_input_value'
    )
    const elInputInstallments = document.getElementById(
      'financing_simulation_installments'
    )
    const elInstallmentValue = this.document.getElementById(
      'financing_simulation_installment_value'
    )
    const elInputBank = document.getElementById('financing_simulation_bank')

    const handleInstallmentChange = (installment) => {
      if (installment) {
        elsResult.forEach((elResult) => {
          elResult.classList.remove('d-none')

          const elResultValue = elResult.querySelector(
            '.sales__financing-installment-result-value'
          )
          elResultValue.innerHTML = `${installment.times}X de ${installment.value}`
        })

        elInputInstallments.value = installment.times
        elInstallmentValue.value = installment.value
      } else {
        elsResult.forEach((elResult) => {
          elResult.classList.add('d-none')
        })
        elInputInstallments.value = null
        elInstallmentValue.value = null
      }
    }

    const handleInputRangeChange = (value) => {
      elInputValue.value = value
    }

    const handleBankChange = (bank) => {
      elInputBank.value = bank
    }

    const handleInstallmentsRequested = () => {
      const { errorApi } = store.getState().financingInstallments
      const elContinueButton = document.querySelector('.sales__btn--next')
      const sellerWhatsappButton = document.querySelector(
        '.sales__btn--whatsapp'
      )

      if (errorApi) {
        elContinueButton.classList.add('d-none')
        sellerWhatsappButton.classList.remove('d-none')
        return
      }

      elContinueButton.classList.remove('d-none')
      sellerWhatsappButton.classList.add('d-none')
    }

    const input_value = moneyFormat.from(elInputValue.value).toFixed()
    const {
      min,
      max,
      errorsInstallments,
      vehicleName,
      vehiclePrice,
      showInputRange,
      saleId,
      useSimpleInstallments,
    } = elFinacingInstallments.dataset

    render(
      <Provider store={store}>
        <FinancingInstallments
          sale_id={saleId}
          input_value={input_value.toString()}
          min={Number(min)}
          max={Number(max)}
          vehicleName={vehicleName}
          vehiclePrice={Number(vehiclePrice)}
          showInputRange={JSON.parse(showInputRange)}
          errorsInstallments={JSON.parse(errorsInstallments)}
          onInstallmentChange={handleInstallmentChange}
          onInputRangeChange={handleInputRangeChange}
          onBankChange={handleBankChange}
          onInstallmentsRequested={handleInstallmentsRequested}
          useSimpleInstallments={useSimpleInstallments === 'true'}
        />
      </Provider>,
      elFinacingInstallments
    )

    // validação do lado client
    elFormFinancingSimulation.addEventListener('submit', (e) => {
      const installments = document.getElementById(
        'financing_simulation_installments'
      ).value
      const entryValue = parseFloat(
        document.getElementById('financing_simulation_input_value').value
      )

      if (!installments) {
        e.preventDefault()
        store.dispatch(setErrorInstallments(true))
      }
      if (entryValue > max) {
        e.preventDefault()
      }
    })
  }
})
