import styled from 'styled-components'
import ReactSelect from 'react-select'
import { rem } from 'polished'

export const Select = styled(ReactSelect)`
  font-size: ${rem(16)};
  line-height: 150%;
`

export const defaultStyles = {
  control: (base) => ({
    ...base,
    borderRadius: rem(4),
    display: 'flex',
    justfyContent: 'space-between',
    padding: `${rem(4)} ${rem(16)}`,
    height: rem(50),
    borderColor: '#ced4da',
  }),

  option: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    padding: `${rem(8)} ${rem(16)}`,
    margin: rem(8),
    borderRadius: rem(4),
    cursor: 'pointer',
    fontSize: rem(16),
  }),

  menuList: (base) => ({
    ...base,
    padding: rem(8),
  }),

  dropdownIndicator: (base, state) => ({
    display: 'flex',
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
}
