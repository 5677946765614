import axios from 'axios'

export const fetchVersions = async (modelId, year) => {
  if (!modelId || !year) return []
  const baseURL = window.location.origin
  const { data } = await axios.get(
    `${baseURL}/api/v1/vehicles/versions?modelId=${modelId}&year=${year}`
  )
  return data
}
