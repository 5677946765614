import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { Card } from 'autocommerce-sb'

import { goTop } from '../../../../actions'
import { getPreviousYears } from './action'
import { CardsContainer, Container, Title, StyledBanner } from './style'
import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
  clearAvaliationFlowForm,
} from '../../../../../../store/ducks/avaliation-flow'
import useSmallWindow from '../../../../hooks/useSmallWindow'

const SelectYear = ({ formValues, warnMsg }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSmallWindow = useSmallWindow(600)

  const [yearsList, setYearsList] = useState([])
  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(0))
    dispatch(clearAvaliationFlowForm(['year', 'plate']))
    setYearsList(getPreviousYears(17))
  }, [])

  const handleSelect = (year) => {
    dispatch(setAvaliationFlowFormValues(parseInt(year), 'year'))
    history.push({
      pathname: '/compramos-seu-usado/marca-do-veiculo',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
  }

  return (
    <Container>
      {warnMsg != '' && (
        <StyledBanner
          className="avaliation-flow__warning-banner"
          type="warning"
          width={isSmallWindow ? '312px' : '384px'}
          message={warnMsg}
        />
      )}
      <Title>Qual o ano do modelo do seu veículo?</Title>
      <CardsContainer>
        {yearsList.map((year, index) => {
          return (
            <Card
              key={index}
              title={year}
              width={isSmallWindow ? 312 : 248}
              onClick={() => handleSelect(year)}
              padding="20px 24px"
              data-cy={`card-${year}`}
            />
          )
        })}
      </CardsContainer>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
  warnMsg: state.avaliationFlow.plateSearchMessage,
})

export default connect(mapStateToProps)(SelectYear)
