import styled from 'styled-components'
import { rem } from 'polished'
import { Banner } from 'autocommerce-sb'

export const Container = styled.div`
  width: ${rem(592)};
  margin: 0 auto;
  padding-bottom: ${rem(64)};
  @media (max-width: 600px) {
    width: ${rem(312)};
    overflow-y: hidden;
  }
`
export const CardsContainer = styled.div`
  justify-content: center;
  > div {
    margin-bottom: ${rem(16)};
  }
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    width: ${rem(528)};
    margin: 0 auto;
    justify-content: flex-start;
    div:nth-of-type(2n) {
      margin-left: 32px;
    }
  }
`
export const Title = styled.h1`
  font-family: Roboto;
  font-size: ${rem(24)};
  font-weight: 700;
  line-height: ${rem(29)};
  letter-spacing: 0em;
  text-align: center;
  margin: 48px auto;
  @media (max-width: 600px) {
    line-height: ${rem(22)};
    font-size: ${rem(18)};
  }
`

export const StyledBanner = styled(Banner)`
  margin: ${rem(20)} auto 0 auto;
`
