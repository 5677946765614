import React from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'
import Address from '../components/ProductReceivement/Address'
import autofillCepData from './autofill-cep-function'
import { handleSelectAddressStateChange } from './autofill-cep-select-state-utils'

const elContainer = document.querySelector('.receive-vehicle')

if (elContainer) {
  const checkInputName = 'receive'

  function removeClassesFromAllCheckboxBlocks() {
    document.querySelectorAll('.receive-vehicle__block-check').forEach((el) => {
      el.classList.remove('receive-vehicle__block-check--active')
    })
  }

  function addClassesToActiveCheckboxBlock() {
    const target = document.querySelector(`[name="${checkInputName}"]:checked`)

    if (target) {
      const container = target.closest('.receive-vehicle__block-check')

      if (container) {
        container.classList.add('receive-vehicle__block-check--active')
      }
    }
  }

  function hideReceivementAddressFormContainer() {
    const elContainerForm = document.querySelector('.receive-vehicle__box-form')
    elContainerForm.classList.add('d-none')
  }

  /**
   * Adicionar class ao box "Atenção"
   */
  function addClassBoxWarning() {
    const el = document.querySelector('.receive-vehicle__warning--desktop')
    el.classList.add('receive-vehicle__warning--no-border-radius')
  }

  /**
   * Remove class do box "Atenção"
   */
  function removeClassBoxWarning() {
    const el = document.querySelector('.receive-vehicle__warning--desktop')
    el.classList.remove('receive-vehicle__warning--no-border-radius')
  }

  const toggleReceivementAddressFormDisplay = (e) => {
    e.preventDefault()
    addClassBoxWarning()

    const elContainerForm = document.querySelector('.receive-vehicle__box-form')

    if (elContainerForm.classList.contains('d-none'))
      elContainerForm.classList.remove('d-none')
    else elContainerForm.classList.add('d-none')
  }

  /**
   * Manipulador de evento do checkbox
   */
  function handleRadioOptionChange() {
    document.getElementsByName(checkInputName).forEach((el) => {
      el.addEventListener('change', (e) => {
        removeClassesFromAllCheckboxBlocks()
        addClassesToActiveCheckboxBlock()
        hideReceivementAddressFormContainer()
        removeClassBoxWarning()
      })
    })
  }

  /**
   * Manipulador de evento do botão ver endereço (da loja)
   */
  function handleSeeAddressClick() {
    const showAddressButton = document.querySelector(
      '.receive-vehicle__show-address'
    )
    const storeAddressContainer = document.querySelector(
      '.receive-vehicle__address--store'
    )

    if (showAddressButton) {
      showAddressButton.addEventListener('click', (event) => {
        event.preventDefault()
        storeAddressContainer.classList.toggle('d-none')
        event.target.classList.add('d-none')
      })
    }
  }

  /**
   * Renderiza select de estado do form de endereço
   */
  function renderSelectState() {
    const elSelect = document.getElementById(
      'receive-vehicle-select-address-state'
    )
    const { options, defaultValue } = elSelect.dataset
    const optionsJSON = JSON.parse(options)
    optionsJSON.push({ label: 'Selecione uma opção', value: '' })

    ReactDOM.render(
      <Select
        className="form__control-select"
        classNamePrefix="form__control-select"
        name="customer[state]"
        options={optionsJSON}
        placeholder="Selecione uma opção"
        defaultValue={optionsJSON.find(
          (state) => state.value === defaultValue || state.value == ''
        )}
        onChange={handleSelectAddressStateChange}
      />,
      elSelect
    )
  }
  /**
   * Ao inserir CEP, aplica outros campos de endereço automaticamente
   */

  autofillCepData(elContainer, 'receive-vehicle-select-address-state')

  /**
   * Chamadas das funções
   */

  function hydrateReceivementAddressComponentIfRendered() {
    const receivementAddressContainer =
      document.querySelector('#customer-address')

    if (receivementAddressContainer) {
      const props = JSON.parse(
        document.querySelector('[data-dom-id="customer-address"]').innerHTML
      )
      const saveAddressButton = document.querySelector('#save-address')

      ReactDOM.hydrate(
        <Address
          ref={saveAddressButton}
          customer={props.customer}
          onAddAddressClick={toggleReceivementAddressFormDisplay}
        />,
        receivementAddressContainer
      )
    }
  }

  handleRadioOptionChange()
  handleSeeAddressClick()
  addClassesToActiveCheckboxBlock()

  renderSelectState()
  hydrateReceivementAddressComponentIfRendered()
}
