import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import store from '../../store'
import UploadDropzoneTwo from '../../components/UploadDropzoneTwo'
import ModalPurchaseTerm from '../../components/ModalPurchaseTerm'
import ModalReserveTerm from '../../components/ModalReserveTerm'
import { setOpenModalPurchaseTerm } from '../../store/ducks/modal-purchase-term'
import { setOpenModalReserveTerm } from '../../store/ducks/modal-reserve-term'

/**
 * Atualmente renderiza o campo de upload de recibo de pagamento
 */
export function renderUploads() {
  const uploadEl = document.getElementById(
    'payment__send-file-upload-receipt-payment'
  )
  if (!uploadEl) return
  const elFileUploadReceiptPaymentReceipt = uploadEl.closest(
    '.payment__send-file-list-item'
  )
  if (elFileUploadReceiptPaymentReceipt.classList.contains('d-none')) return
  const { inputName } = uploadEl.dataset

  render(<UploadDropzoneTwo inputName={inputName} />, uploadEl)
}

export function renderModalPurchaseTerm() {
  const el = document.getElementById('payment__modal-purchase-term')
  if (!el) return false
  const elAcceptTerm = document.getElementById('payment_accept_terms')
  if (!elAcceptTerm) return false
  const { purchaseContract } = el.dataset

  function handleAcceptClick(e) {
    e.preventDefault()

    elAcceptTerm.checked = true
    store.dispatch(setOpenModalPurchaseTerm(false))
  }

  function handleRejectClick(e) {
    e.preventDefault()

    elAcceptTerm.checked = false
    store.dispatch(setOpenModalPurchaseTerm(false))
  }

  render(
    <Provider store={store}>
      <ModalPurchaseTerm
        purchaseContract={purchaseContract}
        onAcceptClick={handleAcceptClick}
        onRejectClick={handleRejectClick}
      />
    </Provider>,
    el
  )
}

export function handlePaymentAcceptTerms() {
  const el = document.getElementById('payment__link-accept-terms')

  if (!el) return

  el.addEventListener(
    'click',
    (e) => {
      e.preventDefault()

      store.dispatch(setOpenModalPurchaseTerm(true))
    },
    false
  )
}

/**
 * Renderiza modal de Termos de Reserva
 */

export function renderModalReserveTerm() {
  const el = document.getElementById('payment__modal-purchase-term')
  const elAcceptTerm = document.getElementById(
    'payment_accept_reservation_terms'
  )
  const vehicleReservationDays = document.getElementById(
    'vehicle-reservation-days'
  )?.dataset?.vehicleReservationDays
  /*
    apesar desta função se referir ao termo ser de reserva, ao concordar, o usuário ainda está concordando com os termos de COMPRA
    provavelmente em back end deverá ser implementado mais um campo que ao invés de :accept_terms, seria :accept_terms_reservation
    por enquanto ainda não implementado
  */

  function handleAcceptClick(e) {
    e.preventDefault()

    elAcceptTerm.checked = true
    store.dispatch(setOpenModalReserveTerm(false))
  }

  function handleRejectClick(e) {
    e.preventDefault()

    elAcceptTerm.checked = false
    store.dispatch(setOpenModalReserveTerm(false))
  }

  render(
    <Provider store={store}>
      <ModalReserveTerm
        vehicleReservationDays={vehicleReservationDays}
        onAcceptClick={handleAcceptClick}
        onRejectClick={handleRejectClick}
      />
    </Provider>,
    el
  )
}

export function handleReserveAcceptTerms() {
  const el = document.getElementById('reserve__link-accept-terms')

  if (!el) return

  el.addEventListener(
    'click',
    (e) => {
      e.preventDefault()

      store.dispatch(setOpenModalReserveTerm(true))
    },
    false
  )
}
