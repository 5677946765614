import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const TYPES = {
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  FACEBOOK: 'Facebook',
  LINKEDIN: 'Linkedin',
  YOUTUBE: 'Youtube',
  VIMEO: 'Vimeo',
  TIKTOK: 'Tiktok',
  NEWINSTAGRAM: 'New-Instagram',
  NEWTWITTER: 'New-Twitter',
  NEWFACEBOOK: 'New-Facebook',
  NEWLINKEDIN: 'New-Linkedin',
  NEWYOUTUBE: 'New-Youtube',
  NEWVIMEO: 'New-Vimeo',
  NEWTIKTOK: 'New-Tiktok',
}

const ICONS = Object.values(TYPES).reduce(
  (acc, value) => ({
    [value]: value.toLowerCase(),
    ...acc,
  }),
  {}
)

const Item = ({
  classNameItem = '',
  classNameItemLink = '',
  url,
  title,
  color,
}) => {
  return (
    <li className={`social-links__item ${classNameItem}`}>
      <a
        className={clsx(
          `icon icon-${ICONS[title]} social-links__item-link ${classNameItemLink}`,
          {
            [`bg-${ICONS[title]}`]: color,
          }
        )}
        href={url}
        alt={title}
        target="_blank"
      />
    </li>
  )
}

Item.defaultProps = {
  /**
   * Booleano se deve aplicar cor de fundo da rede social
   */
  color: false,
}

Item.propTypes = {
  classNameItem: PropTypes.string,
  classNameItemLink: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  color: PropTypes.bool,
}

export default Item
