import React, { useEffect, useState } from 'react'
import { TextInput, Button, Banner } from 'autocommerce-sb'
import { useHistory } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { fetchPlate } from './action'
import { goTop } from '../../actions'
import useSmallWindow from '../../hooks/useSmallWindow'
import {
  WarningContainer,
  ManualInsertCTA,
  InputContainer,
  Container,
  Title,
  StyledIcon,
  WarningText,
  StyledButton,
} from './style'
import {
  setAvaliationFlowStep,
  setAvaliationFlowFormValues,
  clearAvaliationFlowForm,
} from '../../../../store/ducks/avaliation-flow'

const FindVehicle = ({ formValues }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isSmallWindow = useSmallWindow(600)
  const [plateInput, setPlateInput] = useState('')
  const [vehicleData, setVehicleData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [warnMsg, setWarnMsg] = useState('')

  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(1))
    dispatch(
      clearAvaliationFlowForm(['plate', 'make_id', 'model_id', 'model', 'year'])
    )
  }, [])

  useEffect(() => {
    const plateRegex = /[a-zA-Z]{3}-[0-9][a-zA-Z0-9][0-9]{2}/
    const matchPlate = plateRegex.exec(plateInput)
    if (matchPlate) {
      ;(async () => {
        setIsLoading(true)
        const { payload, status } = await fetchPlate(plateInput)
        setIsLoading(false)
        if (status && payload.year > 0) {
          setIsDisabled(false)
          setWarnMsg('')
          setVehicleData(payload)
        } else {
          setWarnMsg(
            'Não encontramos a sua placa, insira os dados manualmente.'
          )
          setIsDisabled(true)
        }
      })()
    } else {
      setVehicleData([])
      setIsDisabled(true)
    }
  }, [plateInput])

  function handleChange(e) {
    setPlateInput(e)
  }

  function handleClick() {
    const data_payload = {
      plate: plateInput,
      make_id: vehicleData.make?.id,
      model_id: vehicleData.model?.id,
      model: vehicleData.model.name,
      year: vehicleData.year,
    }
    Object.keys(data_payload).map((e) => {
      dispatch(setAvaliationFlowFormValues(data_payload[e], e))
    })
    history.push({
      pathname: '/compramos-seu-usado/versao-do-veiculo',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
  }

  const handleManualData = () => {
    history.push({
      pathname: '/compramos-seu-usado/ano-do-veiculo',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
  }

  return (
    <Container>
      <Title>Qual o seu veículo?</Title>
      <InputContainer>
        <TextInput
          mask={[{ mask: 'aaa-0a00' }, { mask: 'aaa-0000' }]}
          label="Placa do carro"
          placeholder="Ex.: BRA2E22"
          width={isSmallWindow ? 312 : 384}
          onChange={(e) => handleChange(e)}
        />

        {warnMsg != '' && (
          <Banner
            className="avaliation-flow__warning-banner"
            type="warning"
            width={isSmallWindow ? 312 : 384}
            message={warnMsg}
          />
        )}

        <ManualInsertCTA>
          Não sei minha placa,{' '}
          <a onClick={handleManualData}>Inserir dados manualmente</a>
        </ManualInsertCTA>

        <StyledButton
          label="Continuar"
          width={isSmallWindow ? 312 : 384}
          disable={isDisabled}
          loading={isLoading}
          onClick={() => handleClick()}
        />
      </InputContainer>
      <WarningContainer>
        <StyledIcon className="icon icon-info-grid"></StyledIcon>
        <WarningText>
          Não se preocupe, usamos esses dados
          <br />
          exclusivamente para precificar seu veículo
        </WarningText>
      </WarningContainer>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
})

export default connect(mapStateToProps)(FindVehicle)
