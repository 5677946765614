import React, { useEffect, useState } from 'react'
import StoresCarousel from '../StoresCarousel'
import { Select, Title } from './styles'
import { states } from '../../utils/states'
import UniqueStoreCard from './uniqueStoreCard'

const StoresCarouselApp = ({ elements, urlState }) => {
  const [selectedState, setSelectedState] = useState(urlState)

  const storesBySelectedState = () => {
    return elements.filter((item) => item.state === selectedState)
  }

  const stateOptions = () => {
    const optionsArray = []

    elements.map((element) => {
      if (!optionsArray.find((item) => item.value === element.state)) {
        optionsArray.push({
          title: states[element.state],
          value: element.state,
        })
      }
    })

    return optionsArray
  }

  const handleSelectChange = (event) => {
    setSelectedState(event.target.value)
  }

  const onlyOneStore = () => {
    return storesBySelectedState().length === 1 && stateOptions().length === 1
      ? storesBySelectedState()[0]
      : false
  }

  const selectOptions = stateOptions().map((option, index) => (
    <option key={index} value={option.value}>
      {option.title}
    </option>
  ))

  const carouselElements = () => {
    return selectedState ? storesBySelectedState() : elements
  }

  useEffect(() => {
    if (stateOptions().length === 1) {
      setSelectedState(stateOptions()[0].value)
    }
  }, [])

  return !elements.length ? (
    <></>
  ) : (
    <div className="mb-5">
      <section className="container stores-container--padding-24">
        <Title>Nossas lojas</Title>

        {stateOptions().length > 1 && (
          <Select
            className="custom-select mb-5"
            onChange={handleSelectChange}
            value={selectedState}
          >
            <option value="">Todos os estados</option>
            {selectOptions}
          </Select>
        )}
      </section>

      <section>
        {onlyOneStore() ? (
          <UniqueStoreCard {...onlyOneStore()} />
        ) : (
          <StoresCarousel elements={carouselElements()} />
        )}
      </section>
    </div>
  )
}

export default StoresCarouselApp
