import React from 'react'
import ReactDOM from 'react-dom'
import AvaliationFlow from '../components/AvaliationFlow'
import { Provider } from 'react-redux'
import store from '../store'
import { UsedAvaliationBtn } from '../components/UsedAvaliationBtn'

const elAvaliationFlow = document.getElementById('avaliation-flow')
const elUsedAvaliationBtn = document.getElementsByName(
  'evaluation__presentation__button'
)
console.log(elUsedAvaliationBtn)

if (elAvaliationFlow) {
  ReactDOM.render(
    <Provider store={store}>
      <AvaliationFlow />
    </Provider>,
    elAvaliationFlow
  )
}

if (elUsedAvaliationBtn) {
  elUsedAvaliationBtn.forEach((el) => {
    const { enableFlow, className } = el.dataset
    ReactDOM.render(
      <Provider store={store}>
        <UsedAvaliationBtn enableFlow={enableFlow} className={className} />
      </Provider>,
      el
    )
  })
}
