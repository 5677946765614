import validationFactory from '../utils/validation'
;(() => {
  const elContainer = document.querySelector('.vehicle-inspection')

  if (!elContainer) return

  const validator = validationFactory('.vehicle-inspection__form')

  function handleSubmitForm() {
    const elForm = document.querySelector('.vehicle-inspection__form')

    elForm.addEventListener('submit', (e) => {
      const formIsValid = validator.validateAll(elForm).length === 0

      elForm.classList.add('was-validated')

      if (!formIsValid) {
        e.preventDefault()
        return
      }

      elForm.submit()
    })
  }

  handleSubmitForm()
})()
