export const phoneMasks = [
  {
    mask: '(00) 0000-0000',
  },
  {
    mask: '(00) 00000-0000',
  },
]

export const cpfMasks = [
  {
    mask: '000.000.000-00',
  },
]

export const cnpjMasks = [
  {
    mask: '00.000.000/0000-00',
  },
]

export const cepMasks = [
  {
    mask: '00000-000',
  },
]

export const dateMasks = [
  {
    mask: '00/00/0000',
  },
]

export const moneyMask = [
  {
    mask: '',
  },
  {
    mask: 'R$ num,\\0\\0',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        thousandsSeparator: '.',
      },
    },
  },
]

export const plateMasks = [{ mask: 'aaa-0a00' }, { mask: 'aaa-0000' }]

export const yearMasks = [
  {
    mask: 'YYYY',
    blocks: {
      YYYY: {
        mask: IMask.MaskedRange,
        from: 1955,
        to: 2023,
      },
    },
  },
]

export const kmMask = [
  {
    mask: '',
  },
  {
    mask: 'num Km',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: '.',
        max: 999999999,
        signed: false,
        scale: 0,
      },
    },
  },
]

export const creditNumberMasks = [
  {
    mask: '0000 000000 00000',
  },
  {
    mask: '0000 0000 0000 0000',
  },
]

export const creditValidateMasks = [
  {
    mask: 'MM/YYYY',
    blocks: {
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
      YYYY: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 99,
      },
    },
  },
]

export const dateMask = {
  mask: Date,
  pattern: 'd{/}`m{/}`Y', // default is 'd{.}`m{.}`Y'
  format: function (date) {
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    if (day < 10) day = '0' + day
    if (month < 10) month = '0' + month

    return [day, month, year].join('/')
  },
  parse: function (str) {
    let yearMonthDay = str.split('/')
    let date = new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0])
    return date
  },
  lazy: false,
}

export const positiveNumberMask = [
  {
    mask: '',
  },
  {
    mask: /^\d+$/,
    lazy: false,
  },
]

export const addressNumberMask = [
  {
    mask: '',
  },
  {
    mask: /^\w+$/,
  },
]
