import React from 'react'
import { useDispatch } from 'react-redux'
import { setOpenModalContact } from '../../store/ducks/modal-contact'

export const UsedAvaliationBtn = ({ enableFlow, className }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    if (enableFlow === 'false') {
      dispatch(setOpenModalContact(true))
    }
  }

  return (
    <a
      href={
        enableFlow === 'true'
          ? '/compramos-seu-usado/ano-do-veiculo'
          : undefined
      }
      className={className}
      onClick={() => handleClick()}
    >
      Avaliar meu veículo
    </a>
  )
}
