
import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import Loading from '../FinancingInstallments/Loading'
import Error from './Error'
import CreditAnalysisApprovedBox from './CreditAnalysisApprovedBox'
import CreditAnalysisSummary from './CreditAnalysisSummary'
import CreditAnalysisInfo from './CreditAnalysisInfo'
import CreditAnalysisFooter from './CreditAnalysisFooter'
import SalesSecurityText from './SalesSecurityText'
import { useDispatch } from 'react-redux'
import financingService from '../../service/financingSimulationService'

const CreditAnalysis = (props) => {

  const timeToWaitApprovalInSeconds = 20

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const retries = useRef(0)
  const numberOfAnalysisRetries = props.number_of_analysis_retries

  const [simulationNumber, setSimulationNumber] = useState(props.simulation_number)

  const dispatch = useDispatch()

  const getCreditAnalysis = () => {
   financingService.creditAnalysis(props.financing_request_id)
      .then(analysis => {
        const statusInterpretedAsFailure = ['rejected', 'error', 'other']

        if (statusInterpretedAsFailure.includes(analysis.status)) {
          dispatch({ type: "CREDIT_ANALYSIS/REJECTED" })
          setError(true)
          return 
        }

        if (analysis.status == 'processing') {
          dispatch({ type: "CREDIT_ANALYSIS/REJECTED" })
          setError(true)
          return
        }
        
        if (analysis.status == 'approved') {
          setSimulationNumber(analysis.transaction_number)
          dispatch({ type: "CREDIT_ANALYSIS/APPROVED" })
          return 
        } 
      })
      .catch(err => {
        dispatch({ type: "CREDIT_ANALYSIS/REJECTED" })
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setTimeout(getCreditAnalysis, timeToWaitApprovalInSeconds * 1000)
  })

  const content = () => {

    if (loading) return (<Loading />)
    if (error) return (<Error bankName={props.bank}/>)

    return (
      <div>
        <CreditAnalysisApprovedBox bank={props.bank} simulation_number={simulationNumber} />

        <CreditAnalysisSummary financing_value={props.financing_value} {...props.product} />

        <CreditAnalysisInfo {...props} />

        <CreditAnalysisFooter legal_notice={props.legal_notice} used_in_exchange={props.used_in_exchange} />

        <SalesSecurityText dealerName={props.dealer_name} />
      </div>
    )
  }

  return content()
}

export default CreditAnalysis