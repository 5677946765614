import React from 'react'
import NavbarSearchInput from '../components/NavbarSearchInput'
import UserDropDown from '../components/UserDropDown'
import Swiper from 'react-id-swiper'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import StoresCarouselApp from '../components/StoresCarouselApp'
import { storesCarouselElements } from './data/storesCarouselElements'

const IndexStyleGuide = () => {
  return (
    <div className="container">
      <section className="style-guide__section">
        <h1 className="style-guide__title">Style Guide</h1>
        <div className="style-guide__call style-guide--call-large">
          Grupo de compontes utilizados na Loja do Autocommerce
        </div>
      </section>

      <section className="style-guide__section">
        <h2 className="style-guide__subtitle">Index Background</h2>
        <div className="style-guide__call">
          São as cores padrão a serem utilizadas nos fundos de cards e outros
          backgrounds dentro na página Index.
        </div>

        <div className="style-guide__colors row">
          <div className="col-md-3 col-sm-12 style-guide__color bg-primary text-white">
            <h3 className="style-guide__color-title">
              <span>&primary</span>
              var(--primary)
            </h3>
          </div>
          <div className="col-md-3 col-sm-12 style-guide__color bg-primary-dark text-white">
            <h3 className="style-guide__color-title">
              <span>&primaryDark</span>
              var(--primaryDark)
            </h3>
          </div>
          <div className="col-md-3 col-sm-12 style-guide__color style-guide--gray-bg-2">
            <h3 className="style-guide__color-title">
              <span>&gray-bg-2</span>
              #FDFDFD
            </h3>
          </div>
          <div className="col-md-3 col-sm-12 style-guide__color style-guide--gray-bg-1">
            <h3 className="style-guide__color-title">
              <span>&gray-bg-1</span>
              #FFFFFF
            </h3>
          </div>
        </div>
      </section>

      <section className="style-guide__section">
        <h2 className="style-guide__subtitle">Inputs</h2>
        <div className="row form-group">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <h5>Text input</h5>
            <input
              className="styled-input"
              type="text"
              placeholder="Digite o CEP"
            />
          </div>
        </div>

        <div className="row form-group">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <h5>Select input</h5>
            <select className="custom-select styled-select" name="" id="">
              <option value="">Pernambuco</option>
              <option value="">Rio Grande do Norte</option>
              <option value="">São Paulo</option>
            </select>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <h5>Navbar search input</h5>
            <NavbarSearchInput />
          </div>
        </div>
      </section>

      <section className="style-guide__section">
        <h2 className="style-guide__subtitle">Buttons</h2>
        <h5>Primary</h5>

        <div className="row">
          <div className="col-sm-1 text-lg-right">
            <strong>Default</strong>
          </div>
          <div className="col-sm-8 d-flex flex-wrap" style={{ gap: '8px' }}>
            <PrimaryButton>
              Avaliar meu carro
              <i icon-name="plus" className="icon button__icon"></i>
            </PrimaryButton>
            <PrimaryButton>
              <i icon-name="plus" className="icon button__icon"></i>
              Avaliar meu carro
            </PrimaryButton>
            <PrimaryButton>Avaliar meu carro</PrimaryButton>
          </div>
        </div>

        <br />

        <div className="row">
          <div className="col-sm-1 text-lg-right">
            <strong>Disabled</strong>
          </div>
          <div className="col-sm-8 d-flex flex-wrap" style={{ gap: '8px' }}>
            <PrimaryButton disabled={true}>
              Avaliar meu carro
              <i icon-name="plus" className="icon button__icon"></i>
            </PrimaryButton>
            <PrimaryButton disabled={true}>
              <i icon-name="plus" className="icon button__icon"></i>
              Avaliar meu carro
            </PrimaryButton>
            <PrimaryButton disabled={true}>Avaliar meu carro</PrimaryButton>
          </div>
        </div>

        <h5>Secondary</h5>

        <div className="row">
          <div className="col-sm-1 text-lg-right">
            <strong>Default</strong>
          </div>
          <div className="col-sm-8 d-flex flex-wrap" style={{ gap: '8px' }}>
            <SecondaryButton>
              Avaliar meu carro
              <i icon-name="plus" className="icon button__icon"></i>
            </SecondaryButton>
            <SecondaryButton>
              <i icon-name="plus" className="icon button__icon"></i>
              Avaliar meu carro
            </SecondaryButton>
            <SecondaryButton>Avaliar meu carro</SecondaryButton>
          </div>
        </div>

        <br />

        <div className="row">
          <div className="col-sm-1 text-lg-right">
            <strong>Disabled</strong>
          </div>
          <div className="col-sm-8 d-flex flex-wrap" style={{ gap: '8px' }}>
            <SecondaryButton disabled={true}>
              Avaliar meu carro
              <i icon-name="plus" className="icon button__icon"></i>
            </SecondaryButton>
            <SecondaryButton disabled={true}>
              <i icon-name="plus" className="icon button__icon"></i>
              Avaliar meu carro
            </SecondaryButton>
            <SecondaryButton disabled={true}>Avaliar meu carro</SecondaryButton>
          </div>
        </div>

        <br />

        <div className="row">
          <div className="col-sm-1 text-lg-right">
            <strong>Whatsapp</strong>
          </div>
          <div className="col-sm-8">
            <button className="btn navbar-header__btn-toggle navbar-header__btn-toggle--whatsapp">
              <i className="icon icon-whatsapp navbar-header__btn-toggle--icon-whatsapp m-0"></i>
            </button>
          </div>
        </div>
      </section>

      <section className="style-guide__section">
        <h2 className="style-guide__subtitle">Dropdowns</h2>

        <div className="row">
          <div className="col-sm-2 text-lg-right">
            <strong>User dropdown</strong>
          </div>
          <div className="col-sm-3">
            <UserDropDown />
          </div>
        </div>
      </section>

      <section className="style-guide__section">
        <h2 className="style-guide__subtitle">Containers</h2>

        <Swiper>
          <div className="advantage-card">
            <header>
              <h2 className="advantage-card__title">Reserve e compre online</h2>
            </header>
            <section className="advantage-card__content">
              Reserve seu veículo online e tenha total segurança durante seu
              processo de compra.
            </section>
            <footer>
              <a className="advantage-card__link">
                Veja nosso catalogo
                <i
                  icon-name="arrow-right"
                  className="icon button__icon advantage-card__link-icon"
                ></i>
              </a>
            </footer>
          </div>

          <div className="advantage-card">
            <header>
              <h2 className="advantage-card__title">Reserve e compre online</h2>
            </header>
            <section className="advantage-card__content">
              Reserve seu veículo online e tenha total segurança durante seu
              processo de compra.
            </section>
            <footer>
              <a className="advantage-card__link">
                Veja nosso catalogo
                <i
                  icon-name="arrow-right"
                  className="icon button__icon advantage-card__link-icon"
                ></i>
              </a>
            </footer>
          </div>

          <div className="advantage-card">
            <header>
              <h2 className="advantage-card__title">Reserve e compre online</h2>
            </header>
            <section className="advantage-card__content">
              Reserve seu veículo online e tenha total segurança durante seu
              processo de compra.
            </section>
            <footer>
              <a className="advantage-card__link">
                Veja nosso catalogo
                <i
                  icon-name="arrow-right"
                  className="icon button__icon advantage-card__link-icon"
                ></i>
              </a>
            </footer>
          </div>
        </Swiper>

        <StoresCarouselApp elements={storesCarouselElements} />
      </section>
    </div>
  )
}

export default IndexStyleGuide
