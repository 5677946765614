import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { goTop } from '../../../../actions'
import {
  setAvaliationFlowFormValues,
  setAvaliationFlowStep,
  clearAvaliationFlowForm,
} from '../../../../../../store/ducks/avaliation-flow'
import { Container, Title, StyledSearch, StyledCard } from './styled'
import { fetchModels } from './action'
import useSmallWindow from '../../../../hooks/useSmallWindow'

const SelectModel = ({ formValues }) => {
  const dispatch = useDispatch()
  const isSmallWindow = useSmallWindow(600)
  const history = useHistory()

  const [modelsList, setModelsList] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    goTop()
    dispatch(setAvaliationFlowStep(2))
    dispatch(clearAvaliationFlowForm(['model_id', 'model']))
    ;(async () => {
      if (formValues.year && formValues.make_id) {
        const list = await fetchModels(formValues.year, formValues.make_id)
        setModelsList(list)
      }
    })()
  }, [])

  const handleSelect = (model) => {
    dispatch(setAvaliationFlowFormValues(model.id, 'model_id'))
    dispatch(setAvaliationFlowFormValues(model.name, 'model'))
    history.push({
      pathname: '/compramos-seu-usado/versao-do-veiculo',
      search: formValues.city_inspection
        ? `?cidade=${formValues.city_inspection}&estado=${formValues.state_inspection_UF}`
        : '',
    })
  }

  return (
    <Container>
      <Title>Selecione o modelo do seu veículo</Title>
      <StyledSearch
        onChange={(e) => setSearch(e.target.value)}
        width={isSmallWindow ? 312 : 488}
        containerPadding={isSmallWindow ? '12px 16px' : undefined}
      />
      <div>
        {modelsList
          .filter((model) =>
            model.name.toLowerCase().includes(search.toLowerCase())
          )
          .map((model) => (
            <StyledCard
              title={`${model.name}`}
              padding="20px 28px"
              width={isSmallWindow ? 312 : 488}
              onClick={() => handleSelect(model)}
              titleSize={isSmallWindow ? 14 : 16}
            />
          ))}
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  formValues: state.avaliationFlow.formValues,
})

export default connect(mapStateToProps)(SelectModel)
