import React, { useState } from 'react'
import { SocialLinks } from '../NavbarHeader/SocialLinks'
import './index.scss'

export const ShortNavbarHeader = ({
  logoPath,
  unitsPhone,
  unitsWhatsapp,
  socialLinks,
  url_state,
}) => {
  const [showNavbar, setShowNavbar] = useState(false)
  const [showButton, setShowButton] = useState(
    !window.location.pathname.includes('compramos-seu-usado')
  )
  const hasStatePrefix = !!url_state ? `/${url_state}` : ''
  const aboutUsPath = hasStatePrefix + '/quem-somos'
  const contactUsPath = hasStatePrefix + '/empresa-fale-conosco'

  return (
    <header className="header-container">
      <div className="bg-primary">
        <div
          style={{
            maxWidth: '1216px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <a className="d-xl-inline text-white mr-5" href={aboutUsPath}>
            Quem Somos
          </a>
          <a className="d-xl-inline text-white" href={contactUsPath}>
            Fale Conosco
          </a>
        </div>
      </div>
      <div className="header-content">
        <a href={window.location.origin}>
          <img alt="logo" src={logoPath} />
        </a>

        <a
          href={'/compramos-seu-usado/ano-do-veiculo'}
          style={{ display: showButton ? 'flex' : 'none' }}
          className="evaluation-custom-button"
        >
          Avalie seu veículo
        </a>

        <SocialLinks
          className={'social-link-buttons-position'}
          unitsPhone={unitsPhone}
          unitsWhatsapp={unitsWhatsapp}
          setShowNavbar={setShowNavbar}
          whatsappSVGColor={'#00212E'}
          customBg={'phone-link-background'}
          phoneSVGColor={'#FFFFFF'}
        />
      </div>
    </header>
  )
}
