import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import SearchInput from './SearchInput'
import close from '../../../../assets/images/x.png'
import { SocialLinks } from './SocialLinks'
import {
  openedStyles,
  MobileMenuContainer,
  MobileMenuHeader,
  MobileMenuBody,
  MobileMenuFooter,
} from './stylesMobileMenu'

export const MobileMenu = ({
  buy_your_vehicle,
  closeMobileMenu,
  hasSocialLinks,
  logoPath,
  show,
  advancedSearchPath,
  customer_signed_in,
  unitsPhone,
  unitsWhatsapp,
  setShowNavbar,
}) => {
  const closeMobileMenuRef = useRef(null)

  useEffect(() => {
    show === true
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset')
  }, [show])

  return (
    <MobileMenuContainer
      aria-modal="true"
      aria-hidden={show === true ? 'false' : 'true'}
      role="dialog"
      style={show === true ? openedStyles : null}
      tabIndex={-1}
    >
      <MobileMenuHeader>
        <a href="/">
          <img
            className="logo img-lazy"
            src=""
            data-src={logoPath}
            alt="logomarca"
          />
        </a>

        {hasSocialLinks === true ? (
          <div className="social-links-container">
            <SocialLinks
              unitsPhone={unitsPhone}
              unitsWhatsapp={unitsWhatsapp}
              setShowNavbar={setShowNavbar}
              mobileMenu
              closeButton={closeMobileMenuRef}
            />
          </div>
        ) : null}

        <span onClick={() => closeMobileMenu()} ref={closeMobileMenuRef}>
          <img src={close} />
        </span>
      </MobileMenuHeader>

      <MobileMenuBody>
        <div className="content">
          <SearchInput advancedSearchPath={advancedSearchPath} />
          <a className="navbar-header__nav-link" href="/veiculos">
            Quero Comprar
          </a>
          {buy_your_vehicle === true && (
            <a className="navbar-header__nav-link" href="/compramos-seu-usado">
              Quero Vender
            </a>
          )}
          <a className="navbar-header__nav-link" href="/quem-somos">
            Quem Somos
          </a>
          <a className="navbar-header__nav-link" href="/empresa-fale-conosco">
            Fale Conosco
          </a>
          {customer_signed_in === true && (
            <>
              <a className="navbar-header__nav-link" href="/editar-cadastro">
                Minha Conta
              </a>
              <a className="navbar-header__nav-link" href="/meus-pedidos">
                Meus Pedidos
              </a>
            </>
          )}
        </div>
      </MobileMenuBody>

      <MobileMenuFooter>
        <div className="navbar-header__user--sticky text-center pb-4">
          <a
            href={
              customer_signed_in ? '/customers/sign_out' : '/customers/sign_in'
            }
            className={clsx('navbar-header__btn-user', {
              'navbar-header__btn-user--signout': customer_signed_in,
            })}
          >
            <i
              icon-name={customer_signed_in ? 'log-out' : 'user'}
              className="d-inline"
            />
            <span className="mx-2">
              {customer_signed_in ? 'Sair' : 'Entrar'}
            </span>
          </a>
        </div>
      </MobileMenuFooter>
    </MobileMenuContainer>
  )
}
