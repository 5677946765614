import React from 'react'

const ErrorApi = () => {
  return (
    <div className="financing-installments__box">
      <i className="icon icon-meh-regular financing-installments__error-api-icon" />
      <h4 className="financing-installments__error-api-title">
          Ops! Não conseguimos prosseguir <br className="d-none d-xl-inline" />com a sua solicitação.
      </h4>
      <p className="financing-installments__error-api-desc">
        Não foi possível simular o financiamento com os dados fornecidos. <br className="d-none d-xl-inline" />
        Você pode tentar alterar a entrada, alterar seus dados ou falar diretamente<br className="d-none d-xl-inline" /> com um vendedor!
      </p>
    </div>
  )
}

export default ErrorApi
