import React from 'react'
import ReactDOM from 'react-dom'
import WhatsAppButtonBuyUsed from '../components/WhatsAppButtonBuyUsed'
import { Provider } from 'react-redux'
import store from '../store'
;(() => {
  const buyUsedContainer = document.querySelector('.buy-used.evaluation')

  if (!buyUsedContainer) return

  const commonQuestionButtons = document.querySelectorAll(
    '.common-question-button'
  )

  commonQuestionButtons &&
    commonQuestionButtons.forEach((questionButton) => {
      questionButton.addEventListener('click', (e) => {
        const questionIsOpening =
          questionButton.getAttribute('aria-expanded') === 'false'

        if (questionIsOpening) {
          questionButton.classList.add('common-question-button--active')
          questionButton.querySelector('span').innerText = '-'
        } else {
          questionButton.classList.remove('common-question-button--active')
          questionButton.querySelector('span').innerText = '+'
        }
      })
    })

  ReactDOM.render(
    <Provider store={store}>
      <WhatsAppButtonBuyUsed />
    </Provider>,
    document.getElementById('whatsAppButtonDiv')
  )
})()
